import { Iwi, IwiRegion } from '../types';

import { iwiAffiliationData } from '@/tools/constants/iwi';
import { IdToLabel, IwiAffiliationData, IwiAffiliationsPerRegion } from '@/tools/constants/iwi';
import { sanitiseText } from '@/tools/text';

type IwiAffiliationQuery = {
  iwiAffiliationData?: IwiAffiliationData;
  regions?: IwiRegion[];
  iwis?: Iwi[];
};

export function useIwiAffiliationData(): IwiAffiliationQuery {
  /**
   * Return the regions only for the regions dropdown.
   * `formattedId` enables the matching between regionId and the id in the path being clicked.
   * Since browsers remove the leading zeroes for those iwi regions whose id start with a "0"
   * we wouldn't be able to match them properly.
   */
  const regions = iwiAffiliationData.iwiAffiliationsPerRegion?.map((iwiAffiliationDatum: IwiAffiliationsPerRegion) => {
    return {
      ...iwiAffiliationDatum.region,
      formattedId: iwiAffiliationDatum.region.id.replace(/^0+/, ''),
    };
  });

  /**
   * Return the iwis with the region as part of each of them for easier filtering
   * at the iwi Lookup step in the form.
   */
  const iwis = iwiAffiliationData.iwiAffiliationsPerRegion.reduce(
    (reduceResult: Iwi[], regionIwiList: IwiAffiliationsPerRegion) => {
      const iwisWithRegion = regionIwiList.iwis.map((iwi: IdToLabel) => {
        return {
          ...iwi,
          region: regionIwiList.region,
          sanitisedLabel: sanitiseText(iwi.label),
        };
      });
      return [...reduceResult, ...iwisWithRegion];
    },
    [],
  );

  return {
    iwiAffiliationData,
    regions,
    iwis,
  };
}

import React from 'react';

import { Box } from '../Box/Box';

export const Container = ({ children }: React.PropsWithChildren<unknown>) => (
  <Box
    display="flex"
    flexShrink={0}
    flexGrow={0}
    flexDirection="column"
    alignItems="center"
    width="100%"
    m="0 auto"
    p={{ _: '0 24px', md: '0 150px', xxl: '0 272px' }}
  >
    {children}
  </Box>
);

Container.displayName = 'Container';

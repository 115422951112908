import React, { forwardRef, PropsWithChildren } from 'react';
import { useVisuallyHidden } from 'react-aria';
import styled from 'styled-components';

import { Box } from '../Box/Box';
import { Body2 } from '../Typography/Typography';

type RadioType = string | number;

interface IRadioProps<T extends RadioType> extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  checked?: boolean;
  value?: T;
}

export const Radio = forwardRef<HTMLInputElement, PropsWithChildren<IRadioProps<any>>>((props, ref) => {
  const { children, id, label, ...otherProps } = props;
  const { visuallyHiddenProps } = useVisuallyHidden();

  return (
    <RadioRoot id={`label-${id}`} htmlFor={id}>
      <RadioInput
        {...visuallyHiddenProps}
        id={id}
        ref={ref}
        aria-labelledby={`label-${id}`}
        type="radio"
        {...otherProps}
      />
      <RadioLabelWrapper>
        <RadioLabel>
          <RadioIcon />
          <Body2 as="label" m={0}>
            {label}
          </Body2>
        </RadioLabel>
        {!!children && (
          <Box mt={16} pt={16} borderTop="1px solid" borderColor="papatuanuku">
            {children}
          </Box>
        )}
      </RadioLabelWrapper>
    </RadioRoot>
  );
});

Radio.displayName = 'Radio';

const RadioInput = styled.input``;

const RadioIcon = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.papatuanuku};
  background-color: white;
`;

const RadioLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  flex-grow: 1;
  width: 100%;
  pointer-events: none;
`;

const RadioLabelWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 24px;
  width: 100%;
`;

const RadioRoot = styled.label`
  position: relative;
  display: flex;
  background-color: ${(props) => props.theme.colors.ma};
  margin-bottom: 16px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;

  &:last-of-type {
    margin-bottom: 0;
  }

  & ${RadioInput}:focus + ${RadioLabelWrapper} {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  & ${RadioInput}:checked + ${RadioLabelWrapper} ${RadioIcon} {
    border-width: 6px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

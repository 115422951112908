import axios from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import { useAccessTokenPromise } from '@/features/auth';

const API_PATH = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_BIND_MHA_API_PATH;

export const bindMhaApi = (getAccessToken: () => Promise<string | void>): UseQueryOptions<any> => ({
  queryKey: 'bindMha',
  queryFn: async (): Promise<any> =>
    axios.post(
      API_PATH,
      {},
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      },
    ),
  // fetched data from the API.
  staleTime: 10000,
});

export function useBindMha() {
  const getAccessToken = useAccessTokenPromise();
  // Fetch data
  const query = useQuery(bindMhaApi(getAccessToken));

  return query;
}

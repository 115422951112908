import { useVisuallyHidden } from 'react-aria';
import { NavHashLink } from 'react-router-hash-link';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled, { css } from 'styled-components';

import { BlockBackgroundVariant } from '../Block/Block';
// import MenuIndicatorIcon from '../../public/ic-list-item-white.svg';
import { Box } from '../Box/Box';
import Icon from '../Icon/Icon';
import { useLandingContext } from '../LandingContextProvider/useLandingContext';
import { ButtonPrint } from '../Typography/Typography';

export enum NavigationItems {
  intro = 'intro',
  about = 'about',
  benefits = 'benefits',
  // contributors = 'contributors',
  patai = 'patai',
}

export const LANDING_PAGE_SECTION_LABELS: { [key: string]: string } = {
  [NavigationItems.intro]: 'Intro',
  [NavigationItems.about]: 'About',
  [NavigationItems.benefits]: 'Benefits',
  // [NavigationItems.contributors]: 'Contributors',
  [NavigationItems.patai]: 'Pātai',
};

export const PrimaryNavigation = () => {
  const {
    sectionConfig: { navigationItem, textColor, blockBackgroundVariant },
  } = useLandingContext();
  const { visuallyHiddenProps } = useVisuallyHidden();

  const isDesktop = useBreakpoint(up('md'));

  return (
    <PrimaryNavigationRoot
      // Visually hide the main naviation on desktop
      {...(isDesktop ? {} : visuallyHiddenProps)}
      currentBackgroundVariant={blockBackgroundVariant}
      aria-labelledby="label_main-navigation"
    >
      <h2 {...visuallyHiddenProps} id="label_main-navigation">
        Main navigation
      </h2>
      <PrimaryNavigationList>
        {Object.keys(NavigationItems).map((section) => {
          const active = section === navigationItem;

          return (
            <PrimaryNavigationListItem key={section} active={active}>
              <Box display="flex" alignItems="center" flexShrink={0} flexGrow={0}>
                {active && (
                  <Box position="absolute" left="0">
                    <Icon size={10} name="ic-list-item-white" />
                  </Box>
                )}
                <NavHashLink to={`#${section}`}>
                  <ButtonPrint color={textColor} data-cy={`navigation-item-${section}`}>
                    {LANDING_PAGE_SECTION_LABELS[section]}
                  </ButtonPrint>
                </NavHashLink>
                {/* <Link to={`#${section}`}></Link> */}
              </Box>
            </PrimaryNavigationListItem>
          );
        })}
      </PrimaryNavigationList>
    </PrimaryNavigationRoot>
  );
};

interface IPrimaryNavigationRootProps {
  currentBackgroundVariant: BlockBackgroundVariant;
}

const PrimaryNavigationRoot = styled.nav<IPrimaryNavigationRootProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  z-index: 10;

  color: ${(props) => props.theme.colors.pango};

  svg {
    fill: ${(props) => props.theme.colors.pango};
  }

  ${({ currentBackgroundVariant }) =>
    currentBackgroundVariant === 'dark' &&
    css`
      color: ${(props) => props.theme.colors.white};

      svg {
        fill: ${(props) => props.theme.colors.white};
      }
    `}
`;

const PrimaryNavigationList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

interface IPrimaryNavigationListItemProps {
  active: boolean;
}

const PrimaryNavigationListItem = styled.li<IPrimaryNavigationListItemProps>`
  margin-bottom: 16px;
  padding-left: 20px;
  position: relative;
  opacity: 0.75;

  &:last-of-type {
    margin-bottom: 0px;
  }
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      font-weight: bold;
    `}
`;

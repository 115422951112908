import { useVisuallyHidden } from 'react-aria';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

import { useLandingContext } from '../LandingContextProvider/useLandingContext';
import { Body2, Fineprint } from '../Typography/Typography';

export const FooterNavigation = () => {
  const { visuallyHiddenProps } = useVisuallyHidden();

  const {
    sectionConfig: { textColor },
  } = useLandingContext();

  return (
    <Footer>
      <FooterNavigationRoot aria-labelledby="label_footer-navigation">
        <h2 {...visuallyHiddenProps} id="label_footer-navigation">
          Footer navigation
        </h2>
        <FooterNavigationList>
          <FooterNavigationListItem>
            <Link to="/terms-of-use">
              <Body2 as="span" color={textColor} my="0" fontWeight={600}>
                Terms of Use
              </Body2>
            </Link>
          </FooterNavigationListItem>
          <FooterNavigationListItem>
            <Link to="/privacy-policy">
              <Body2 as="span" color={textColor} my="0" fontWeight={600}>
                Privacy Policy
              </Body2>
            </Link>
          </FooterNavigationListItem>
          <FooterNavigationListItem>
            {/* <Link to="mailto:contact@tatai.maori.nz"> */}
            <a href="mailto:contact@tatai.maori.nz">
              <Body2 as="span" color={textColor} my="0" fontWeight={600}>
                contact@tatai.maori.nz
              </Body2>
            </a>
            {/* </Link> */}
          </FooterNavigationListItem>

          <FooterNavigationListItem>
            <Fineprint color={textColor}>
              This site is protected by reCAPTCHA and Google{' '}
              <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">
                <Fineprint as="span" my="0" fontWeight={600}>
                  Privacy Policy
                </Fineprint>
              </a>{' '}
              and{' '}
              <a href="https://policies.google.com/terms" rel="noopener noreferrer" target="_blank">
                <Fineprint as="span" my="0" fontWeight={600}>
                  Terms of Service
                </Fineprint>
              </a>{' '}
              apply.
            </Fineprint>
          </FooterNavigationListItem>
        </FooterNavigationList>
      </FooterNavigationRoot>
    </Footer>
  );
};

const Footer = styled.div`
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  width: 100%;
  bottom: 0;
  z-index: 10;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  color: #ffffff;
  position: relative;
  ${up('md')} {
    text-align: left;
    position: relative;
  }
`;

const FooterNavigationRoot = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const FooterNavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  width: 100%;

  ${up('md')} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const FooterNavigationListItem = styled.li`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${up('md')} {
    margin-bottom: 10px;
    margin-right: 40px;
    flex-basis: unset;
    &:last-of-type {
      margin-right: 0px;
    }
  }
  ${up('xl')} {
    margin-bottom: 0px;
    &:last-of-type {
      max-width: 450px;
      margin-left: auto;
    }
  }
  ${up('xl')} {
    &:last-of-type {
      max-width: 500px;
      margin-left: auto;
    }
  }
  @media only screen and (min-width: 1600px) {
    &:last-of-type {
      max-width: none;
    }
  }
`;

import { GlobalHeader } from '../components/GlobalHeader/GlobalHeader';
import { DefaultLayout } from '../components/Layouts/Layouts';
import { Message, MessageType } from '../components/Message/Message';

const NotFoundPage = () => (
  <DefaultLayout>
    <GlobalHeader variantOverride="dark" />

    <Message type={MessageType.ERROR} title="404" message="Page not found" />
  </DefaultLayout>
);

export default NotFoundPage;

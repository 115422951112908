import { Suspense } from 'react';
import { OverlayProvider } from 'react-aria';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { ReactComponent as AllSvgs } from './components/Icon/all.svg';
import { About } from './components/Landing/About';
import { Benefits } from './components/Landing/Benefits';
import { Questions } from './components/Landing/Questions';
import { LandingContextProvider } from './components/LandingContextProvider/LandingContextProvider';
import { Loader } from './components/Loader/Loader';
import { AuthProvider } from './features/auth';
import { RequireAuth } from './features/auth/RequireAuth';
import { MocksPanel } from './features/mocks/components/MocksPanel';
import { AffiliationFor } from './routes/AffiliationFor';
import BehalfOfForm from './routes/BehalfOfForm';
import { ConfirmDeleteData } from './routes/ConfirmDeleteData';
import { ConfirmDeleteDataBehalfOf } from './routes/ConfirmDeleteDataBehalfOf';
import CopyrightPage from './routes/Copyright';
import { DeleteData } from './routes/DeleteData';
import { DeleteDataBehalfOf } from './routes/DeleteDataBehalfOf';
import { EditDetails } from './routes/EditDetails';
import { EditDetailsBehalfOf } from './routes/EditDetailsBehalfOf';
import EditIwiMap from './routes/EditIwiMap';
import EditIwiMapBehalfOf from './routes/EditIwiMapBehalfOf';
import Form from './routes/Form';
import Landing from './routes/Landing';
import { LinkMha } from './routes/LinkMha';
import { ManagePage } from './routes/Manage';
import NotFoundPage from './routes/NotFound';
import PrivacyPolicyPage from './routes/PrivacyPolicy';
import { RealMeError } from './routes/RealMeError';
import { SignInPage } from './routes/Signin';
import TermsOfUsePage from './routes/TermsOfUse';
import { UpgradeAccount } from './routes/UpgradeAccount';
import { VerifyAccount } from './routes/VerifyAccount';
import { GlobalStyle } from './styles/globals';
import { defaultTheme } from './styles/themes/default';

import 'intersection-observer';
import '@fontsource/quando';
import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/900.css';
import '@fontsource/poppins/400.css';
import '@fontsource/fira-sans/400.css';
import '@fontsource/fira-sans/700.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 0,
      suspense: true,
    },
  },
});

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <AllSvgs style={{ display: 'none' }} />

      <BrowserRouter>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={defaultTheme}>
              <OverlayProvider>
                {/* set up environment variable */}
                <LandingContextProvider>
                  <Suspense fallback={<Loader />}>
                    {process.env.REACT_APP_ENABLE_DEBUG_TOOLS === 'true' && <MocksPanel />}

                    <Routes>
                      <Route index element={<Landing />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/benefits" element={<Benefits />} />
                      <Route path="/questions" element={<Questions />} />
                      <Route
                        path="/login"
                        element={
                          <RequireAuth redirectTo="/verify-account">
                            <VerifyAccount />
                          </RequireAuth>
                        }
                      />
                      {/* <Route path="/verify-account" element={<VerifyAccount />} /> */}
                      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                      <Route path="/terms-of-use" element={<TermsOfUsePage />} />
                      <Route path="/form" element={<Form />} />
                      <Route
                        path="/behalf-of-form"
                        element={
                          <RequireAuth>
                            <BehalfOfForm />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/edit-iwi-map"
                        element={
                          <RequireAuth>
                            <EditIwiMap />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/edit-iwi-map-behalf-of"
                        element={
                          <RequireAuth>
                            <EditIwiMapBehalfOf />
                          </RequireAuth>
                        }
                      />
                      <Route path="/copyright" element={<CopyrightPage />} />
                      <Route
                        path="/manage"
                        element={
                          <RequireAuth>
                            <ManagePage />
                          </RequireAuth>
                        }
                      />
                      <Route path="/signin" element={<SignInPage />} />
                      <Route
                        path="/linked-account"
                        element={
                          <RequireAuth>
                            <LinkMha />
                          </RequireAuth>
                        }
                      />{' '}
                      <Route path="/who-is-this-iwi-affiliation-for" element={<AffiliationFor />} />
                      <Route
                        path="/edit-details"
                        element={
                          <RequireAuth>
                            <EditDetails />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/edit-details-behalf-of"
                        element={
                          <RequireAuth>
                            <EditDetailsBehalfOf />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/delete-account"
                        element={
                          <RequireAuth>
                            <DeleteData />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/delete-account-behalf-of"
                        element={
                          <RequireAuth>
                            <DeleteDataBehalfOf />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/confirm-delete-account"
                        element={
                          <RequireAuth>
                            <ConfirmDeleteData />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/confirm-delete-account-behalf-of"
                        element={
                          <RequireAuth>
                            <ConfirmDeleteDataBehalfOf />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/verify-account"
                        element={
                          <RequireAuth>
                            <VerifyAccount />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/upgrade-account"
                        element={
                          <RequireAuth>
                            <UpgradeAccount />
                          </RequireAuth>
                        }
                      />
                      <Route path="/realMeError" element={<RealMeError />} />
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                  </Suspense>
                </LandingContextProvider>
              </OverlayProvider>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
};

import styled from 'styled-components';

import externalLinkLight from '../assets/icons/externalLinkLight.svg';

import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { Container } from '@/components/Container/Container';
import { GlobalHeader } from '@/components/GlobalHeader/GlobalHeader';
import { Head } from '@/components/Head/Head';
import { useLandingContext } from '@/components/LandingContextProvider/useLandingContext';
import { DefaultLayout } from '@/components/Layouts/Layouts';
import { Body2, H2 } from '@/components/Typography/Typography';

export const UpgradeAccount = () => {
  const {
    sectionConfig: { textColor },
  } = useLandingContext();

  return (
    <>
      <Head />
      <Wrapper>
        <GlobalHeader variantOverride="dark" />
        <DefaultLayout>
          <Container>
            <Box color={textColor} mt={{ _: '56px', md: '104px' }} maxWidth="567px">
              <H2 textAlign="left" mt={{ _: '16px', md: '32px' }}>
                Incorrect identification level
              </H2>
              <Body2 mt={{ _: '16px', md: '32px' }}>Sorry!</Body2>
              <Body2 mt={{ _: '16px', md: '32px' }}>
                It looks like you don&apos;t have the correct identification level on your My Health Account to access
                Tātai.
              </Body2>
              <Body2 mt={{ _: '16px', md: '32px' }}>
                Please log into your My Health Account and use your healthcare provider or an identity document to
                confirm who you are.
              </Body2>
              <a href="https://app-int.np-identity.health.nz/login">
                <Button
                  width="279px"
                  mt={{ _: '16px', md: '32px' }}
                  type="button"
                  variant="dark"
                  label="My Health Account"
                  maxWidth="550px"
                  mb="64px"
                  icon={externalLinkLight}
                >
                  My Health Account
                </Button>
              </a>
            </Box>
          </Container>
        </DefaultLayout>
      </Wrapper>
    </>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

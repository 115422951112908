import React from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { flexbox } from 'styled-system';

import { Box, IBoxProps } from '../Box/Box';
import { LinkButton } from '../Button/Button';
import Icon, { IconName } from '../Icon/Icon';
import { Body2, H2 } from '../Typography/Typography';

import { useAuth } from '@/features/auth';

export enum MessageType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const MESSAGE_TYPE_TO_ICON = {
  [MessageType.SUCCESS]: 'ic-success',
  [MessageType.ERROR]: 'ic-error',
};

interface IMessageProps {
  type: MessageType;
  title: string;
  message: string;
  children?: React.ReactNode;
}

type IMessageBoxProps = IBoxProps & {
  type: MessageType;
};

export const Message = ({ type, title, message, children }: IMessageProps) => {
  const { isAuthenticated } = useAuth();

  return (
    <MessageWrapper>
      <MessageBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        backgroundColor="rgba(255, 255, 255, 0.3)"
        borderRadius="4px"
        p={24}
        mb={{ md: '24px' }}
        type={type}
        flexShrink={0}
        flexGrow={0}
      >
        <Icon name={MESSAGE_TYPE_TO_ICON[type] as IconName} size={48} />
        <H2 mt={16} mb="8px">
          {title}
        </H2>
        <Body2>{message}</Body2>
      </MessageBox>
      {children}
      <MessageCTA>
        {isAuthenticated ? (
          <LinkButton label="View my affiliations" to="/manage" />
        ) : (
          <LinkButton label="Back to home" to="/" />
        )}
      </MessageCTA>
    </MessageWrapper>
  );
};

export const MessageWrapper = styled.div`
  ${flexbox}
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 16px;
  text-align: center;

  padding: 80px 24px 36px;

  ${up('xl')} {
    padding: 120px 240px 36px;
  }
`;

export const MessageCTA = styled.div`
  width: 100%;
  margin-top: auto;
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${up('md')} {
    margin-top: 0;
    padding: 0px;
  }
`;

export const MessageBox = styled(Box)<IMessageBoxProps>`
  width: ${(props) => (props.type === MessageType.ERROR ? '100%' : 'auto')};
`;

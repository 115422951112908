import { RefObject } from 'react';

import Icon from '../Icon/Icon';
import { ButtonPrint } from '../Typography/Typography';

import { TransparentButton } from './TransparentButton';

interface IScrollToTopButtonProps {
  focusRef: RefObject<HTMLElement>;
}
export const ScrollToTopButton = (props: IScrollToTopButtonProps) => {
  const { focusRef } = props;

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
    focusRef.current?.focus();
  };

  return (
    <TransparentButton type="button" onClick={handleScrollToTop}>
      <ButtonPrint mr="6px">Back to top</ButtonPrint>
      <Icon name="ic-arrow-up" size={24} />
    </TransparentButton>
  );
};

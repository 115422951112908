export enum ConfidenceLevel {
  LEVEL1 = '1',
  LEVEL1N = '1N',
  LEVEL2 = '2',
  LEVEL2N = '2N',
  LEVEL3 = '3',
  LEVEL3N = '3N',
}

export type UserInfo = {
  birthdate?: string;
  email: string;
  family_name?: string;
  given_name?: string;
  nickname: string | null;
  middle_name?: string;
  'urn:login:health:nz:claims:confidence_level': ConfidenceLevel;
  'urn:login:health:nz:claims:mobile_number'?: string;
  'urn:login:health:nz:claims:nhi': string;
};

import { createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';

import { defaultTheme } from '../../styles/themes/default';
import { BlockBackgroundVariant } from '../Block/Block';
import { NavigationItems } from '../PrimaryNavigation/PrimaryNavigation';

export interface ILandingContext {
  sectionConfig: ISectionConfig;
  currentSection: LandingSections;
  setCurrentSection: Dispatch<SetStateAction<LandingSections>>;
}

export interface ISectionConfig {
  textColor: keyof typeof defaultTheme.colors;
  backgroundColor: keyof typeof defaultTheme.colors;
  blockBackgroundVariant: BlockBackgroundVariant;
  navigationItem: NavigationItems;
}

export type ISectionsConfig = {
  [key in LandingSections]: ISectionConfig;
};

export enum LandingSections {
  intro = 'intro',
  about = 'about',
  aboutContinued = 'aboutContinued',
  benefits = 'benefits',
  benefitsContinued = 'benefitsContinued',
  // contributors = 'contributors',
  patai = 'patai',
  pataiContinued = 'pataiContinued',
}

export const SECTIONS_CONFIG: ISectionsConfig = {
  [LandingSections.intro]: {
    textColor: 'white',
    blockBackgroundVariant: 'dark',
    backgroundColor: 'papatuanuku',
    navigationItem: NavigationItems.intro,
  },
  [LandingSections.about]: {
    textColor: 'pango',
    blockBackgroundVariant: 'light',
    backgroundColor: 'ma',
    navigationItem: NavigationItems.about,
  },
  [LandingSections.aboutContinued]: {
    textColor: 'white',
    blockBackgroundVariant: 'dark',
    backgroundColor: 'harakeke',
    navigationItem: NavigationItems.about,
  },
  [LandingSections.benefits]: {
    textColor: 'white',
    blockBackgroundVariant: 'dark',
    backgroundColor: 'pango',
    navigationItem: NavigationItems.benefits,
  },
  [LandingSections.benefitsContinued]: {
    textColor: 'white',
    blockBackgroundVariant: 'dark',
    backgroundColor: 'papatuanuku',
    navigationItem: NavigationItems.benefits,
  },
  // [LandingSections.contributors]: {
  //   textColor: 'pango',
  //   blockBackgroundVariant: 'light',
  //   backgroundColor: 'ma',
  //   navigationItem: NavigationItems.contributors,
  // },
  [LandingSections.patai]: {
    textColor: 'white',
    blockBackgroundVariant: 'dark',
    backgroundColor: 'harakeke',
    navigationItem: NavigationItems.patai,
  },
  [LandingSections.pataiContinued]: {
    textColor: 'white',
    blockBackgroundVariant: 'dark',
    backgroundColor: 'pango',
    navigationItem: NavigationItems.patai,
  },
};

export const LandingContext = createContext<ILandingContext | null>(null);

export const LandingContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [currentSection, setCurrentSection] = useState<LandingSections>(LandingSections.intro);
  const [sectionConfig, setSectionConfig] = useState<ISectionConfig>(SECTIONS_CONFIG[LandingSections.intro]);
  useEffect(() => {
    setSectionConfig(SECTIONS_CONFIG[currentSection]);
  }, [currentSection]);

  return (
    <LandingContext.Provider value={{ currentSection, sectionConfig, setCurrentSection }}>
      {children}
    </LandingContext.Provider>
  );
};

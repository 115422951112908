import { useRef } from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import { MaoriLanguage } from '../components/Accessibility/MaoriLanguage';
import { Head } from '../components/Head/Head';
import { LegalPageWrapper, LegalBody2 } from '../components/LegalPages/LegalPages';
import { H2 } from '../components/Typography/Typography';

import { Box } from '@/components/Box/Box';
import { ScrollToTopButton } from '@/components/Button/ScrollToTopButton';
import { FooterNavigation } from '@/components/FooterNavigation/FooterNavigation';
import { BoldA } from '@/components/Links/Links';

const PrivacyPolicyPage = () => {
  const isDesktop = useBreakpoint(up('md'));
  const mainTitleRef = useRef<HTMLHeadingElement>(null);

  return (
    <Wrapper>
      <Box mt={{ _: '20px', md: '100px' }}>
        <LegalPageWrapper>
          <Head />
          <H2 as="h1">
            <MaoriLanguage>Nau mai ki Tātai (Tātai)</MaoriLanguage>
          </H2>

          <LegalBody2>
            If you are of <MaoriLanguage>Māori</MaoriLanguage> descent and know your <MaoriLanguage>Iwi</MaoriLanguage>{' '}
            you can choose to join <MaoriLanguage>Tātai</MaoriLanguage>.
          </LegalBody2>

          <LegalBody2>
            Over time, this information will be used to help enable <MaoriLanguage>Māori</MaoriLanguage> people across{' '}
            <MaoriLanguage>Aotearoa</MaoriLanguage> to identify <MaoriLanguage>Iwi</MaoriLanguage> they affiliate to and
            for those <MaoriLanguage>Iwi</MaoriLanguage> to understand more about their people.
          </LegalBody2>

          <LegalBody2>
            It can also be used to create statistical datasets (that will not identify you) to directly support{' '}
            <MaoriLanguage>Māori</MaoriLanguage> health and wellbeing through more informed decision making and
            evidence-based practices.
          </LegalBody2>

          <LegalBody2>
            Read the <MaoriLanguage>Tātai</MaoriLanguage>{' '}
            <BoldA to="/tatai-privacy-statement.pdf" target="_blank">
              Privacy Statement (PDF, 171 KB<NoUnderline>)</NoUnderline>
            </BoldA>
            {/* <a href="../assets/tatai-privacy-statement.pdf" download>
              Privacy Statement (PDF, 171 KB<NoUnderline>)</NoUnderline>
            </a> */}
            .
          </LegalBody2>
        </LegalPageWrapper>
      </Box>
      {/* Footer navigation and back to top buttons */}
      <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }} zIndex={10} mt="auto">
        <FooterNavigation />

        {!isDesktop && (
          <Box mt={32} display="flex" justifyContent={{ _: 'center' }} flexShrink={0} flexGrow={0}>
            <ScrollToTopButton focusRef={mainTitleRef} />
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

/**
 * Makes the link look nicer with no underline on the bracket. Shay asked me to
 * do this.
 *
 * Adapted from https://stackoverflow.com/a/13856365.
 */
const NoUnderline = styled.span`
  /** inline-block needed to override parent's style */
  display: inline-block;
  text-decoration: none;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export default PrivacyPolicyPage;

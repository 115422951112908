import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { GlobalHeader } from '../components/GlobalHeader/GlobalHeader';
import { Head } from '../components/Head/Head';

import { EditIwiMapBehalfOf } from '@/features/editIwiMapBehalfOf';

const FormEditIwiBehalfOf = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}>
    <Head />

    <GlobalHeader variantOverride="dark" />

    <main>
      <EditIwiMapBehalfOf />
    </main>
  </GoogleReCaptchaProvider>
);
export default FormEditIwiBehalfOf;

import { Dialog as ReachDialog } from '@reach/dialog';
import { useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import Icon from '../Icon/Icon';
import { Body2, ButtonPrint, Ul } from '../Typography/Typography';

export const NHIInfoDialog = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  const isDesktop = useBreakpoint(up('md'));

  return (
    <>
      <InfoButton type="button" onClick={open}>
        <VisuallyHidden>NHI number info</VisuallyHidden>
        <Icon name="ic-info" size={{ _: 24 }} />
      </InfoButton>
      <Dialog isOpen={showDialog} onDismiss={close} aria-labelledby="dialog_label">
        <DialogWrapper>
          {isDesktop && <DialogPattern />}
          <DialogContentWrapper>
            <ButtonPrint id="dialog_label">You can find your NHI number:</ButtonPrint>
            <Ul>
              <Body2 as="li">on a prescription or prescription receipt</Body2>
              <Body2 as="li">on a prescription medicine bottle label</Body2>
              <Body2 as="li">on a hospital letter or appointment card</Body2>
              <Body2 as="li">on an x-ray or test result</Body2>
              <Body2 as="li">by checking an online patient portal</Body2>
              <Body2 as="li">by asking your general practitioner or pharmacist on laboratory orders</Body2>
            </Ul>
            <Box mt={{ _: 'auto', md: 40 }} mb={32}>
              <DialogCTA>
                <Button type="button" onClick={close} label="Ok, got it" />
              </DialogCTA>
            </Box>
          </DialogContentWrapper>
        </DialogWrapper>
      </Dialog>
    </>
  );
};
const Dialog = styled(ReachDialog)`
  position: fixed;
  background: rgba(51, 51, 51, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 20;

  ${up('md')} {
    padding: 24px;
  }
`;

const DialogWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 24px;
  left: 24px;
  right: 24px;
  bottom: 24px;
  flex-shrink: 1;
  overflow: auto;

  ${up('md')} {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: relative;
    width: auto;
    margin: auto;
  }
`;

const DialogContentWrapper = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${up('md')} {
    padding-left: 32px;
    padding: 80px 120px;
  }
`;

const DialogPattern = styled.div`
  background-color: rgba(109, 131, 60, 0.8);
  background-image: url('/modal-pattern.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 32px;
`;

const DialogCTA = styled.div`
  width: 100%;
  margin-top: auto;
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${up('md')} {
    margin-top: 0;
    padding: 0px;
  }
`;

const InfoButton = styled.button`
  cursor: pointer;
  background: 0;
  padding: 0;
  border: 0;
`;

import React, { useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';
import { useMediaQuery } from 'usehooks-ts';

import { ReactComponent as BackgroundHoverSvg } from '../../assets/images/Subtract_grey_hover_md.svg';
import { ReactComponent as BackgroundHoverSmallSvg } from '../../assets/images/Subtract_grey_hover_sm.svg';
import { ReactComponent as BackgroundSvg } from '../../assets/images/Subtract_grey_md.svg';
import { ReactComponent as BackgroundSmallSvg } from '../../assets/images/Subtract_grey_sm.svg';
import { MaoriLanguage } from '../Accessibility/MaoriLanguage';
import { recordCustomEvents } from '../Analytics/RecordEvents';
import { BackgroundBlockRoot } from '../AnimatedBackground/AnimatedBackground';
import { Block } from '../Block/Block';
import { Box } from '../Box/Box';
import { LinkButton } from '../Button/Button';
import { ScrollToTopButton } from '../Button/ScrollToTopButton';
import { ButtonLink } from '../ButtonLink/ButtonLink';
import { Container4 } from '../Container/Container2';
import { FooterNavigation } from '../FooterNavigation/FooterNavigation';
import { GlobalHeader } from '../GlobalHeader/GlobalHeader';
import { Head } from '../Head/Head';
import { HorizontalSeparator } from '../HorizontalSeperator/HorizontalSeparator';
import { LandingSections } from '../LandingContextProvider/LandingContextProvider';
import { useLandingContext } from '../LandingContextProvider/useLandingContext';
import { NavigationItems } from '../PrimaryNavigation/PrimaryNavigation';
import { Body2, Body4, Body5, H1 } from '../Typography/Typography';

import { useAuth } from '@/features/auth';
import { Navigation } from '@/features/iwiAffiliationForm/components/Navigation';
const BACKGROUND_IMAGE_MAPPINGS: {
  [key: string]: { small: string; large: string };
} = {
  harakeke: {
    small: '/backgrounds/background-harakeke-small.png',
    large: '/backgrounds/background-harakeke.jpg',
  },
};
export const About = () => {
  let previousStep = 'self';
  if (window.history.state) {
    if (window.history.state.usr) {
      previousStep = window.history.state && window.history.state?.usr;
    }
  }

  console.log({ previousStep });
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isHoverDiv2, setIsHoverDiv2] = useState<boolean>(false);

  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuth();
  /* We refocus the main title when using the back to top button */
  const {
    sectionConfig: { textColor },
    currentSection,
    setCurrentSection,
  } = useLandingContext();
  const handleInViewChange = (inView: boolean, sectionId: LandingSections) => {
    if (inView && sectionId !== currentSection) {
      setCurrentSection(sectionId);
    }
  };
  /* We refocus the main title when using the back to top button */
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  const isDesktop = useBreakpoint(up('md'));
  const currentSize = isDesktop ? 'large' : 'small';
  const isDesktopLg = useMediaQuery('(min-width: 1470px)');
  // const isDesktopMedium = useMediaQuery('(min-width: 1099px)');
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Head />

      <GlobalHeader />
      {/* <PrimaryNavigation /> */}
      <main>
        {!isAuthenticated && (
          <Box
            position={{ _: 'relative', md: 'fixed' }}
            display="flex"
            justifyContent="space-between"
            top={{ md: 32 }}
            right={{ _: 0, md: 32 }}
            bottom={{ md: 'unset' }}
            left={{ _: 0, md: 'unset' }}
            px={{ _: 24, md: 0 }}
            py={{ _: 12, md: 0 }}
            width={{ _: '100%', md: 'auto' }}
            flexShrink={0}
            flexGrow={0}
            flexDirection="column"
            zIndex="999"
          >
            {isDesktop && (
              <>
                <LinkButton label="Get started" to="/signin" dataCy="get-started-button-top" />
              </>
            )}
            <Box textAlign="end" mt="24px">
              <ButtonLink
                color="white"
                onClick={() => {
                  recordCustomEvents('login_with_mha', { login_with_mha: true });
                  login();
                }}
              >
                Sign in
              </ButtonLink>
            </Box>
          </Box>
        )}
        {/* <AnimatedBackground /> */}
        <BackgroundBlockRoot
          data-cy="background-block-root"
          key="harakeke"
          backgroundColor="harakeke"
          backgroundImageUrl={BACKGROUND_IMAGE_MAPPINGS['harakeke'][currentSize]}
          active={true}
        />
        <Block
          id={NavigationItems.patai}
          inViewThreshold={currentSection === LandingSections.patai ? 1 : undefined}
          variant="light"
          onInViewChange={(inView) => {
            if (inView) {
              handleInViewChange(inView, LandingSections.patai);
            }
          }}
        >
          <Container4>
            <Navigation
              title="Back"
              goToPreviousStep={() =>
                previousStep === 'questions'
                  ? navigate('/questions', { state: { previousStep: 'about' } })
                  : previousStep === 'benefits'
                  ? navigate('/benefits', { state: { previousStep: 'about' } })
                  : navigate('/', { state: { previousStep: 'about' } })
              }
              labelled-by="about"
              padding="0px"
              marginTop={{ _: '72px' }}
            >
              <Box marginTop="40px" marginBottom={{ _: '32px', xxl: '72px' }} width="100%" maxWidth={{ md: 920 }}>
                <H1 as="h2" color={textColor} textAlign={{ _: 'left', md: 'center' }}>
                  Whakapapa
                </H1>
                <HorizontalSeparator color={textColor} justifyContent={{ _: 'left', md: 'center' }} />
              </Box>
              <Box
                display="flex"
                flexWrap={{ _: 'wrap', xl: 'nowrap' }}
                flexShrink={0}
                flexGrow={0}
                flexDirection={{ _: 'column', xxl: 'row' }}
                marginBottom={{ _: '32px', xxl: '72px' }}
              >
                <Box m="0px" flexBasis={{ _: '100%', xl: '50%' }} mr={{ _: 0, lg: 0, xl: 32 }}>
                  <Body2 fontWeight={500} mb="1rem" color={textColor}>
                    <MaoriLanguage>Tātai</MaoriLanguage> has been developed with <MaoriLanguage>Māori</MaoriLanguage>,
                    for <MaoriLanguage>Māori</MaoriLanguage>.
                  </Body2>
                  <Body2 fontWeight={500} mb="1rem" color={textColor}>
                    By sharing your iwi affiliations, you are contributing to a dataset that wants to improve Māori
                    health outcomes.
                  </Body2>
                  <Body2 fontWeight={500} mb="1rem" color={textColor}>
                    The Government recognises that it has not been able to provide iwi with high-quality information
                    about their people and knows that this needs to change. The need for data that is reliable,
                    relevant, and accessible for iwi is important.
                  </Body2>
                  <Body2 fontWeight={500} mb={{ _: '1rem', xxl: 0 }} color={textColor}>
                    <MaoriLanguage>Māori</MaoriLanguage> data experts from the Data Iwi Leaders Group have partnered
                    with the Ministry of Health | <MaoriLanguage>Manatū</MaoriLanguage> Hauora and Health New Zealand |
                    Te Whatu Ora on this kaupapa. <MaoriLanguage>Māori</MaoriLanguage> data experts from Te Puni{' '}
                    <MaoriLanguage>Kōkiri</MaoriLanguage>, StatsNZ and the Ministry of Education have also assisted with
                    this work. Together we are working to uphold the mana of your information and build on the current
                    health system datasets to benefit iwi, <MaoriLanguage>hapū</MaoriLanguage>,{' '}
                    <MaoriLanguage>hāpori</MaoriLanguage>, and <MaoriLanguage>whānau</MaoriLanguage>.
                  </Body2>
                </Box>
                <Box m="0px" flexBasis={{ _: '100%', xl: '50%' }}>
                  <Body2 fontWeight={500} color={textColor}>
                    As critical partners to this data collection, the information that is collected will be shared back
                    to iwi to inform and strengthen iwi strategies and support progress towards their self-determined
                    vision and outcomes for health. With permission of individuals and iwi, the government will also use
                    this to inform future health system strategies to improve health outcomes for{' '}
                    <MaoriLanguage>Māori</MaoriLanguage>.
                  </Body2>
                </Box>
              </Box>

              <StyledOuterDiv>
                <Box
                  style={{
                    position: 'relative',
                  }}
                  onClick={() => navigate('/questions', { state: { previousStep: 'about' } })}
                  onMouseEnter={() => {
                    return setIsHover(true);
                  }}
                  onMouseLeave={() => {
                    return setIsHover(false);
                  }}
                >
                  {isDesktopLg ? (
                    <>
                      {!isHover ? (
                        <div
                        // style={{
                        //   position: 'relative',
                        // }}
                        >
                          <StyledBackgroundSvg />
                        </div>
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundHoverSvg />
                        </div>
                      )}
                      <StyledImageDiv>
                        <Box>
                          <StyledImg src="/backgrounds/patai_questions.png" alt="" />
                        </Box>
                        <StyledContentDiv>
                          <Body5 color="#FFFDFB">
                            <MaoriLanguage>Pātai</MaoriLanguage>{' '}
                          </Body5>{' '}
                          <Body4 color="#FFFDFB" marginLeft="6px">
                            {' '}
                            (Questions)
                          </Body4>
                        </StyledContentDiv>
                      </StyledImageDiv>
                    </>
                  ) : (
                    <>
                      {' '}
                      {!isHover ? (
                        <div
                        // style={{
                        //   position: 'relative',
                        // }}
                        >
                          <StyledBackgroundSmallSvg />
                        </div>
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundHoverSmallSvg />
                        </div>
                      )}
                      <StyledImageDiv>
                        <Box>
                          <StyledImg src="/backgrounds/patai_questions.png" alt="" />
                        </Box>
                        <StyledContentDiv>
                          <Body5 color="#FFFDFB">
                            <MaoriLanguage>Pātai</MaoriLanguage>{' '}
                          </Body5>{' '}
                          <Body4 color="#FFFDFB" marginLeft="6px">
                            {' '}
                            (Questions)
                          </Body4>
                        </StyledContentDiv>
                      </StyledImageDiv>
                    </>
                  )}
                </Box>
                <Box
                  style={{
                    position: 'relative',
                  }}
                  onClick={() => navigate('/benefits', { state: { previousStep: 'about' } })}
                  onMouseEnter={() => {
                    return setIsHoverDiv2(true);
                  }}
                  onMouseLeave={() => {
                    return setIsHoverDiv2(false);
                  }}
                >
                  {isDesktopLg ? (
                    <>
                      {!isHoverDiv2 ? (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundSvg />
                        </div>
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundHoverSvg />
                        </div>
                      )}

                      <StyledImageDiv>
                        <Box>
                          <StyledImg1 src="/backgrounds/whainga_benefits.png" alt="" />
                        </Box>
                        <StyledContentDiv>
                          <Body5 color="#FFFDFB">
                            <MaoriLanguage>Whāinga</MaoriLanguage>{' '}
                          </Body5>{' '}
                          <Body4 color="#FFFDFB" marginLeft="6px">
                            {' '}
                            (Benefits)
                          </Body4>
                        </StyledContentDiv>
                      </StyledImageDiv>
                    </>
                  ) : (
                    <>
                      {!isHoverDiv2 ? (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundSmallSvg />
                        </div>
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundHoverSmallSvg />
                        </div>
                      )}

                      <StyledImageDiv>
                        <Box>
                          <StyledImg1 src="/backgrounds/whainga_benefits.png" alt="" />
                        </Box>
                        <StyledContentDiv>
                          <Body5 color="#FFFDFB">
                            <MaoriLanguage>Whāinga</MaoriLanguage>{' '}
                          </Body5>{' '}
                          <Body4 color="#FFFDFB" marginLeft="6px">
                            {' '}
                            (Benefits)
                          </Body4>
                        </StyledContentDiv>
                      </StyledImageDiv>
                    </>
                  )}
                </Box>
              </StyledOuterDiv>
            </Navigation>
          </Container4>
        </Block>
        {/* Footer navigation and back to top buttons */}
        <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }} zIndex={10} mt="auto">
          <FooterNavigation />

          {!isDesktop && (
            <Box mt={32} display="flex" justifyContent={{ _: 'center' }} flexShrink={0} flexGrow={0}>
              <ScrollToTopButton focusRef={mainTitleRef} />
            </Box>
          )}
        </Box>
      </main>
    </>
  );
};

const StyledBackgroundSvg = styled(BackgroundSvg)`
  height: 377px;

  ${up('md')} {
    height: 377px;
  }

  ${up('lg')} {
    height: 314px;
  }
  ${up('xl')} {
    height: 314px;
  }
  ${up('xxl')} {
    height: 314px;
  }
`;

const StyledBackgroundHoverSvg = styled(BackgroundHoverSvg)`
  height: 377px;

  ${up('md')} {
    height: 377px;
  }

  ${up('lg')} {
    height: 314px;
  }
  ${up('xl')} {
    height: 314px;
  }
  ${up('xxl')} {
    height: 314px;
  }
`;
const StyledBackgroundSmallSvg = styled(BackgroundSmallSvg)`
  height: 377px;

  ${up('md')} {
    height: 314px;
  }

  ${up('lg')} {
    height: 314px;
  }
  ${up('xl')} {
    height: 314px;
  }
  ${up('xxl')} {
    height: 314px;
  }
`;

const StyledBackgroundHoverSmallSvg = styled(BackgroundHoverSmallSvg)`
  height: 377px;

  ${up('md')} {
    height: 377px;
  }

  ${up('lg')} {
    height: 314px;
  }
  ${up('xl')} {
    height: 314px;
  }
  ${up('xxl')} {
    height: 314px;
  }
`;

const StyledImageDiv = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 16px;
`;

const StyledOuterDiv = styled.div`
  // width: 100%;
  margin-bottom: 72px;
  margin-top: 32px;
  display: grid;
  grid-template-columns: 100%;

  gap: 16px;

  @media only screen and (min-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em 16px;
  }

  ${up('xl')} {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em 32px;
  }
  ${up('xxl')} {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em 32px;
  }
`;

const StyledContentDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  color: white;
  align-content: end;
  height: 62px;
  padding: 15px;
  @media only screen and (min-width: 991px) {
    padding: 15px;
  }

  ${up('xl')} {
    padding: 10px;
  }

  @media only screen and (min-width: 1600px) {
    padding: 10px;
  }
`;

const StyledImg1 = styled.img`
  width: 100%;
  height: 100%;
  object-position: top center;
  object-fit: cover;
  max-height: 295px;
  color: #333333;
  :hover {
    color: #666666;
  }
  ${up('md')} {
    max-height: 232px;
  }
  ${up('lg')} {
    max-height: 232px;
  }
  ${up('xl')} {
    max-height: 232px;
  }
  ${up('xxl')} {
    max-height: 232px;
  }
`;
const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  max-height: 295px;
  color: #333333;
  :hover {
    color: #666666;
  }
  ${up('md')} {
    max-height: 232px;
  }
  ${up('lg')} {
    max-height: 232px;
  }
  ${up('xl')} {
    max-height: 232px;
  }
  ${up('xxl')} {
    max-height: 232px;
  }
`;

import React from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

interface IDefaultLayoutProps {
  children: React.ReactNode;
}

export const DefaultLayout = ({ children }: IDefaultLayoutProps) => (
  <LayoutWrapper>
    <LayoutBackground />
    {children}
  </LayoutWrapper>
);

export const AlternativeLayout = ({ children }: IDefaultLayoutProps) => (
  <LayoutWrapper>
    <AlternativeLayoutBackground />
    {children}
  </LayoutWrapper>
);

export const DesignedLayout = ({ children }: IDefaultLayoutProps) => (
  <LayoutWrapper>
    <DesignedLayoutBackground />
    {children}
  </LayoutWrapper>
);

export const AccountDesignedLayout = ({ children }: IDefaultLayoutProps) => (
  <LayoutWrapper>
    <AccountDesignedLayoutBackground />
    {children}
  </LayoutWrapper>
);

export const LayoutBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -20;

  background: ${(props) => props.theme.colors.papatuanuku}
    linear-gradient(0deg, rgba(255, 239, 216, 0.3) 0%, ${(props) => props.theme.colors.papatuanuku} 45%);

  ${up('md')} {
    background: ${(props) => props.theme.colors.papatuanuku}
      radial-gradient(700px 700px at 28% 65%, rgba(255, 239, 216, 0.35) 0%, rgba(196, 196, 196, 0) 100%);
  }
`;

export const AlternativeLayoutBackground = styled(LayoutBackground)`
  background: ${(props) => props.theme.colors.harakeke}
    linear-gradient(0deg, rgba(255, 239, 216, 0.3) 0%, ${(props) => props.theme.colors.harakeke} 45%);

  ${up('md')} {
    background: ${(props) => props.theme.colors.harakeke}
      radial-gradient(700px 700px at 28% 65%, rgba(255, 239, 216, 0.35) 0%, rgba(196, 196, 196, 0) 100%);
  }
`;

export const DesignedLayoutBackground = styled(LayoutBackground)`
  position: relative;
  background-color: #bf5422;
  background-image: url(/backgrounds/background-papatuanuku-small.png);
  background-size: 100%;
  background-position: top center;
  opacity: 1;
  z-index: 0;
  ${up('md')} {
    background-image: url(/backgrounds/pattern-orange.png);
  }
`;

export const AccountDesignedLayoutBackground = styled(LayoutBackground)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #bf5422;
  background-image: url(/backgrounds/background-papatuanuku-small.png);
  background-size: 100%;
  background-position: top center;
  opacity: 1;
  z-index: 0;
  ${up('md')} {
    background-image: url(/backgrounds/pattern-orange.png);
  }
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
`;

import { rest } from 'msw';

import {} from 'date-fns';
import { getCurrentMockAccount } from '../helpers/getCurrentMockAccount';

import { MOCK_USERS, MOCK_USER_RESPONSES } from './userInfo';

import { Gender, IsMaoriDescentResponses, Iwi, KnowsIwiResponses } from '@/features/iwiAffiliationForm';
import { TIwiSubmission } from '@/features/manage';

export const USERS_TO_SUBMISSIONS: { [key in MOCK_USERS]: Omit<TIwiSubmission, 'merged'>[] } = {
  [MOCK_USERS.level1NoSubmissions]: [],
  [MOCK_USERS.level1TwoSubmissions]: [
    {
      dateOfBirth: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions].birthdate,
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.YES,
      dateSubmitted: '2022-04-03T02:32:58.606Z',
      nhiNumber: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions]['urn:login:health:nz:claims:nhi'],
      firstName: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions].given_name,
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions].family_name,
      address: '123 Example Street',
      gender: Gender.FEMALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions].email,
      phone: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0801',
          label: 'Te Atiawa (Taranaki)',
          region: {
            id: '08',
            label: 'Taranaki Region Iwi',
          },
          sanitisedLabel: 'teatiawataranaki',
        },
        {
          id: '0803',
          label: 'Ngāti Mutunga (Taranaki)',
          region: {
            id: '08',
            label: 'Taranaki Region Iwi',
          },
          sanitisedLabel: 'ngatimutungataranaki',
        },
      ],
    },
    {
      dateOfBirth: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions].birthdate,
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.PARTIALLY,
      dateSubmitted: '2022-01-01T02:32:58.606Z',
      nhiNumber: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions]['urn:login:health:nz:claims:nhi'],
      firstName: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions].given_name,
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions].family_name,
      address: '123 Example Street',
      gender: Gender.MALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions].email,
      phone: MOCK_USER_RESPONSES[MOCK_USERS.level1TwoSubmissions]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0803',
          label: 'Ngāti Mutunga (Taranaki)',
          region: {
            id: '08',
            label: 'Taranaki Region Iwi',
          },
          sanitisedLabel: 'ngatimutungataranaki',
        },
      ],
    },
  ],
  [MOCK_USERS.level3NNoSubmissions]: [],
  [MOCK_USERS.level3NTwoSubmissions]: [
    {
      dateOfBirth: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions].birthdate,
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.YES,
      dateSubmitted: '2022-04-03T02:32:58.606Z',
      nhiNumber: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions]['urn:login:health:nz:claims:nhi'],
      firstName: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions].given_name,
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions].family_name,
      address: '123 Example Street',
      gender: Gender.MALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions].email,
      phone: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0801',
          label: 'Te Atiawa (Taranaki)',
          region: {
            id: '08',
            label: 'Taranaki Region Iwi',
          },
          sanitisedLabel: 'teatiawataranaki',
        },
        {
          id: '0803',
          label: 'Ngāti Mutunga (Taranaki)',
          region: {
            id: '08',
            label: 'Taranaki Region Iwi',
          },
          sanitisedLabel: 'ngatimutungataranaki',
        },
      ],
    },
    {
      dateOfBirth: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions].birthdate,
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.PARTIALLY,
      dateSubmitted: '2022-01-01T02:32:58.606Z',
      nhiNumber: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions]['urn:login:health:nz:claims:nhi'],
      firstName: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions].given_name,
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions].family_name,
      address: '123 Example Street',
      gender: Gender.MALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions].email,
      phone: MOCK_USER_RESPONSES[MOCK_USERS.level3NTwoSubmissions]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0803',
          label: 'Ngāti Mutunga (Taranaki)',
          region: {
            id: '08',
            label: 'Taranaki Region Iwi',
          },
          sanitisedLabel: 'ngatimutungataranaki',
        },
      ],
    },
  ],
  [MOCK_USERS.level3NOneSubmissionOneOnBehalf]: [
    {
      dateOfBirth: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionOneOnBehalf].birthdate,
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.YES,
      dateSubmitted: '2022-11-01T02:32:58.606Z',
      nhiNumber: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionOneOnBehalf]['urn:login:health:nz:claims:nhi'],
      firstName: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionOneOnBehalf].given_name,
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionOneOnBehalf].family_name,
      address: '123 Example Street',
      gender: Gender.MALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionOneOnBehalf].email,
      phone:
        MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionOneOnBehalf]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0702',
          label: 'Ngāti Kahungunu ki Te Wairoa',
          region: {
            id: '07',
            label: "Te Matau-a-Māui/Wairarapa (Hawke's Bay/Wairarapa) Region iwi",
          },
          sanitisedLabel: 'ngatikahungunukitewairoa',
        },
        {
          id: '0701',
          label: 'Rongomaiwahine (Te Māhia)',
          region: {
            id: '07',
            label: "Te Matau-a-Māui/Wairarapa (Hawke's Bay/Wairarapa) Region iwi",
          },
          sanitisedLabel: 'rongomaiwahinetemahia',
        },
      ],
    },
    {
      dateOfBirth: '2000-10-08',
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.YES,
      dateSubmitted: '2022-11-01T02:52:58.606Z',
      nhiNumber: 'ZZ1234A',
      firstName: 'Alex',
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionOneOnBehalf].family_name,
      address: '123 Example Street',
      gender: Gender.FEMALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionOneOnBehalf].email,
      phone:
        MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionOneOnBehalf]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0702',
          label: 'Ngāti Kahungunu ki Te Wairoa',
          region: {
            id: '07',
            label: "Te Matau-a-Māui/Wairarapa (Hawke's Bay/Wairarapa) Region iwi",
          },
          sanitisedLabel: 'ngatikahungunukitewairoa',
        },
        {
          id: '0701',
          label: 'Rongomaiwahine (Te Māhia)',
          region: {
            id: '07',
            label: "Te Matau-a-Māui/Wairarapa (Hawke's Bay/Wairarapa) Region iwi",
          },
          sanitisedLabel: 'rongomaiwahinetemahia',
        },
      ],
    },
  ],
  [MOCK_USERS.level3NNoSubmissionsOneOnBehalf]: [
    {
      dateOfBirth: '1992-02-12',
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.YES,
      dateSubmitted: '2022-07-01T02:52:58.606Z',
      nhiNumber: 'ZZ9999B',
      firstName: 'Melanie',
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level3NNoSubmissionsOneOnBehalf].family_name,
      address: {
        addressLine1: '99 Mock Street',
        addressLine2: '',
        city: 'Mock city',
        postcode: '9999',
        suburb: 'Mockton',
      },
      gender: Gender.FEMALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level3NNoSubmissionsOneOnBehalf].email,
      phone:
        MOCK_USER_RESPONSES[MOCK_USERS.level3NNoSubmissionsOneOnBehalf]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '1109',
          label: 'Ngāi Tahu / Kāi Tahu',
          region: {
            id: '11',
            label: 'Te Waipounamu/Wharekauri (South Island/Chatham Islands) Region Iwi',
          },
          sanitisedLabel: 'ngaitahukaitahu',
        },
      ],
    },
  ],
  [MOCK_USERS.level3NOneSubmissionFiveOnBehalf]: [
    {
      dateOfBirth: '1992-02-01',
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.YES,
      dateSubmitted: '2022-08-01T02:52:58.606Z',
      nhiNumber: 'ZZ65655A',
      firstName: 'Tyler',
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf].family_name,
      address: {
        addressLine1: '99 Mock Street',
        addressLine2: '',
        city: 'Mock city',
        postcode: '9999',
        suburb: 'Mockton',
      },
      gender: Gender.ANOTHER_GENDER,
      genderAsStated: 'Non binary',
      email: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf].email,
      phone:
        MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0104',
          label: 'Ngāpuhi',
          region: {
            id: '01',
            label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
          },
          sanitisedLabel: 'ngapuhi',
        },
        {
          id: '0116',
          label: 'Ngāti Hine (Te Tai Tokerau)',
          region: {
            id: '01',
            label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
          },
          sanitisedLabel: 'ngatihinetetaitokerau',
        },
      ],
    },
    {
      dateOfBirth: '1993-04-11',
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.YES,
      dateSubmitted: '2022-08-01T03:12:58.606Z',
      nhiNumber: 'ZZ65656B',
      firstName: 'Kaitlyn',
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf].family_name,
      address: {
        addressLine1: '99 Mock Street',
        addressLine2: '',
        city: 'Mock city',
        postcode: '9999',
        suburb: 'Mockton',
      },
      gender: Gender.FEMALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf].email,
      phone:
        MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0104',
          label: 'Ngāpuhi',
          region: {
            id: '01',
            label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
          },
          sanitisedLabel: 'ngapuhi',
        },
        {
          id: '0116',
          label: 'Ngāti Hine (Te Tai Tokerau)',
          region: {
            id: '01',
            label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
          },
          sanitisedLabel: 'ngatihinetetaitokerau',
        },
      ],
    },
    {
      dateOfBirth: '1994-07-19',
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.YES,
      dateSubmitted: '2022-08-01T03:26:58.606Z',
      nhiNumber: 'ZZ65657C',
      firstName: 'Pierce',
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf].family_name,
      address: {
        addressLine1: '99 Mock Street',
        addressLine2: '',
        city: 'Mock city',
        postcode: '9999',
        suburb: 'Mockton',
      },
      gender: Gender.MALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf].email,
      phone:
        MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0104',
          label: 'Ngāpuhi',
          region: {
            id: '01',
            label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
          },
          sanitisedLabel: 'ngapuhi',
        },
        {
          id: '0116',
          label: 'Ngāti Hine (Te Tai Tokerau)',
          region: {
            id: '01',
            label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
          },
          sanitisedLabel: 'ngatihinetetaitokerau',
        },
      ],
    },
    {
      dateOfBirth: '1997-03-03',
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.YES,
      dateSubmitted: '2022-08-01T03:45:58.606Z',
      nhiNumber: 'ZZ65658D',
      firstName: 'Connor',
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf].family_name,
      address: {
        addressLine1: '99 Mock Street',
        addressLine2: '',
        city: 'Mock city',
        postcode: '9999',
        suburb: 'Mockton',
      },
      gender: Gender.MALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf].email,
      phone:
        MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0104',
          label: 'Ngāpuhi',
          region: {
            id: '01',
            label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
          },
          sanitisedLabel: 'ngapuhi',
        },
        {
          id: '0116',
          label: 'Ngāti Hine (Te Tai Tokerau)',
          region: {
            id: '01',
            label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
          },
          sanitisedLabel: 'ngatihinetetaitokerau',
        },
      ],
    },
    {
      dateOfBirth: '1998-05-29',
      isMaoriDescent: IsMaoriDescentResponses.YES,
      doesKnowIwi: KnowsIwiResponses.YES,
      dateSubmitted: '2022-08-01T03:53:58.606Z',
      nhiNumber: 'ZZ65659E',
      firstName: 'Emma',
      lastName: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf].family_name,
      address: '99 Mock Street, Mockton, Mock City, 9999',
      gender: Gender.FEMALE,
      email: MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf].email,
      phone:
        MOCK_USER_RESPONSES[MOCK_USERS.level3NOneSubmissionFiveOnBehalf]['urn:login:health:nz:claims:mobile_number'],
      affiliatedIwi: [
        {
          id: '0104',
          label: 'Ngāpuhi',
          region: {
            id: '01',
            label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
          },
          sanitisedLabel: 'ngapuhi',
        },
        {
          id: '0116',
          label: 'Ngāti Hine (Te Tai Tokerau)',
          region: {
            id: '01',
            label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
          },
          sanitisedLabel: 'ngatihinetetaitokerau',
        },
      ],
    },
  ],
};

export const handlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/submissions`, (req, res, ctx) => {
    const mockAccount = getCurrentMockAccount() || undefined;

    if (!mockAccount) {
      return res(ctx.delay(1000), ctx.status(403), ctx.json({ error: 'You must be logged in' }));
    }

    const submissions: TIwiSubmission[] = Object.keys(USERS_TO_SUBMISSIONS).includes(mockAccount)
      ? USERS_TO_SUBMISSIONS[mockAccount]
      : [];

    if (!submissions.length) {
      return res(ctx.delay(1000), ctx.status(200), ctx.json(submissions));
    }

    // separate submissions in to mine and on behalf of
    const mySubmissions = submissions.filter(
      (submission) => submission.nhiNumber === MOCK_USER_RESPONSES[mockAccount]['urn:login:health:nz:claims:nhi'],
    );

    const onBehalfOfSubmissions = submissions.filter(
      (submission) => submission.nhiNumber !== MOCK_USER_RESPONSES[mockAccount]['urn:login:health:nz:claims:nhi'],
    );

    // if I have more than one submission, we need to merge it
    if (mySubmissions.length > 1) {
      // sort the submissions by submitted date
      const latest = mySubmissions.sort((a, b) => {
        let dateA = new Date(a.dateSubmitted);
        let dateB = new Date(b.dateSubmitted);
        return dateA.getTime() - dateB.getTime();
      })[0];

      const mergedAffiliations: Iwi[] = mySubmissions.reduce((acc, curr, i) => {
        return [...acc, ...curr.affiliatedIwi];
      }, []);

      const merged = {
        merged: true,
        ...latest,
        affiliatedIwi: mergedAffiliations,
      };

      return res(ctx.delay(1000), ctx.status(200), ctx.json({ mySubmissions: [merged], onBehalfOfSubmissions }));
    }

    return res(ctx.delay(1000), ctx.status(200), ctx.json({ mySubmissions, onBehalfOfSubmissions }));
  }),
];

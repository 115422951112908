import { useVisuallyHidden } from 'react-aria';
import styled, { css } from 'styled-components';

// import { Box } from '../Box/Box';
import Icon from '../Icon/Icon';
import { ButtonPrint } from '../Typography/Typography';

export type ToggleSwitchOption<T> = {
  label: string;
  value: T;
  id: string;
};

interface IToggleSwitchProps<T> {
  id: string;
  label: string;
  currentValue: T | null;
  options: [ToggleSwitchOption<T>, ToggleSwitchOption<T>];
  onChange: (value: T) => void;
  disabled?: boolean;
  hideLabel?: boolean;
}

export const ToggleSwitch = <T extends string | number>({
  label,
  id,
  currentValue,
  options,
  disabled,
  hideLabel,
  onChange,
}: IToggleSwitchProps<T>) => {
  const { visuallyHiddenProps } = useVisuallyHidden();

  const handleChange = (value: T) => {
    if (!disabled) {
      onChange(value);
    }
  };

  return (
    <ToggleFieldset>
      {hideLabel ? <legend {...visuallyHiddenProps}>{label}</legend> : <legend>{label}</legend>}
      {options.map((option, index) => {
        const inputId = `input_${id}_${option.id}`;
        const active = option.value === currentValue;
        const background =
          index === 0 ? (
            <LeftButton>
              <LeftButtonEdge>
                <Icon name="ic-button-left" height="100%" width="14px" />
              </LeftButtonEdge>
              <LeftButtonCentre>
                <Icon name="ic-button-center" size="100%" />
              </LeftButtonCentre>
            </LeftButton>
          ) : (
            <RightButton>
              <RightButtonEdge>
                <Icon name="ic-button-right" height="100%" width="14px" />
              </RightButtonEdge>
              <RightButtonCentre>
                <Icon name="ic-button-center" size="100%" />
              </RightButtonCentre>
            </RightButton>
          );

        return (
          <ToggleOption active={active} key={option.id}>
            <ToggleOptionInput
              type="radio"
              id={inputId}
              value={option.value}
              checked={option.value === currentValue}
              onChange={() => handleChange(option.value)}
              {...visuallyHiddenProps}
            />
            <ToggleOptionLabel htmlFor={inputId}>
              <ToggleBackgroundWrapper>{background}</ToggleBackgroundWrapper>
              <ButtonPrint style={{ position: 'relative' }} m={0}>
                {option.label}
              </ButtonPrint>
            </ToggleOptionLabel>
          </ToggleOption>
        );
      })}
    </ToggleFieldset>
  );
};

const ToggleFieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  position: relative;
  &:before {
    content: '';
    width: 50px;
    height: 100%;
    background: ${(props) => props.theme.colors.pango};
    position: absolute;
    left: 50%;
    margin-left: -25px;
  }
`;

interface IToggleOptionProps {
  active: boolean;
}

const ToggleBackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const LeftButton = styled.div`
  border-radius: 4px 0 0 4px;
  overflow: hidden;
  height: 100%;
  position: relative;
`;

const LeftButtonEdge = styled.div`
  position: absolute;
  height: 100%;
  svg {
    stroke: ${(props) => props.theme.colors.pango};
    stroke-width: 2px;
  }
`;

const LeftButtonCentre = styled.div`
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  right: 0;

  svg {
    border-top: 1px solid ${(props) => props.theme.colors.pango};
    border-bottom: 1px solid ${(props) => props.theme.colors.pango};
    stroke-width: 0;
  }
`;

const RightButton = styled.div`
  borderradius: 0 4px 4px 0;
  overflow: hidden;
  height: 100%;
  position: relative;
`;

const RightButtonCentre = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  width: calc(100% - 10px);
  svg {
    border-top: 1px solid ${(props) => props.theme.colors.pango};
    border-bottom: 1px solid ${(props) => props.theme.colors.pango};
    stroke-width: 0;
  }
`;

const RightButtonEdge = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  svg {
    stroke: ${(props) => props.theme.colors.pango};
    stroke-width: 2px;
  }
`;

const ToggleOptionInput = styled.input``;

const ToggleOptionLabel = styled.label`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  height: 56px;
  cursor: pointer;
`;

const ToggleOption = styled.div<IToggleOptionProps>`
  display: inline-flex;
  user-select: none;
  position: relative;
  flex-grow: 1;
  height: 56px;
  width: 100%;

  :first-of-type {
    border-radius: 4px 0 0 4px;
  }
  :last-of-type {
    border-radius: 0 4px 4px 0;
  }

  ${ToggleOptionInput}:focus + ${ToggleOptionLabel} {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  svg {
    fill: ${(props) => props.theme.colors.ma};
    color: ${(props) => props.theme.colors.pango};
  }

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.colors.white};
      svg {
        fill: ${(props) => props.theme.colors.pango};
    `}
`;

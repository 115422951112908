import { useRef, useState } from 'react';
import { useVisuallyHidden } from 'react-aria';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';

import { useIwiAffiliationData } from '../../hooks/useIwiAffiliationData';
import { useIwiAffiliationFormContext } from '../../hooks/useIwiAffiliationFormContext';
import { useIwiLookup } from '../../hooks/useIwiLookup';
import { usePrefersReducedMotion } from '../../hooks/usePrefersReducedMotion';
import { Iwi, IwiRegion } from '../../types';
import { FormStep } from '../FormStep';
import { FormSteps } from '../IwiAffiliationFormContext';
import { IwiSelect } from '../IwiSelect';
import { NotificationBar } from '../NotificationBar';
import { RegionSelect } from '../RegionSelect';
import { SelectedIwiDialog } from '../SelectedIwiDialog';

import { MaoriLanguage } from '@/components/Accessibility/MaoriLanguage';
import { Box } from '@/components/Box/Box';
import { ScrollToTopButton } from '@/components/Button/ScrollToTopButton';
import { FlowFooter } from '@/components/FlowFooter/FlowFooter';
import { FlowFooterMap } from '@/components/FlowFooterMap/FlowFooterMap';
import { InputError } from '@/components/InputError/InputError';
import { DefaultLayout } from '@/components/Layouts/Layouts';
import { Map } from '@/components/Map/Map';
import { ToggleSwitch } from '@/components/ToggleSwitch/ToggleSwitch';
import { Body2, H2 } from '@/components/Typography/Typography';

enum SEARCH_BY {
  NAME = 'name',
  REGION = 'region',
}

export const Step2IwiLookup = () => {
  const isDesktopOrAbove = useBreakpoint(up('lg'));
  const { formData, goToNextStep, setFormData, goToPreviousStep } = useIwiAffiliationFormContext();
  const { regions } = useIwiAffiliationData();
  const stepData = formData[FormSteps.IWI_LOOKUP].affiliatedIwi || [];
  const focusRef = useRef<HTMLDivElement>(null);

  const prefersReducedMotion = usePrefersReducedMotion();
  const mapWrapperRef = useRef<HTMLDivElement>(null);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const close = () => setShowDialog(false);
  const stepSubmitHandler = (selectedItems: Iwi[]) => {
    const formDataUpdated = { ...formData, [FormSteps.IWI_LOOKUP]: { affiliatedIwi: selectedItems } };

    setFormData(formDataUpdated);
    goToNextStep(formDataUpdated);
  };

  const searchByChangeHandler = (value: string) => {
    setSearchBy(value as SEARCH_BY);
  };

  const [searchBy, setSearchBy] = useState<SEARCH_BY>(SEARCH_BY.NAME);
  const { visuallyHiddenProps } = useVisuallyHidden();

  const {
    selectedItems = [],
    handleItemSelection,
    region,
    setRegion,
    submitWrapper,
    showErrors,
    recentlyAdded,
    recentlyRemoved,
    setSelectedItems,
  } = useIwiLookup({ lookupData: stepData, onSubmit: stepSubmitHandler });

  const regionZoomHandler = (regionId: string) => {
    const selectedRegion = regions?.find((region: IwiRegion) => region.formattedId === regionId);
    setRegion(selectedRegion);

    if (!prefersReducedMotion && !isDesktopOrAbove) {
      // Scroll the top of the map to the top of the screen
      window?.scrollTo({ top: mapWrapperRef.current?.offsetTop, behavior: 'smooth' });
    }
  };

  return (
    <DefaultLayout>
      {showErrors && <InputError>Error: Please select their iwis from the list</InputError>}

      <FormStep
        aria-labelledby="label_formStep"
        goToPreviousStep={() => goToPreviousStep(formData)}
        onSubmit={submitWrapper}
        height={{ _: 'auto', lg: 'calc(100vh - 120px)' }}
        pt="8px"
        px={{ md: '32px ' }}
        mt={{ _: '65px', md: '144px' }}
      >
        <Box color="white" px={{ _: '30px' }} mt={{ _: 30, md: 55 }} textAlign={{ md: 'center' }}>
          <div tabIndex={-1} ref={focusRef}>
            <H2 color="white" id="label_formStep">
              Type or select their <MaoriLanguage>iwi</MaoriLanguage> and their <MaoriLanguage>rohe</MaoriLanguage> /
              region (if known)
            </H2>
          </div>
          <Body2 my="8px" mb={{ md: 40 }} color="white">
            Please provide all <MaoriLanguage>iwi</MaoriLanguage> that they <MaoriLanguage>whakapapa</MaoriLanguage> to
          </Body2>
        </Box>

        {isDesktopOrAbove === false && (
          <Box role="radiogroup" width="100%" aria-labelledby="label_searchBy">
            <label id="label_searchBy" htmlFor="searchBy" {...visuallyHiddenProps}>
              Search for iwi by:
            </label>
            <ToggleSwitch
              onChange={searchByChangeHandler}
              id="searchBy"
              label="Search for iwi by"
              hideLabel
              options={[
                {
                  label: 'List',
                  value: SEARCH_BY.NAME,
                  id: 'byName',
                },
                {
                  id: 'byRegion',
                  label: 'Map',
                  value: SEARCH_BY.REGION,
                },
              ]}
              currentValue={searchBy}
            />
          </Box>
        )}

        <Box
          display="flex"
          flexShrink={1}
          flexGrow={0}
          justifyContent={{ lg: 'center' }}
          flexDirection={{ _: 'column', lg: 'row' }}
          width="100%"
          height="100%"
          overflow={{ lg: 'hidden' }}
        >
          {(searchBy === SEARCH_BY.REGION || isDesktopOrAbove) && (
            <Box
              display="flex"
              flexDirection={'column'}
              flexGrow={1}
              flexBasis={{ lg: '50%', xl: '50%' }}
              maxWidth={{ _: '800px', lg: '600px', xl: '800px' }}
              py={{ _: 16, lg: 0 }}
              flexShrink={1}
              ref={mapWrapperRef}
              mr={32}
            >
              <Map onZoom={regionZoomHandler} onRegionSelect={setRegion} region={region && region.id} />
              {isDesktopOrAbove && (
                <>
                  <FlowFooterMap />
                </>
              )}
            </Box>
          )}

          <Box
            display="flex"
            flexBasis={{ lg: '50%', xl: '50%' }}
            pt={{ _: 24, lg: 0 }}
            maxWidth={{ lg: 689 }}
            flexDirection="column"
            position="relative"
            flexShrink={1}
            flexGrow={1}
          >
            {(searchBy === SEARCH_BY.NAME || isDesktopOrAbove) && (
              <RegionSelect region={region} onRegionSelect={setRegion} />
            )}

            <IwiSelect
              cy-data="iwi-list"
              selectedItems={selectedItems}
              onItemSelect={handleItemSelection}
              region={region}
              includeTextFilter={searchBy === SEARCH_BY.NAME || isDesktopOrAbove === true}
            />

            {showDialog && (
              <SelectedIwiDialog
                onClose={close}
                setSelectedItems={setSelectedItems}
                onItemSelect={handleItemSelection}
                selectedItems={selectedItems}
              />
            )}

            {isDesktopOrAbove === false && (
              <>
                <Box
                  pb={{ _: '100px', md: '20px', xl: '0px' }}
                  mt={16}
                  display="flex"
                  flexGrow={0}
                  flexShrink={0}
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                >
                  <ScrollToTopButton focusRef={focusRef} />
                  <FlowFooter />
                </Box>
              </>
            )}

            <NotificationBar
              selectedItems={selectedItems}
              showDialog={setShowDialog}
              recentlyAdded={recentlyAdded}
              recentlyRemoved={recentlyRemoved}
            />
          </Box>
        </Box>
      </FormStep>
    </DefaultLayout>
  );
};

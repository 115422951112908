import React, { forwardRef } from 'react';
import { useHover, mergeProps } from 'react-aria';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { space, SpaceProps, layout, LayoutProps } from 'styled-system';

import { Box } from '../Box/Box';
import { ButtonPrint2 } from '../Typography/Typography';

type ButtonVariant = 'dark' | 'light';

interface IButtonProps extends SpaceProps, LayoutProps, React.HTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  type: 'button' | 'submit' | 'reset';
  label: string;
  label2: string;
  dataCy?: string;
  icon?: any;
}

export const Button2 = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const { label, label2, variant, dataCy, icon, ...otherProps } = props;
  const { hoverProps } = useHover({});

  const combinedProps = mergeProps(otherProps, hoverProps);

  return (
    <ButtonRoot ref={ref} variant={variant} data-cy={dataCy} {...combinedProps}>
      <ButtonBackground2 variant={variant} />
      <Box position="relative" display="flex" flexShrink={0} flexGrow={0} padding={16}>
        <ButtonPrint2 as="span">
          {label}
          <span style={{ fontStyle: 'normal', fontWeight: '700' }}>{label2}</span>
          {icon && <StyledImage src={icon} alt="External Link" />}
        </ButtonPrint2>
      </Box>
    </ButtonRoot>
  );
});

Button2.displayName = 'Button2';
export const Button3 = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const { label, label2, variant, dataCy, icon, ...otherProps } = props;
  const { hoverProps } = useHover({});

  const combinedProps = mergeProps(otherProps, hoverProps);

  return (
    <ButtonRoot ref={ref} variant={variant} data-cy={dataCy} {...combinedProps}>
      <ButtonBackground3 variant={variant} />
      <Box position="relative" display="flex" flexShrink={0} flexGrow={0}>
        <ButtonPrint2 as="span">
          {label}
          <span style={{ fontStyle: 'normal', fontWeight: '700' }}>{label2}</span>
          {icon && <StyledImage src={icon} alt="External Link" />}
        </ButtonPrint2>
      </Box>
    </ButtonRoot>
  );
});

Button3.displayName = 'Button3';

const ButtonBackground2 = (props: { variant: ButtonVariant }) => (
  <Box
    size="100%"
    display="flex"
    flexGrow={0}
    flexShrink={0}
    flexDirection="row"
    position="absolute"
    zIndex={5}
    left={0}
    top={0}
    bottom={0}
    right={0}
    aria-hidden="true"
    backgroundColor="#FFF"
    border="1px solid"
    borderRadius="8px"
  ></Box>
);
const ButtonBackground3 = (props: { variant: ButtonVariant }) => (
  <Box
    size="100%"
    display="flex"
    flexGrow={0}
    flexShrink={0}
    flexDirection="row"
    position="absolute"
    zIndex={5}
    left={0}
    top={0}
    bottom={0}
    right={0}
    aria-hidden="true"
    backgroundColor="#000"
    border="1px solid #000"
    borderRadius="8px"
  ></Box>
);

const ButtonRoot = styled.button<{ variant: ButtonVariant }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 56px;
  padding: 13px 45px;
  max-width: 280px;
  cursor: pointer;
  border: 0;
  background: 0;
  border-radius: 4px;
  overflow: hidden;
  appearance: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  font-family: 'Fira Sans !important';
  ${space};
  ${layout};

  span {
    z-index: 20;
    white-space: nowrap;
  }

  svg {
    stroke-width: 2px;
  }

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${(props) => props.theme.colors.pango};

      svg {
        /* stroke-width: px; */
        fill: ${(props) => props.theme.colors.white};
        stroke: ${(props) => props.theme.colors.pango};
      }

      :hover {
        svg {
          fill: #ddd;
        }
      }

      :active {
        svg {
          fill: ${(props) => props.theme.colors.white};
        }
      }
    `};

  ${({ variant }) =>
    variant === 'dark' &&
    css`
      color: ${(props) => props.theme.colors.white};

      svg {
        fill: ${(props) => props.theme.colors.pango};
        stroke: ${(props) => props.theme.colors.pango};
      }

      :hover {
        svg {
          fill: #666;
          stroke: #666;
        }
      }

      :active {
        svg {
          fill: ${(props) => props.theme.colors.pango};
          stroke: ${(props) => props.theme.colors.pango};
        }
      }
    `};
`;

const StyledImage = styled.img`
  margin-left: 8px;
  vertical-align: baseline;
  ${up('md')} {
    margin-left: 16px;
    vertical-align: top;
  }
`;

import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { Box } from '@/components/Box/Box';
import { Button, LinkButton } from '@/components/Button/Button';
import { Container } from '@/components/Container/Container';
import { FooterNavigation } from '@/components/FooterNavigation/FooterNavigation';
import { GlobalHeader } from '@/components/GlobalHeader/GlobalHeader';
import { Head } from '@/components/Head/Head';
import { InputError } from '@/components/InputError/InputError';
import { useLandingContext } from '@/components/LandingContextProvider/useLandingContext';
import { DesignedLayoutBackground } from '@/components/Layouts/Layouts';
import { TextInput } from '@/components/TextInput/TextInput';
import { Body2, H1, H2 } from '@/components/Typography/Typography';
import { Navigation } from '@/features/iwiAffiliationForm/components/Navigation';

export const ConfirmDeleteData = () => {
  const data = window.history.state.usr?.data;
  const {
    sectionConfig: { textColor },
  } = useLandingContext();
  const navigate = useNavigate();
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      code: '',
    },
  });
  const [boxError, setBoxError] = useState<boolean>(false);
  const confirmDelete = (formData) => {
    if (formData.code.toLowerCase() === 'delete') {
      setBoxError(false);
      navigate('/delete-account', { state: { name: data.preferredName, data: data } });
    } else {
      setBoxError(true);
    }
  };
  return (
    <>
      <Head />
      <DesignedLayoutBackground>
        <GlobalHeader variantOverride="dark" />

        <Container>
          <Navigation
            goToPreviousStep={() => navigate('/edit-details', { state: { previousStep: 'edit-details', data: data } })}
            labelled-by="linked-account"
            title="Update account details"
            padding="0px"
            marginTop={{ _: '115px' }}
            minHeight={{ _: 'calc(100vh - 350px)', lg: 'calc(100vh - 215px)' }}
            display="block"
            width="100%"
            maxWidth="600px"
          >
            <Box textAlign="left">
              <H1 color={textColor} mt={{ _: 24, md: 75 }}>
                Delete data
              </H1>
            </Box>
            <Box
              maxWidth="597px"
              pb={{ _: 120, md: 200 }}
              display="block"
              flexShrink={0}
              flexGrow={0}
              color={textColor}
            >
              <Box mt={{ _: '32px' }}>
                <H2>Delete my iwi affiliations</H2>
                <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                  Name
                </Body2>
                <Body2 mb="8px">{data.preferredName ? data.preferredName : '-'}</Body2>
                <Body2 mt={{ _: '16px', md: '32px' }} fontWeight={700}>
                  Enter code to delete
                </Body2>
                <Body2 mt="8px">
                  Enter &apos;Delete&apos; into the box below then click the Delete button to delete your iwi
                  affiliations and unlink my health information from Tātai&apos;s records.
                </Body2>
                <Box maxWidth="200px" mt={{ _: '16px', md: '32px' }}>
                  <form>
                    <Controller
                      control={control}
                      name="code"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => <TextInput dataCy="delete" label="" id="delete" {...field} />}
                    />
                    {errors.code || boxError ? (
                      <Box>
                        <InputError>Enter a valid code</InputError>
                      </Box>
                    ) : null}
                    <Box mt="32px" display="flex" flexDirection="row">
                      <LinkButton
                        label="Cancel"
                        to="/manage"
                        data-cy="cancel-button"
                        maxWidth="103px"
                        variant="light"
                        mr="16px"
                      />
                      <Button
                        type="submit"
                        label="Next"
                        onClick={handleSubmit(confirmDelete)}
                        data-cy="confirm-delete-account-button"
                        maxWidth="83px"
                      />
                    </Box>
                  </form>
                </Box>
              </Box>
            </Box>
          </Navigation>
        </Container>

        <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }}>
          <FooterNavigation />
        </Box>
      </DesignedLayoutBackground>
    </>
  );
};

import React, { FocusEventHandler, forwardRef, ReactNode, useState, useCallback, HTMLProps } from 'react';
import { useVisuallyHidden } from 'react-aria';
import { FieldError } from 'react-hook-form';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

import { InputError } from '../InputError/InputError';
import { ErrorBar } from '../TextInput/TextInput';
import { Body2 } from '../Typography/Typography';

interface ICheckboxProps extends HTMLProps<HTMLInputElement> {
  id: string;
  error?: FieldError;
  children: ReactNode;
}

export const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  ({ id, className, error, children, onFocus: onFocusProp, onBlur: onBlurProp, ...rest }: ICheckboxProps, ref) => {
    const { visuallyHiddenProps } = useVisuallyHidden();

    const hasError = Boolean(error);
    const errorId = `${id}-error`;

    const [isFocused, setIsFocused] = useState(false);

    const onFocus: FocusEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        setIsFocused(true);
        onFocusProp?.(event);
      },
      [onFocusProp],
    );
    const onBlur: FocusEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        setIsFocused(false);
        onBlurProp?.(event);
      },
      [onBlurProp],
    );

    return (
      <CheckboxWrapper>
        <CheckboxRoot className={className} id={`label-${id}`} htmlFor={id} hasError={hasError}>
          {/* The `ref` prop doesn't work with a styled.input for some reason. It's invisible anyway to not much use in styling it */}
          <input
            {...visuallyHiddenProps}
            id={id}
            ref={ref}
            type="checkbox"
            onFocus={onFocus}
            onBlur={onBlur}
            aria-labelledby={`label-${id}`}
            aria-describedby={errorId}
            aria-invalid={hasError}
            {...rest}
          />
          <VisibleCheckbox isFocused={isFocused} />
          <Body2 as="span">{children}</Body2>
          {hasError && <ErrorBar />}
        </CheckboxRoot>
        {error?.message && (
          <InputError aria-live="polite" id={errorId}>
            {error.message}
          </InputError>
        )}
      </CheckboxWrapper>
    );
  },
);

Checkbox.displayName = 'Checkbox';

const CheckboxWrapper = styled.div`
  margin-bottom: 16px;

  ${up('md')} {
    margin-bottom: 32px;
  }
`;

const VisibleCheckbox = styled.div<{ isFocused: boolean }>`
  &:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    background-image: url('/icons/ic-checkbox.svg');
    background-size: 24px 24px;
    background-position: center center;

    ${(props) =>
      props.isFocused &&
      `
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    `}
  }
`;

const CheckboxRoot = styled.label<{ hasError: boolean }>`
  display: flex;

  /* For the ErrorBar */
  position: relative;

  cursor: pointer;

  background-color: ${(props) => props.theme.colors.ma};
  border: 1px solid rgba(191, 84, 34, 0.5);

  border-radius: 4px;
  padding: 24px;

  ${up('md')} {
    padding: 16px;
  }

  > input:checked + ${VisibleCheckbox}::before {
    background-image: url('/icons/ic-checkbox-checked.svg');
  }
`;

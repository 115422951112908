import { useRef } from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import { MaoriLanguage } from '../components/Accessibility/MaoriLanguage';
import { Head } from '../components/Head/Head';
import { LegalPageWrapper, LegalH2, LegalBody2 } from '../components/LegalPages/LegalPages';
import { BoldA, LightA } from '../components/Links/Links';
import { H2, Fineprint } from '../components/Typography/Typography';

import { Box } from '@/components/Box/Box';
import { ScrollToTopButton } from '@/components/Button/ScrollToTopButton';
import { FooterNavigation } from '@/components/FooterNavigation/FooterNavigation';

const TermsOfUsePage = () => {
  const isDesktop = useBreakpoint(up('md'));
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  return (
    <Wrapper>
      <Box mt={{ _: '20px', md: '100px' }}>
        <LegalPageWrapper>
          <Head />
          <H2 as="h1">Terms of Use</H2>

          <LegalBody2>By viewing and using this service, you agree to these Terms of Use.</LegalBody2>

          <LegalH2>Agreement to terms</LegalH2>

          <LegalBody2>
            Welcome to <MaoriLanguage>Tātai</MaoriLanguage>, a place where <MaoriLanguage>Māori</MaoriLanguage> can
            share their <MaoriLanguage>iwi</MaoriLanguage> affiliations, with <MaoriLanguage>Iwi</MaoriLanguage>, and
            the Ministry of Health, to make a positive difference today, and for future generations.
          </LegalBody2>
          <LegalBody2>
            These terms apply to the entire operation and content of the service, to any web feeds produced by the
            service, any interactions you may have with the service, and to any feedback or submissions provided to the
            service.
          </LegalBody2>

          <LegalH2>Purpose</LegalH2>

          <LegalBody2>
            The service is only for the purposes as outlined in the{' '}
            <BoldA to="/privacy-policy">Privacy Statement</BoldA>. Your use of this website is governed by New Zealand
            law.
          </LegalBody2>

          <LegalH2>Disclaimer</LegalH2>

          <LegalBody2>
            Except where we have an explicit legal obligation under New Zealand legislation we disclaim and exclude all
            liability for any claim, loss, demand or damages of any kind whatsoever (including for our negligence)
            arising out of or in connection with the use of either this service or the information, content or materials
            included on this service or on any website we link to. It is your responsibility to provide accurate
            information to us, and we are entitled to rely, without making further inquiry, on information provided by
            you or any third party you choose to interact with via this service.
          </LegalBody2>

          <LegalH2>Continuity of service</LegalH2>

          <LegalBody2>
            We make no warranty or representation, express or implied, as to continuity of service. We reserve the right
            to suspend, terminate or otherwise alter access to some or all of the services at any time and without
            notice.
          </LegalBody2>

          <LegalH2>Revisions to these Terms of Use</LegalH2>

          <LegalBody2>
            We may revise these Terms of Use at any time. Any such revisions take effect when this page is published.
          </LegalBody2>

          <LegalH2>Security</LegalH2>

          <LegalBody2>
            If you discover a potential security vulnerability or suspect a security incident related to this service,
            it can be reported by sending an email to{' '}
            <LightA to="mailto:itsecurity@health.govt.nz">itsecurity@health.govt.nz</LightA>, or by following the
            disclosure process on <BoldA to="https://www.cert.govt.nz/">cert.govt.nz</BoldA>. The service has been and
            will continue to be subjected to independent security audits.
          </LegalBody2>

          <Fineprint>Last updated: 09 September 2021</Fineprint>
        </LegalPageWrapper>
      </Box>
      {/* Footer navigation and back to top buttons */}
      <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }} zIndex={10} mt="auto">
        <FooterNavigation />

        {!isDesktop && (
          <Box mt={32} display="flex" justifyContent={{ _: 'center' }} flexShrink={0} flexGrow={0}>
            <ScrollToTopButton focusRef={mainTitleRef} />
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

export default TermsOfUsePage;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import externalLinkLight from '../assets/icons/externalLinkLight.svg';
// import closeButton from '../assets/icons/closeButton.svg';

import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { ScrollToTopButton } from '@/components/Button/ScrollToTopButton';
import { Container } from '@/components/Container/Container';
import { FooterNavigation } from '@/components/FooterNavigation/FooterNavigation';
import { GlobalHeader } from '@/components/GlobalHeader/GlobalHeader';
import { Head } from '@/components/Head/Head';
import { ErrorBanner, InputError } from '@/components/InputError/InputError';
import { DesignedLayoutBackground } from '@/components/Layouts/Layouts';
import { TextInput } from '@/components/TextInput/TextInput';
import { Body, Body2, Body3, H2 } from '@/components/Typography/Typography';
import { Gender } from '@/features/iwiAffiliationForm';
import { Navigation } from '@/features/iwiAffiliationForm/components/Navigation';
import { useUpdateIwiAffiliation } from '@/features/iwiAffiliationForm/hooks/useUpdateIwiAffiliation';
import { isEmailValid } from '@/tools/email';
import { isPhoneValid } from '@/tools/phone';
import { TEXT_VALIDATION_REGEX } from '@/tools/text';

export const EditDetails = () => {
  const data = window.history.state.usr?.data;
  const navigate = useNavigate();
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  const isDesktop = useBreakpoint(up('md'));
  const [isAttemptingSubmission, setIsAttemptingSubmissions] = useState<boolean>(false);
  // const [showToast, setShowToast] = useState<boolean>(false);

  const { isLoading, updateIwiAffiliation, showSubmissionError } = useUpdateIwiAffiliation({
    onSuccessOfSubmission: () => {
      if (!isLoading) {
        // setShowToast(true);
        // window.scrollTo(0, 0);
        setIsAttemptingSubmissions(false);
        window.sessionStorage.setItem('showToast', 'true');
        navigate('/manage', { state: { showToast: true } });
      }
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: {
      preferredName: data?.preferredName || '',
      gender: data?.gender || '',
      genderAsStated: data?.genderAsStated || '',
      email: data?.email || '',
      phone: data?.phone || '',
      isMaoriDescent: data?.isMaoriDescent || 'YES',
      doesKnowIwi: data?.doesKnowIwi || 'YES',
    },
  });
  const watchGender = watch('gender', data.gender);
  const updateDetails = (formData) => {
    if (isLoading || isAttemptingSubmission) {
      return;
    }
    setIsAttemptingSubmissions(true);
    formData['lastUpdatedTime'] = data.lastUpdatedTime;
    const filteredData = Object.fromEntries(Object.entries(formData).filter(([key, value]) => value !== ''));
    if (filteredData.gender !== Gender.ANOTHER_GENDER) {
      delete filteredData.genderAsStated;
    }
    window.sessionStorage.setItem('preferredName', formData.preferredName);
    updateIwiAffiliation(filteredData);
  };
  const deleteButtonClick = () => {
    navigate('/confirm-delete-account', { state: { data: data } });
  };
  const handleSelect = (event) => {
    console.log('Handle select : ', event);
  };
  return (
    <>
      <Head />
      <DesignedLayoutBackground>
        <GlobalHeader variantOverride="dark" />

        <Container>
          <Navigation
            goToPreviousStep={() => navigate('/manage', { state: { previousStep: 'manage' } })}
            labelled-by="manage-page"
            title="Account"
            padding="0px"
            mt={{ _: '65px', md: '144px' }}
          >
            {/* {showToast && (
              <ToastWrapper>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Body2> Your account details have been updated.</Body2>
                  <ImgButton type="button" onClick={() => setShowToast(false)}>
                    <img src={closeButton} alt="Plus Icon" />
                  </ImgButton>
                </Box>
              </ToastWrapper>
            )} */}

            <Box maxWidth={689} width="100%" pb={64} mt={{ _: 15, md: 55 }}>
              <Box alignSelf="flex-start" my={16} mt={{ md: 0 }} color="white">
                <H2 id="label_formStep">Edit account details</H2>
                <Body mb="16px" mt="16px" textAlign="left">
                  Account details
                </Body>
                <FormInputRow>
                  <Controller
                    control={control}
                    defaultValue={data.preferredName}
                    name="preferredName"
                    rules={{
                      required: 'Please enter your preferred name',
                      pattern: {
                        value: TEXT_VALIDATION_REGEX,
                        message:
                          'Preferred name must not contain any special characters or start or finish with a space.',
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        dataCy="preferred-name"
                        placeholder="Enter your preferred name"
                        autoComplete="preferred-name"
                        label="Preferred name"
                        id="preferredName"
                        aria-required="true"
                        {...field}
                      />
                    )}
                  />
                  {errors.preferredName && (
                    <Box>
                      <InputError>Please enter your full name</InputError>
                    </Box>
                  )}
                </FormInputRow>
                <FormInputRow>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <>
                        <Body2 fontSize={16} mt={{ _: '16px' }} mb="8px" fontWeight={400}>
                          Gender
                        </Body2>
                        <SelectWrapper>
                          <Select value={data.gender} style={{ width: '100%' }} onSelect={handleSelect} {...field}>
                            <option value="Not Provided">Select Gender</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                            <option value="ANOTHER_GENDER">Another Gender</option>
                            <option value="PREFER_NOT_TO_SAY">Prefer Not To Say</option>
                          </Select>
                        </SelectWrapper>
                      </>
                    )}
                    name="gender"
                  />
                </FormInputRow>
                {watchGender === Gender.ANOTHER_GENDER && (
                  <FormInputRow>
                    <Controller
                      name="genderAsStated"
                      defaultValue={data.genderAsStated || ''}
                      control={control}
                      rules={{
                        required: 'Please state your gender',
                        pattern: {
                          value: TEXT_VALIDATION_REGEX,
                          message:
                            'Please state your gender. It must not contain any special characters or start or finish with a space',
                        },
                      }}
                      render={({ field }) => (
                        <TextInput
                          placeholder="Enter your gender"
                          id="input_gender_as_stated"
                          autoComplete="off"
                          label="Please state"
                          maxLength={30}
                          {...field}
                        />
                      )}
                    />
                    {errors.genderAsStated && (
                      <Box>
                        <InputError>Please state your gender</InputError>
                      </Box>
                    )}
                  </FormInputRow>
                )}
                <FormInputRow>
                  <Controller
                    control={control}
                    rules={{
                      validate: (value?: string) => {
                        if (!value) {
                          return 'Please enter an email or phone number before continuing';
                        } else {
                          clearErrors('phone');
                        }

                        if (value) {
                          const isValidEmail = isEmailValid(value);

                          if (!isValidEmail) {
                            return 'Please enter a valid email';
                          }
                        }

                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        dataCy="email"
                        placeholder="Enter your email"
                        autoComplete="email"
                        label="Email"
                        id="email"
                        aria-required="true"
                        value={data.email ? data.email : ''}
                        {...field}
                      />
                    )}
                    name="email"
                  />
                  {errors.email && (
                    <Box>
                      <InputError>Please enter a valid email</InputError>
                    </Box>
                  )}
                </FormInputRow>
                <FormInputRow>
                  <Controller
                    control={control}
                    rules={{
                      validate: (value?: string) => {
                        if (value) {
                          const isValidPhone = isPhoneValid(value);

                          if (!isValidPhone) {
                            return 'Please provide a valid contact number';
                          }
                        }
                        return true;
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        dataCy="mobile-number"
                        placeholder="Enter your contact number"
                        autoComplete="mobile-number"
                        label="Contact number"
                        id="phone"
                        aria-required="true"
                        value={data.phone}
                        {...field}
                      />
                    )}
                    name="phone"
                  />
                  {errors.phone && (
                    <Box>
                      <InputError>Please provide a valid contact number</InputError>
                    </Box>
                  )}
                </FormInputRow>
                <FormInputRow>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <>
                        <Body2 fontSize={16} mt={{ _: '16px' }} mb="8px" fontWeight={400}>
                          Māori descent
                        </Body2>
                        <SelectWrapper>
                          <Select value={data.isMaoriDescent} style={{ width: '100%' }} {...field}>
                            <option value="YES">Yes</option>
                            <option value="DONT_KNOW">Don&apos;t Know</option>
                          </Select>
                        </SelectWrapper>
                      </>
                    )}
                    name="isMaoriDescent"
                  />
                </FormInputRow>
                <FormInputRow>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <>
                        <Body2 fontSize={16} mt={{ _: '16px' }} mb="8px" fontWeight={400}>
                          Iwi name(s) known
                        </Body2>
                        <SelectWrapper>
                          <Select value={data.doesKnowIwi} style={{ width: '100%' }} {...field}>
                            <option value="YES">Yes</option>
                            <option value="PARTIALLY">Partially</option>
                            <option value="NO">No</option>
                          </Select>
                        </SelectWrapper>
                      </>
                    )}
                    name="doesKnowIwi"
                  />
                </FormInputRow>
                <Box>
                  {showSubmissionError && (
                    <Box mb={32}>
                      <ErrorBanner>
                        There&apos;s been an error trying to update your details. Please try again later.
                      </ErrorBanner>
                    </Box>
                  )}
                </Box>
                <Button
                  type="submit"
                  label={isLoading || isAttemptingSubmission ? 'Loading...' : 'Update details'}
                  onClick={handleSubmit(updateDetails)}
                />
                {/* </form> */}
                <Box width="100%" mt="32px">
                  <H2>Delete my data</H2>
                  <Body3 fontSize={16} maxWidth={{ md: '596px' }} mt={{ _: '16px' }} fontWeight={400}>
                    Delete my iwi affiliations and my health information from Tātai&apos;s records.
                  </Body3>
                  <Body3 fontSize={16} maxWidth={{ md: '596px' }} mt={{ _: '16px' }} fontWeight={400}>
                    To unlink your account from from your My Health Account, visit{' '}
                    <a href="https://identity.health.nz/">https://identity.health.nz/ </a>
                    <StyledImage src={externalLinkLight} alt="External Link" /> {'  '}and remove Tātai from the
                    &quot;Connected services&quot; section.
                  </Body3>

                  <Button
                    type="button"
                    label="Delete data"
                    onClick={deleteButtonClick}
                    data-cy="delete-data-button"
                    maxWidth="152px"
                    variant="dark"
                    mt="16px"
                  />
                </Box>
              </Box>
            </Box>
          </Navigation>
        </Container>

        <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }}>
          <FooterNavigation />

          {!isDesktop && (
            <Box mt={32} display="flex" justifyContent={{ _: 'center' }} flexShrink={0} flexGrow={0}>
              <ScrollToTopButton focusRef={mainTitleRef} />
            </Box>
          )}
        </Box>
      </DesignedLayoutBackground>
    </>
  );
};

const FormInputRow = styled.div`
  width: 100%;
  margin-bottom: 24px;
  position: relative;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  :after {
    pointer-events: none;
    content: '';
    display: inline-block;
    background-image: url('/icons/select-icons.svg');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    background-size: 16px;
    position: absolute;
    right: 16px;
    transform: translateY(-25%);
  }
`;

const Select = styled.select`
  appearance: none;
  text-overflow: ellipsis;
  background: ${(props) => props.theme.colors.ma};
  border: 1px solid rgba(191, 84, 34, 0.5);
  border-radius: 4px;
  padding: 8px 45px 8px 16px;
  height: 56px;
  margin-bottom: 8px;
  color: black;

  font-family: Poppins;
  font-size: 1rem;
  font-size: clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.25)), 1.25rem);
`;

// export const ImgButton = styled.button`
//   border: none;
//   background-color: transparent;
// `;

export const StyledImage = styled.img`
  vertical-align: sub;
`;

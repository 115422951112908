export const IWI_AFFILIATIONS_PER_REGION = [
  {
    region: {
      id: '01',
      label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) Region Iwi',
    },
    iwis: [
      {
        id: '0101',
        label: 'Te Aupōuri',
      },
      {
        id: '0102',
        label: 'Ngāti Kahu',
      },
      {
        id: '0103',
        label: 'Ngāti Kurī',
      },
      {
        id: '0104',
        label: 'Ngāpuhi',
      },
      {
        id: '0105',
        label: 'Ngāpuhi ki Whaingaroa-Ngāti Kahu ki Whaingaroa',
      },
      {
        id: '0106',
        label: 'Te Rarawa',
      },
      {
        id: '0107',
        label: 'Ngāi Takoto',
      },
      {
        id: '0108',
        label: 'Ngāti Wai',
      },
      {
        id: '0109',
        label: 'Ngāti Whātua (not Ōrākei or Kaipara)',
      },
      {
        id: '0110',
        label: 'Te Kawerau ā Maki',
      },
      {
        id: '0111',
        label: 'Te Uri-o-Hau',
      },
      {
        id: '0112',
        label: 'Te Roroa',
      },
      {
        id: '0113',
        label: 'Ngāti Whātua o Kaipara',
      },
      {
        id: '0114',
        label: 'Ngāti Whātua o Ōrākei',
      },
      {
        id: '0115',
        label: 'Ngāi Tai ki Tāmaki  ',
      },
      {
        id: '0116',
        label: 'Ngāti Hine (Te Tai Tokerau)',
      },
      {
        id: '0117',
        label: 'Te Paatu',
      },
      {
        id: '0118',
        label: 'Ngāti Manuhiri',
      },
      {
        id: '0119',
        label: 'Ngāti Rehua',
      },
      {
        id: '0120',
        label: 'Ngāti Torehina ki Mata-ure ō Hau',
      },
    ],
  },
  {
    region: {
      id: '02',
      label: 'Hauraki (Coromandel) Region Iwi',
    },
    iwis: [
      {
        id: '0201',
        label: 'Ngāti Hako',
      },
      {
        id: '0202',
        label: 'Ngāti Hei',
      },
      {
        id: '0203',
        label: 'Ngāti Maru (Hauraki)',
      },
      {
        id: '0204',
        label: 'Ngāti Paoa',
      },
      {
        id: '0205',
        label: 'Patukirikiri',
      },
      {
        id: '0206',
        label: 'Ngāti Porou ki Harataunga ki Mataora',
      },
      {
        id: '0207',
        label: 'Ngāti Pūkenga ki Waiau',
      },
      {
        id: '0208',
        label: 'Ngāti Rāhiri Tumutumu',
      },
      {
        id: '0210',
        label: 'Ngāti Tamaterā',
      },
      {
        id: '0211',
        label: 'Ngāti Tara Tokanui',
      },
      {
        id: '0212',
        label: 'Ngāti Whanaunga',
      },
      {
        id: '0213',
        label: 'Ngāti Huarere',
      },
    ],
  },
  {
    region: {
      id: '03',
      label: 'Waikato/Te Rohe Pōtae (Waikato/King Country) Region Iwi',
    },
    iwis: [
      {
        id: '0301',
        label: 'Ngāti Haua (Waikato)',
      },
      {
        id: '0302',
        label: 'Ngāti Maniapoto',
      },
      {
        id: '0303',
        label: 'Raukawa (Waikato)',
      },
      {
        id: '0304',
        label: 'Waikato',
      },
      {
        id: '0305',
        label: 'Ngāti Te Ata',
      },
      {
        id: '0306',
        label: 'Ngāti Hīkairo ',
      },
      {
        id: '0307',
        label: 'Rereahu',
      },
      {
        id: '0308',
        label: 'Ngāti Tiipa',
      },
      {
        id: '0309',
        label: 'Ngāti Korokī Kahukura',
      },
      {
        id: '0310',
        label: 'Ngāti Tamaoho',
      },
      {
        id: '0311',
        label: 'Te Ākitai-Waiohua',
      },
      {
        id: '0312',
        label: 'Tainui Awhiro',
      },
      {
        id: '0313',
        label: 'Ngāti Hinerangi',
      },
    ],
  },
  {
    region: {
      id: '04',
      label: 'Te Arawa/Taupō (Rotorua/Taupō) Region Iwi',
    },
    iwis: [
      {
        id: '0401',
        label: 'Ngāti Pikiao (Te Arawa)',
      },
      {
        id: '0402',
        label: 'Ngāti Rangiteaorere (Te Arawa)',
      },
      {
        id: '0403',
        label: 'Ngāti Rangitihi (Te Arawa)',
      },
      {
        id: '0404',
        label: 'Ngāti Rangiwewehi (Te Arawa)',
      },
      {
        id: '0405',
        label: 'Tapuika (Te Arawa)',
      },
      {
        id: '0406',
        label: 'Ngāti Tarāwhai (Te Arawa)',
      },
      {
        id: '0407',
        label: 'Tūhourangi (Te Arawa)',
      },
      {
        id: '0408',
        label: 'Uenuku-Kōpako (Te Arawa)',
      },
      {
        id: '0409',
        label: 'Waitaha (Te Arawa)',
      },
      {
        id: '0410',
        label: 'Ngāti Whakaue (Te Arawa)',
      },
      {
        id: '0411',
        label: 'Ngāti Tūwharetoa (ki Taupō)',
      },
      {
        id: '0412',
        label: 'Ngāti Tahu-Ngāti Whaoa (Te Arawa)',
      },
      {
        id: '0413',
        label: 'Ngāti Mākino',
      },
      {
        id: '0414',
        label: 'Ngāti Kearoa / Ngāti Tuarā',
      },
      {
        id: '0415',
        label: 'Ngāti Rongomai (Te Arawa)',
      },
    ],
  },
  {
    region: {
      id: '05',
      label: 'Tauranga Moana/Mātaatua (Bay of Plenty) Region Iwi',
    },
    iwis: [
      {
        id: '0501',
        label: 'Ngāti Pūkenga',
      },
      {
        id: '0502',
        label: 'Ngāi Te Rangi ',
      },
      {
        id: '0503',
        label: 'Ngāti Ranginui',
      },
      {
        id: '0504',
        label: 'Ngāti Awa',
      },
      {
        id: '0505',
        label: 'Ngāti Manawa',
      },
      {
        id: '0506',
        label: 'Ngāi Tai (Tauranga Moana/Mātaatua)',
      },
      {
        id: '0507',
        label: 'Tūhoe',
      },
      {
        id: '0508',
        label: 'Whakatōhea',
      },
      {
        id: '0509',
        label: 'Te Whānau-ā-Apanui',
      },
      {
        id: '0510',
        label: 'Ngāti Whare',
      },
      {
        id: '0511',
        label: 'Ngā Pōtiki ā Tamapahore',
      },
      {
        id: '0512',
        label: 'Te Upokorehe',
      },
      {
        id: '0513',
        label: 'Ngāti Tūwharetoa ki Kawerau ',
      },
    ],
  },
  {
    region: {
      id: '06',
      label: 'Te Tai Rāwhiti (East Coast) Region Iwi',
    },
    iwis: [
      {
        id: '0601',
        label: 'Ngāti Porou',
      },
      {
        id: '0602',
        label: 'Te Aitanga-a-Māhaki',
      },
      {
        id: '0603',
        label: 'Rongowhakaata',
      },
      {
        id: '0604',
        label: 'Ngāi Tāmanuhiri',
      },
      {
        id: '0605',
        label: 'Te Aitanga ā Hauiti',
      },
    ],
  },
  {
    region: {
      id: '07',
      label: "Te Matau-a-Māui/Wairarapa (Hawke's Bay/Wairarapa) Region iwi",
    },
    iwis: [
      {
        id: '0701',
        label: 'Rongomaiwahine (Te Māhia)',
      },
      {
        id: '0702',
        label: 'Ngāti Kahungunu ki Te Wairoa',
      },
      {
        id: '0703',
        label: 'Ngāti Kahungunu ki Heretaunga',
      },
      {
        id: '0704',
        label: 'Ngāti Kahungunu ki Wairarapa',
      },
      {
        id: '0706',
        label: "Rangitāne (Te Matau-a-Māui/Hawke's Bay/Wairarapa)",
      },
      {
        id: '0707',
        label: 'Ngāti Kahungunu ki Te Whanganui-a-Orotu',
      },
      {
        id: '0708',
        label: 'Ngāti Kahungunu ki Tamatea',
      },
      {
        id: '0709',
        label: 'Ngāti Kahungunu ki Tamakinui a Rua',
      },
      {
        id: '0710',
        label: 'Ngāti Pāhauwera',
      },
      {
        id: '0711',
        label: 'Ngāti Rākaipaaka',
      },
      {
        id: '0712',
        label: 'Ngāti Hineuru',
      },
      {
        id: '0713',
        label: 'Maungaharuru Tangitū',
      },
      {
        id: '0714',
        label: 'Rangitāne o Tamaki nui ā Rua',
      },
      {
        id: '0715',
        label: 'Ngāti Ruapani ki Waikaremoana',
      },
      {
        id: '0716',
        label: 'Te Hika o Pāpāuma',
      },
      {
        id: '0717',
        label: 'Ngāti Hinemanu (Heretaunga)',
      },
    ],
  },
  {
    region: {
      id: '08',
      label: 'Taranaki Region Iwi',
    },
    iwis: [
      {
        id: '0801',
        label: 'Te Atiawa (Taranaki)',
      },
      {
        id: '0802',
        label: 'Ngāti Maru (Taranaki)',
      },
      {
        id: '0803',
        label: 'Ngāti Mutunga (Taranaki)',
      },
      {
        id: '0804',
        label: 'Ngā Rauru',
      },
      {
        id: '0805',
        label: 'Ngā Ruahine',
      },
      {
        id: '0806',
        label: 'Ngāti Ruanui',
      },
      {
        id: '0807',
        label: 'Ngāti Tama (Taranaki)',
      },
      {
        id: '0808',
        label: 'Taranaki',
      },
      {
        id: '0809',
        label: 'Tangāhoe',
      },
      {
        id: '0810',
        label: 'Pakakohi',
      },
    ],
  },
  {
    region: {
      id: '09',
      label: 'Whanganui/Rangitīkei (Wanganui/Rangitīkei) Region Iwi',
    },
    iwis: [
      {
        id: '0901',
        label: 'Ngāti Apa (Rangitīkei)',
      },
      {
        id: '0902',
        label: 'Te Ati Haunui-a-Pāpārangi',
      },
      {
        id: '0903',
        label: 'Ngāti Haua (Taumarunui)',
      },
      {
        id: '0904',
        label: 'Ngāti Hauiti (Rangitīkei)',
      },
      {
        id: '0905',
        label: 'Ngāti Whitikaupeka (Rangitīkei)',
      },
      {
        id: '0906',
        label: 'Ngāi Te Ohuake (Rangitīkei)',
      },
      {
        id: '0907',
        label: 'Ngāti Tamakōpiri (Rangitīkei)',
      },
      {
        id: '0908',
        label: 'Ngāti Rangi (Ruapehu, Whanganui)',
      },
      {
        id: '0909',
        label: 'Uenuku (Ruapehu, Waimarino)',
      },
      {
        id: '0910',
        label: 'Tamahaki (Ruapehu, Waimarino)',
      },
      {
        id: '0911',
        label: 'Tamakana (Ruapehu, Waimarino)',
      },
      {
        id: '0912',
        label: 'Ngāti Hinemanu (Rangitīkei)',
      },
    ],
  },
  {
    region: {
      id: '10',
      label: 'Manawatū/Horowhenua/Te Whanganui-a-Tara (Manawatū/Horowhenua/Wellington) Region Iwi',
    },
    iwis: [
      {
        id: '1001',
        label: 'Te Atiawa (Te Whanganui-a-Tara/Wellington)',
      },
      {
        id: '1002',
        label: 'Muaūpoko',
      },
      {
        id: '1003',
        label: 'Rangitāne (Manawatū)',
      },
      {
        id: '1004',
        label: 'Ngāti Raukawa (Horowhenua/Manawatū)',
      },
      {
        id: '1005',
        label: 'Ngāti Toarangatira (Te Whanganui-a-Tara/Wellington)',
      },
      {
        id: '1006',
        label: 'Te Atiawa ki Whakarongotai',
      },
      {
        id: '1007',
        label: 'Ngāti Tama ki Te Upoko o Te Ika (Te Whanganui-a-Tara/Wellington)',
      },
      {
        id: '1008',
        label: 'Ngāti Kauwhata',
      },
      {
        id: '1009',
        label: 'Ngāti Tukorehe ',
      },
    ],
  },
  {
    region: {
      id: '11',
      label: 'Te Waipounamu (South Island) Region Iwi',
    },
    iwis: [
      {
        id: '1101',
        label: 'Te Atiawa (Te Waipounamu/South Island)',
      },
      {
        id: '1102',
        label: 'Ngāti Koata',
      },
      {
        id: '1103',
        label: 'Ngāti Kuia',
      },
      {
        id: '1104',
        label: 'Kāti Māmoe',
      },
      {
        id: '1107',
        label: 'Rangitāne (Te Waipounamu/South Island)',
      },
      {
        id: '1108',
        label: 'Ngāti Rārua',
      },
      {
        id: '1109',
        label: 'Ngāi Tahu / Kāi Tahu',
      },
      {
        id: '1110',
        label: 'Ngāti Tama ki Te Tauihu',
      },
      {
        id: '1111',
        label: 'Ngāti Toarangatira (Te Waipounamu/South Island)',
      },
      {
        id: '1112',
        label: 'Waitaha (Te Waipounamu/South Island)',
      },
      {
        id: '1113',
        label: 'Ngāti Apa ki Te Rā Tō',
      },
    ],
  },
  {
    region: {
      id: '12',
      label: 'Rēkohu/Wharekauri (Chatham Islands) Region Imi/Iwi',
    },
    iwis: [
      {
        id: '1201',
        label: 'Moriori',
      },
      {
        id: '1202',
        label: 'Ngāti Mutunga (Wharekauri/Chatham Islands)',
      },
    ],
  },
];

export const ADDITIONAL_VALUES = [
  {
    id: '44',
    label: "Don't Know",
  },
  {
    id: '4444',
    label: "Don't Know",
  },
  {
    id: '55',
    label: 'Refused To Answer',
  },
  {
    id: '5555',
    label: 'Refused To Answer',
  },
  {
    id: '77',
    label: 'Response Unidentifiable',
  },
  {
    id: '7777',
    label: 'Response Unidentifiable',
  },
  {
    id: '88',
    label: 'Response Outside Scope',
  },
  {
    id: '8888',
    label: 'Response Outside Scope',
  },
  {
    id: '99',
    label: 'Not Stated',
  },
  {
    id: '9999',
    label: 'Not Stated',
  },
];

export type IdToLabel = {
  id: string;
  label: string;
};

export type IwiAffiliationsPerRegion = {
  region: IdToLabel;
  iwis: IdToLabel[];
};

export type IwiAffiliationData = {
  additionalData: IdToLabel[];
  iwiAffiliationsPerRegion: IwiAffiliationsPerRegion[];
};

export const iwiAffiliationData: IwiAffiliationData = {
  additionalData: ADDITIONAL_VALUES,
  iwiAffiliationsPerRegion: IWI_AFFILIATIONS_PER_REGION,
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

import { useIwiAffiliationFormContext } from '../../hooks/useIwiAffiliationFormContext';
import { FormStep } from '../FormStep';
import { FormStepActions } from '../FormStepActions';
import { FormSteps } from '../IwiAffiliationFormContext';

import { MaoriLanguage } from '@/components/Accessibility/MaoriLanguage';
import { recordEvents } from '@/components/Analytics/RecordEvents';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { ConfirmationDialog } from '@/components/ConfirmationDialog/ConfirmationDialog';
import { FlowFooter } from '@/components/FlowFooter/FlowFooter';
import { ErrorBanner } from '@/components/InputError/InputError';
import { DefaultLayout } from '@/components/Layouts/Layouts';
import { Radio } from '@/components/Radio/Radio';
import { Body2, Fineprint, H2 } from '@/components/Typography/Typography';
import {
  IMaoriDescentData,
  IsMaoriDescentResponses,
  KnowsIwiResponses,
  KNOWS_IWI_TO_LABEL,
  MAORI_DESCENT_TO_LABEL,
} from '@/features/iwiAffiliationForm';
// import { useAnalytics } from '@/tools/analytics';

export const Step1Descent = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
  } = useForm<IMaoriDescentData>();
  const previousStep = window.history.state.usr?.previousStep;
  const navigate = useNavigate();
  const watchIsMaoriDescent = watch('isMaoriDescent');
  const watchDoesKnowIwi = watch('doesKnowIwi');

  const { formData, goToNextStep, setFormData } = useIwiAffiliationFormContext();
  const stepData = formData[FormSteps.MAORI_DESCENT];

  const trackMaoriDescent = (data: IMaoriDescentData) => {
    if (data.isMaoriDescent === IsMaoriDescentResponses.NO) {
      recordEvents({
        action: 'maori-descent-response-submitted',
        label: `Is Māori descent: ${data.isMaoriDescent} | Does know Iwi: Not applicable`,
        data: 'iwi-affiliation-form-progress',
      });
      return;
    }

    recordEvents({
      action: 'maori-descent-response-submitted',
      label: `Is Māori descent: ${data.isMaoriDescent} | Does know Iwi: ${data.doesKnowIwi}`,
      data: 'iwi-affiliation-form-progress',
    });
  };

  const stepSubmitHandler = (data: IMaoriDescentData) => {
    const formDataUpdated = { ...formData, [FormSteps.MAORI_DESCENT]: data };

    trackMaoriDescent(data);
    setFormData(formDataUpdated);
    goToNextStep(formDataUpdated);
  };

  const doesntKnowIwi = (watchDoesKnowIwi || stepData.doesKnowIwi) === KnowsIwiResponses.NO;

  const isMaoriDescent =
    (watchIsMaoriDescent || stepData.isMaoriDescent) === IsMaoriDescentResponses.YES ||
    (watchIsMaoriDescent || stepData.isMaoriDescent) === IsMaoriDescentResponses.DONT_KNOW;

  const isMaoriDescentInputProps = register('isMaoriDescent', { required: true });
  const [showMaoriDescentWarning, setShowMaoriDescentWarning] = useState<boolean>(false);

  const onMaoriDescentChanged = (e: any) => {
    if (stepData.isMaoriDescent === IsMaoriDescentResponses.YES) {
      setShowMaoriDescentWarning(true);
    } else {
      isMaoriDescentInputProps.onChange(e);
    }
  };

  const handleChangeMaoriDescent = (isMaoriDescent: IsMaoriDescentResponses) => {
    setShowMaoriDescentWarning(false);

    setValue('isMaoriDescent', isMaoriDescent);
  };

  return (
    <Wrapper>
      <DefaultLayout>
        <ConfirmationDialog
          isOpen={showMaoriDescentWarning}
          id="maoriDescentWarning"
          dialogLabel="If you switch your answer to No, you will lose the iwi information have entered."
          onConfirm={() => handleChangeMaoriDescent(IsMaoriDescentResponses.NO)}
          onConfirmLabel="Yes, switch answer"
          onCancel={() => handleChangeMaoriDescent(IsMaoriDescentResponses.YES)}
          onCancelLabel="Cancel"
        />
        <FormStep
          onSubmit={handleSubmit(stepSubmitHandler)}
          aria-labelledby="label_formStep"
          goToPreviousStep={() =>
            previousStep === 'manage'
              ? navigate('/manage', { state: { redirectedFrom: 'step1form' } })
              : navigate('/who-is-this-iwi-affiliation-for', { state: { redirectedFrom: 'step1form' } })
          }
          minHeight={{ _: 'auto', lg: 'calc(100vh - 120px)' }}
          pt="8px"
          px={{ md: '32px ' }}
          mt={{ _: '65px', md: '144px' }}
        >
          <Box
            mt={{ _: '65px', md: '120px' }}
            flexGrow={1}
            flexShrink={0}
            display="flex"
            flexDirection="column"
            pb={36}
            maxWidth={850}
          >
            <MaoriDescentQuestionWrapper>
              <Box as="fieldset" width="100%">
                <Box display="flex" flexShrink={0} flexGrow={0} flexDirection="column" alignItems={{ md: 'center' }}>
                  <H2 color="white" as="legend">
                    Are they of <MaoriLanguage>Māori</MaoriLanguage> descent?
                  </H2>
                  <Body2 mt="16px" color="white">
                    That is, did they have a <MaoriLanguage>Māori</MaoriLanguage> birth parent, grandparent or
                    great-grandparent, etc.
                  </Body2>
                  <Box width="100%" maxWidth={{ md: 550 }}>
                    {errors.isMaoriDescent && (
                      <Box mt={12}>
                        <ErrorBanner>
                          Please indicate if they are of <MaoriLanguage>Māori</MaoriLanguage> descent.
                        </ErrorBanner>
                      </Box>
                    )}
                    <RadioGroup>
                      <Radio
                        data-cy="is-maori-descent-yes"
                        label={MAORI_DESCENT_TO_LABEL[IsMaoriDescentResponses.YES]}
                        id="is-maori-descent-yes"
                        value={IsMaoriDescentResponses.YES}
                        {...isMaoriDescentInputProps}
                        onBlur={isMaoriDescentInputProps.onBlur}
                        defaultChecked={stepData.isMaoriDescent === IsMaoriDescentResponses.YES}
                      />
                      <Radio
                        label={MAORI_DESCENT_TO_LABEL[IsMaoriDescentResponses.DONT_KNOW]}
                        id="is-maori-descent-dont-know"
                        value={IsMaoriDescentResponses.DONT_KNOW}
                        {...isMaoriDescentInputProps}
                        onBlur={isMaoriDescentInputProps.onBlur}
                        defaultChecked={stepData.isMaoriDescent === IsMaoriDescentResponses.DONT_KNOW}
                      />
                      <Radio
                        data-cy="is-maori-descent-no"
                        label={MAORI_DESCENT_TO_LABEL[IsMaoriDescentResponses.NO]}
                        id="is-maori-descent-no"
                        value={IsMaoriDescentResponses.NO}
                        {...isMaoriDescentInputProps}
                        onBlur={isMaoriDescentInputProps.onBlur}
                        onChange={onMaoriDescentChanged}
                        defaultChecked={stepData.isMaoriDescent === IsMaoriDescentResponses.NO}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Box>
            </MaoriDescentQuestionWrapper>

            {isMaoriDescent && (
              <MaoriDescentQuestionWrapper>
                <Box as="fieldset" width="100%">
                  <Box display="flex" flexShrink={0} flexDirection="column" alignItems={{ md: 'center' }}>
                    <H2 color="white" as="legend">
                      Do you know the name(s) of their <MaoriLanguage>iwi</MaoriLanguage> (tribe)?
                    </H2>
                    <Body2 color="white" mt={16} textAlign="center">
                      We (the Ministry of Health in partnership with the <MaoriLanguage>Tātai</MaoriLanguage>{' '}
                      <MaoriLanguage>Māori</MaoriLanguage>-led Governance Group) will collect the following information
                      so we know who they are with respect to the <MaoriLanguage>iwi</MaoriLanguage> they are part of.
                    </Body2>
                    <Box width="100%" maxWidth={{ md: 550 }}>
                      {errors.doesKnowIwi && (
                        <Box mt={12}>
                          <ErrorBanner>
                            Please indicate if you know their <MaoriLanguage>iwi</MaoriLanguage>.
                          </ErrorBanner>
                        </Box>
                      )}
                      <RadioGroup>
                        <Radio
                          data-cy="radio-does-know-iwi-yes"
                          label={KNOWS_IWI_TO_LABEL[KnowsIwiResponses.YES]}
                          id="radio-does-know-iwi-yes"
                          {...register('doesKnowIwi', { required: true })}
                          value={KnowsIwiResponses.YES}
                          defaultChecked={stepData.doesKnowIwi === KnowsIwiResponses.YES}
                        />
                        <Radio
                          label={KNOWS_IWI_TO_LABEL[KnowsIwiResponses.PARTIALLY]}
                          id="radio-does-know-iwi-know-some"
                          {...register('doesKnowIwi', { required: true })}
                          value={KnowsIwiResponses.PARTIALLY}
                          defaultChecked={stepData.doesKnowIwi === KnowsIwiResponses.PARTIALLY}
                        />
                        <Radio
                          label={KNOWS_IWI_TO_LABEL[KnowsIwiResponses.NO]}
                          id="radio-does-know-iwi-no"
                          {...register('doesKnowIwi', { required: true })}
                          value={KnowsIwiResponses.NO}
                          defaultChecked={stepData.doesKnowIwi === KnowsIwiResponses.NO}
                        >
                          {doesntKnowIwi && (
                            <>
                              <Fineprint mb="1rem" fontWeight={400} textAlign="left">
                                If you have forgotten the name of their <MaoriLanguage>iwi</MaoriLanguage>, we have
                                provided a map and a list that you can filter through the different regions with all{' '}
                                <MaoriLanguage>iwi</MaoriLanguage> from that <MaoriLanguage>rohe</MaoriLanguage> in the
                                next step.
                              </Fineprint>
                              <Fineprint fontWeight={400} textAlign="left">
                                If you still can’t remember their <MaoriLanguage>iwi</MaoriLanguage> after seeing the
                                list, you are able to talk to their <MaoriLanguage>whānau</MaoriLanguage> and come back
                                to the website to provide the information at another time.
                              </Fineprint>
                            </>
                          )}
                        </Radio>
                      </RadioGroup>
                    </Box>
                  </Box>
                </Box>
              </MaoriDescentQuestionWrapper>
            )}

            <FormStepActions>
              <Button label="Next" type="submit" data-cy="next-button" />
            </FormStepActions>
          </Box>
        </FormStep>
      </DefaultLayout>
      <FlowFooter />
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MaoriDescentQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  width: 100%;

  ${up('md')} {
    text-align: center;
    margin-bottom: 56px;

    :first-child {
      margin-bottom: 80px;
    }
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  label:last-of-type {
    margin-bottom: 0;
  }
`;

import { Dialog as ReachDialog } from '@reach/dialog';
import { PropsWithChildren } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { ButtonPrint } from '../Typography/Typography';

interface IConfirmationDialogProps {
  isOpen: boolean;
  id: string;
  dialogLabel: string;
  onConfirm: any;
  onConfirmLabel: string;
  onCancel?: any;
  onCancelLabel?: string;
}

export const ConfirmationDialog = (props: PropsWithChildren<IConfirmationDialogProps>) => {
  const { children, isOpen, id, dialogLabel, onConfirm, onCancel, onConfirmLabel, onCancelLabel } = props;

  return (
    <Dialog isOpen={isOpen} aria-labelledby={`dialog-label__${id}`}>
      <DialogWrapper>
        <ButtonPrint textAlign="center" id={`dialog-label__${id}`} as="h2">
          {dialogLabel}
        </ButtonPrint>
        <DialogContent>{children}</DialogContent>
        <DialogButtons>
          <Box my={16}>
            <Button type="button" onClick={onConfirm} label={onConfirmLabel} />
          </Box>
          {!!onCancel && !!onCancelLabel && (
            <Box>
              <Button variant="light" type="button" onClick={onCancel} label={onCancelLabel} />
            </Box>
          )}
        </DialogButtons>
      </DialogWrapper>
    </Dialog>
  );
};

const Dialog = styled(ReachDialog)`
  position: fixed;
  background: rgba(51, 51, 51, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;

  ${up('md')} {
    padding: 24px;
  }
`;

const DialogWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  flex-shrink: 1;
  overflow: auto;
  max-width: 270px;
`;

const DialogContent = styled.div``;

const DialogButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

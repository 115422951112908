import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const extractRealMeResponseStatus = (errorResponse: string) => {
  const searchParams = new URLSearchParams(errorResponse);

  const errorDescription = searchParams.get('error_description');

  if (errorDescription !== null) {
    // Gets the 'error_description' search param value, looks for "ExceptionDetails: [...]" and extracts the array part of it
    const exceptionDetailsString = errorDescription.match(/ExceptionDetails: (\[.+])/)?.[1];
    if (exceptionDetailsString) {
      // Convert the string to an array
      // e.g. ['urn:oasis:names:tc:SAML:2.0:status:Responder', 'urn:oasis:names:tc:SAML:2.0:status:UnknownPrincipal']
      const exceptionDetails: string[] = JSON.parse(exceptionDetailsString);

      // The response status is always the second item in the array
      const realMeResponseStatus = exceptionDetails[1];
      return realMeResponseStatus;
    }
  }

  return undefined;
};

const REALME_RESPONSE_STATUS_AUTHNFAILED = 'urn:oasis:names:tc:SAML:2.0:status:AuthnFailed';

export const useRealMeError = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      try {
        const realMeErrorCode = extractRealMeResponseStatus(location.hash);

        if (realMeErrorCode) {
          // Log an analytics event to track the error

          if (realMeErrorCode !== REALME_RESPONSE_STATUS_AUTHNFAILED) {
            // Navigate to error page for all errors except 'AuthnFailed'
            navigate('/realmeError', { state: { realMeErrorCode } });
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [location, navigate]);
};

import {
  Accordion as ReachAccordion,
  AccordionItem as ReachAccordionItem,
  AccordionButton as ReachAccordionButton,
  AccordionPanel as ReachAccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion';
import { useEffect, useRef, useState } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

import Icon from '../Icon/Icon';
import { Body2 } from '../Typography/Typography';

export const AccordionLabel = styled(Body2).attrs(() => ({
  as: 'h3',
  fontWeight: 700,
  mr: { _: 40, md: 46 },
}))``;

export const Accordion = styled(ReachAccordion)`
  margin-top: 24px;
`;

export const AccordionItem = styled(ReachAccordionItem)`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const AccordionButton = styled(ReachAccordionButton).attrs(() => {
  const { isExpanded } = useAccordionItemContext();

  return {
    $isExpanded: isExpanded,
  };
})`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  border: 0;
  background-color: transparent;
  text-align: left;
  padding: 12px;
  cursor: pointer;
  color: black;
  position: relative;

  // Remove the default styling which highlights the entire button
  :focus {
    outline: none;
  }

  // Style the accordion label when the accordion button is focussed
  :focus ${AccordionLabel} {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  // Remove the styles when the browser supports 'focus-visible'
  // i.e. hide the focus styles when the keyboard is not used
  :focus:not(:focus-visible) ${AccordionLabel} {
    outline: none;
  }

  // Finally, add the styles to the label when the keyboard is used
  :focus-visible ${AccordionLabel} {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  ${up('md')} {
    padding: 24px;
  }
`;

export const AccordionPanel = styled(ReachAccordionPanel)`
  padding: 0px 12px 12px 32px;

  background-image: url('/icons/ic-list-item.svg');
  background-position: 12px 6px;
  background-repeat: no-repeat;

  ${up('md')} {
    padding: 0px 24px 24px 44px;
    background-position: 24px 6px;
  }
`;

export const AccordionIcon = () => {
  const { isExpanded } = useAccordionItemContext();
  const buttonRef = useRef<HTMLSpanElement>(null);
  const [currentRotation, setCurrentRotation] = useState<number>(0);

  useEffect(() => {
    setCurrentRotation(currentRotation + 45);

    window.requestAnimationFrame(() => {
      if (buttonRef && buttonRef.current) {
        buttonRef.current.style.transform = `rotate(${currentRotation}deg)`;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <AnimatedAccordionButton ref={buttonRef}>
      <Icon name="ic-expand" size={{ _: 16, md: 20 }} />
    </AnimatedAccordionButton>
  );
};

export const AnimatedAccordionButton = styled.span`
  position: absolute;
  transform-origin: center center;
  top: 14px;
  right: 14px;
  width: 16px;
  height: 16px;
  transition: transform 0.3s ease-in-out;

  ${up('md')} {
    top: 26px;
    right: 26px;
    width: 20px;
    height: 20px;
  }
`;

import { up } from 'styled-breakpoints';
import styled from 'styled-components';
// import HrImage from '../../public/hr-small.svg';
import { flexbox, FlexboxProps } from 'styled-system';

import Icon from '../Icon/Icon';

interface IHorizontalSeparatorProps extends FlexboxProps {
  color?: string;
}

export const HorizontalSeparator = (props: IHorizontalSeparatorProps) => {
  const { color, ...otherProps } = props;

  return (
    <HrRoot aria-hidden color={color} {...otherProps}>
      <Icon height={{ _: '6px', md: '10px' }} width={{ _: 56, md: 100 }} name="ic-hr-small" />
    </HrRoot>
  );
};

const HrRoot = styled.div<IHorizontalSeparatorProps>`
  display: flex;
  height: 6px;
  line-height: 6px;
  margin: 16px 0px 32px 0px;

  svg {
    fill: ${(props) => props.color};
  }

  ${up('md')} {
    margin: 32px 0px 80px 0px;
    line-height: 10px;
  }

  ${flexbox};
`;

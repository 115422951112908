import { useLocation } from 'react-router';
import styled from 'styled-components';

import { Wrapper } from './AffiliationFor';

import { Box } from '@/components/Box/Box';
import { LinkButton } from '@/components/Button/Button';
import { Container } from '@/components/Container/Container';
import { GlobalHeader } from '@/components/GlobalHeader/GlobalHeader';
import { Head } from '@/components/Head/Head';
import { DefaultLayout } from '@/components/Layouts/Layouts';
import { Body2, H2 } from '@/components/Typography/Typography';

export const RealMeError = () => {
  const location = useLocation();
  const state = location.state as { realMeErrorCode: string } | null;
  return (
    <>
      <Head />
      <Wrapper>
        <GlobalHeader variantOverride="dark" />
        <DefaultLayout>
          <Container>
            <Box color="white" mt={{ _: '56px', md: '104px' }} maxWidth="567px">
              <H2 textAlign="left" mt={{ _: '16px', md: '32px' }}>
                Something went wrong with RealMe
              </H2>
              <Body2 mt={{ _: '16px', md: '32px' }}>
                There&apos;s a problem with your RealMe registration or log in. Please try again later.
              </Body2>
              <Body2 mt={{ _: '16px', md: '32px' }}>
                If the issue continues, email{' '}
                <StyledAnchorTag href="mailto:contact@tatai.maori.nz">contact@tatai.maori.nz</StyledAnchorTag>.
              </Body2>
              <Body2 mt={{ _: '16px', md: '32px' }}>
                <StyledStrongTag> Error:</StyledStrongTag> {state?.realMeErrorCode || null}
              </Body2>
              <LinkButton mt={{ _: '16px', md: '32px' }} label="Back to home" to="/" />
            </Box>
          </Container>
        </DefaultLayout>
      </Wrapper>
    </>
  );
};

export const StyledAnchorTag = styled.a`
  font-weight: 700 !important;
  margin-right: 3px;
`;

export const StyledStrongTag = styled.strong`
  font-weight: 700 !important;
`;

import { copy } from '../../content/Step7ConfirmationNonMaori';

import { AlternativeLayout } from '@/components/Layouts/Layouts';
import { Message, MessageType } from '@/components/Message/Message';

export const Step7ConfirmationNonMaori = () => (
  <AlternativeLayout>
    <Message type={MessageType.SUCCESS} title={copy.title} message={copy.message} />
  </AlternativeLayout>
);

export const calculateElementCenterCoordinates = (elements: HTMLElement[]) => {
  let boundingBoxX = 9999,
    boundingBoxY = 9999,
    boundingBoxWidth = 0,
    boundingBoxHeight = 0;

  // Loop through elements and extend bounding box
  elements.forEach((element: HTMLElement) => {
    const { width, height, x, y } = element.getBoundingClientRect();

    // If the current elements x is less than the current bounding box's x, set a new x
    if (x < boundingBoxX) {
      boundingBoxX = x;
    }

    // If the current elements y is less than the current bounding box's y, set a new y
    if (y < boundingBoxY) {
      boundingBoxY = y;
    }

    const xEnd = x + width;
    const yEnd = y + height;

    // If the current elements x position plus its width fall outside of the bounding box
    // set a new width for the bounding box
    if (xEnd > boundingBoxX + boundingBoxWidth) {
      boundingBoxWidth = xEnd - boundingBoxX;
    }

    // If the current elements y position plus its height fall outside of the bounding box
    // set a new width for the bounding box
    if (yEnd > boundingBoxY + boundingBoxHeight) {
      boundingBoxHeight = yEnd - boundingBoxY;
    }
  });

  return {
    x: boundingBoxX + boundingBoxWidth / 2,
    y: boundingBoxY + boundingBoxHeight / 2,
  };
};

import React from 'react';
import styled from 'styled-components';

import { MaoriLanguage } from '../components/Accessibility/MaoriLanguage';
import { Box } from '../components/Box/Box';
import { Head } from '../components/Head/Head';
import Icon from '../components/Icon/Icon';
import { LegalPageWrapper, LegalBody2 } from '../components/LegalPages/LegalPages';
import { H2 } from '../components/Typography/Typography';

const CopyrightPage = () => {
  return (
    <LegalPageWrapper>
      <Head />
      <H2 as="h1">
        <MaoriLanguage>Copyright</MaoriLanguage>
      </H2>

      <LegalBody2>
        <Box mb={32}>Unless indicated below, or on specific items or collections of content:</Box>
        <Box display="flex" mb={24}>
          <Icon color="white" mr={16} size={10} name="ic-list-item-white" />
          <p>any copyright in content on this service is owned by the New Zealand Crown; and</p>
        </Box>
        <Box mb={24} display="flex">
          <Icon color="white" mr={16} size={10} name="ic-list-item-white" />
          <p>
            the Ministry of Health (on behalf of the Crown) licenses you to copy, distribute and adapt that content in
            accordance with the Creative Commons Attribution 4.0 International Licence.
          </p>
        </Box>
        <Box mb={24}>
          Under the terms of the licence, you must attribute the content to the Crown and the Ministry of Health.{' '}
        </Box>
        <BoldA target="_blank" rel="noopener noreferrer" href="https://creativecommons.org/licenses/by/4.0/">
          Creative Commons Attribution 4.0 International Licence
        </BoldA>
      </LegalBody2>
      <H2 mb={16} as="h3">
        Exceptions
      </H2>
      <LegalBody2>
        Copyright in photographs on this service is not owned, or licensed to you, by the Crown. Unless otherwise stated
        in relation to specific photographs, you cannot copy, distribute or adapt any photograph without the agreement
        of the copyright owner.
      </LegalBody2>

      <LegalBody2>
        The New Zealand Government logo, Coat of Arms and other logos, emblems and trade marks on this service are
        protected by law. You must not copy or use the logo, emblem or trade mark without express permission and
        compliance with applicable laws.
      </LegalBody2>
    </LegalPageWrapper>
  );
};

export const BoldA = styled.a`
  font-weight: 700;
  text-decoration: underline;
`;

export default CopyrightPage;

import React, { useCallback, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { copy } from '../../content/Step3YourDetails';
import { useIwiAffiliationFormContext } from '../../hooks/useIwiAffiliationFormContext';
import {
  Gender,
  GENDER_TO_LABEL,
  IAddressPayload,
  IsProvidingAddressResponses,
  IS_PROVIDING_ADDRESS_TO_LABEL,
} from '../../types';
import { FormStep } from '../FormStep';
import { FormStepActions } from '../FormStepActions';
import { IIwiAffiliationFormData, FormSteps } from '../IwiAffiliationFormContext';

import { RadioGroup } from './Step1Descent';
import { formatAddress } from './Step5Summary';

import { MaoriLanguage } from '@/components/Accessibility/MaoriLanguage';
import { AutoComplete } from '@/components/AutoComplete/AutoComplete';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { ButtonLink } from '@/components/ButtonLink/ButtonLink';
import { DateOfBirthInput } from '@/components/DateOfBirthInput/DateOfBirthInput';
import { FlowFooter } from '@/components/FlowFooter/FlowFooter';
import { InputError } from '@/components/InputError/InputError';
import { DefaultLayout } from '@/components/Layouts/Layouts';
import { NHIInfoDialog } from '@/components/NHIInfoDialog/NHIInfoDialog';
import { Radio } from '@/components/Radio/Radio';
import { TextInput } from '@/components/TextInput/TextInput';
import { Body2, H2 } from '@/components/Typography/Typography';
import { useAuth, useUserInfo } from '@/features/auth';
import { isValidDate } from '@/tools/date';
import { NHI_ERROR_MESSAGE, NHI_VALIDATION_REGEX } from '@/tools/nhi';
import { ADDRESS_VALIDATION_REGEX, TEXT_VALIDATION_REGEX, POSTCODE_REGEX } from '@/tools/text';
type NhiLookupFormData = IIwiAffiliationFormData[FormSteps.YOUR_DETAILS];

export const Step3YourDetails = () => {
  const { data } = useUserInfo();
  const { isAuthenticated } = useAuth();
  const { formData, goToNextStep, setFormData, goToPreviousStep } = useIwiAffiliationFormContext();
  const stepData = formData[FormSteps.YOUR_DETAILS];
  const nhi = data ? data['urn:login:health:nz:claims:nhi'] : '';
  const authenticatedNHI = nhi ? nhi : '';
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    register,
    setValue,
  } = useForm<NhiLookupFormData>({
    defaultValues: {
      address: {
        addressLine1: stepData.address?.addressLine1 || '',
        addressLine2: stepData.address?.addressLine2 || '',
        suburb: stepData.address?.suburb || '',
        city: stepData.address?.city || '',
        postcode: stepData.address?.postcode || '',
      },
      isManualAddressEntry: stepData.isManualAddressEntry || false,
    },
  });
  const autocompleteRef = useRef<HTMLInputElement>(null);

  const watchGender = watch('gender', stepData.gender);
  const watchIsProvidingAddress = watch('isProvidingAddress', stepData.isProvidingAddress);
  const watchIsManualAddressEntry = watch('isManualAddressEntry', stepData.isManualAddressEntry || false);

  const onAutocompleteChange = useCallback(
    (autocompleteAddress: IAddressPayload) => {
      setValue('address.addressLine1', autocompleteAddress.addressLine1);
      setValue('address.addressLine2', autocompleteAddress.addressLine2);
      setValue('address.suburb', autocompleteAddress.suburb);
      setValue('address.city', autocompleteAddress.city);
      setValue('address.postcode', autocompleteAddress.postcode);
      setValue('address.esamId', autocompleteAddress.esamId);

      /* If all the required fields can't be set, go to manual entry mode for the valiation to kick in */
      if (!autocompleteAddress.esamId) {
        setValue('isManualAddressEntry', true);
      }
    },
    [setValue],
  );

  const toggleManualEntry = () => {
    let defaultAddressLine1Value = '';

    if (watchIsManualAddressEntry) {
      setValue('address.addressLine1', defaultAddressLine1Value);
      setValue('address.addressLine2', undefined);
      setValue('address.suburb', undefined);
      setValue('address.city', undefined);
      setValue('address.postcode', undefined);
    } else {
      // jwelfare: dirty dom access
      // if we're switching to manual entry mode take the value of the autocomplete
      // field and set it as the default value for street address line 1
      // we unfortunately can't use a ref or state value as the the ref is not
      // forwarded to the input by accessible-autocomplete and there's no onChange
      // for the input
      const autocompleteInput = document.getElementById('autocomplete');

      if (autocompleteInput && (autocompleteInput as HTMLInputElement).value) {
        defaultAddressLine1Value = (autocompleteInput as HTMLInputElement).value;
      }

      setValue('address.addressLine1', defaultAddressLine1Value);
    }

    setValue('address.esamId', undefined);
    setValue('isManualAddressEntry', !watchIsManualAddressEntry);
  };

  const stepSubmitHandler = (data: NhiLookupFormData) => {
    if (!isAuthenticated && data['gender'] === Gender.PREFER_NOT_TO_SAY) {
      data['gender'] = undefined;
    }
    const formDataUpdated = {
      ...formData,
      [FormSteps.YOUR_DETAILS]: {
        ...data,
        nhiNumber: data?.nhiNumber?.toUpperCase?.(),
      },
    };

    setFormData(formDataUpdated);
    goToNextStep(formDataUpdated);
  };

  return (
    <Wrapper>
      <DefaultLayout>
        <FormStep
          goToPreviousStep={() => goToPreviousStep(formData)}
          onSubmit={handleSubmit(stepSubmitHandler)}
          labelled-by="label_formStep"
          pt="8px"
          mt={{ _: '65px', md: '144px' }}
        >
          <Box maxWidth={689} width="100%" mt={{ _: 15, md: 55 }} pb={64}>
            <Box alignSelf="flex-start" my={16} mt={{ md: 0 }} color="white">
              <H2 id="label_formStep">Your Details</H2>
            </Box>
            <Box color="white">
              <Body2 mb={16}>
                We will match your information to your National Health Index number (NHI). We can then create
                information datasets to directly support <MaoriLanguage>Māori</MaoriLanguage> health and wellbeing. The
                datasets will not identify you.
              </Body2>
              <Body2 mb={32}>
                The <MaoriLanguage>Tātai</MaoriLanguage> <MaoriLanguage>Māori</MaoriLanguage>-led Governance Group will
                guide the use of information in partnership with the Ministry of Health.
              </Body2>
            </Box>
            <FormInputRow>
              {data && authenticatedNHI ? (
                // <Controller
                //   name="nhiNumber"
                //   control={control}
                //   defaultValue={authenticatedNHI || ''}
                //   rules={{
                //     pattern: {
                //       value: NHI_VALIDATION_REGEX,
                //       message: NHI_ERROR_MESSAGE,
                //     },
                //   }}
                //   render={({ field }) => (
                //     <TextInput
                //       id="input_nhiNumber"
                //       label="NHI number"
                //       placeholder="Enter your NHI number"
                //       error={errors.nhiNumber}
                //       maxLength={7}
                //       readOnly={true}
                //       disabled={true}
                //       {...field}
                //     />
                //   )}
                // />
                <>
                  <Body2 mb="8px" color="white">
                    NHI number
                  </Body2>
                  <Body2 mb="8px" color="white">
                    {authenticatedNHI}
                  </Body2>
                </>
              ) : (
                <Controller
                  name="nhiNumber"
                  control={control}
                  defaultValue={stepData.nhiNumber || ''}
                  rules={{
                    pattern: {
                      value: NHI_VALIDATION_REGEX,
                      message: NHI_ERROR_MESSAGE,
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      id="input_nhiNumber"
                      label="NHI number (if known)"
                      placeholder="Enter your NHI number (optional)"
                      error={errors.nhiNumber}
                      maxLength={7}
                      {...field}
                    />
                  )}
                />
              )}

              <Box marginLeft="auto" position="absolute" right="0" top="3px">
                <NHIInfoDialog />
              </Box>
            </FormInputRow>

            <FormInputRow>
              {data && data.given_name ? (
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={data.given_name || ''}
                  rules={{
                    required: 'Please provide your first name',
                    pattern: {
                      value: TEXT_VALIDATION_REGEX,
                      message: 'First name must not contain any special characters or start or finish with a space.',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      dataCy="first-name"
                      placeholder="Enter your first name"
                      autoComplete="given-name"
                      label="First name (if you have only one name, enter your name in both the first and last name fields)"
                      id="input_firstName"
                      error={errors.firstName}
                      aria-required="true"
                      {...field}
                    />
                  )}
                />
              ) : (
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={stepData.firstName || ''}
                  rules={{
                    required: 'Please provide your first name',
                    pattern: {
                      value: TEXT_VALIDATION_REGEX,
                      message: 'First name must not contain any special characters or start or finish with a space.',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      dataCy="first-name"
                      placeholder="Enter your first name"
                      autoComplete="given-name"
                      label="First name (if you have only one name, enter your name in both the first and last name fields)"
                      id="input_firstName"
                      error={errors.firstName}
                      aria-required="true"
                      {...field}
                    />
                  )}
                />
              )}
            </FormInputRow>
            {data && data.middle_name ? (
              <FormInputRow>
                <Controller
                  defaultValue={data.middle_name || ''}
                  name="middleName"
                  rules={{
                    pattern: {
                      value: TEXT_VALIDATION_REGEX,
                      message: 'Middle name must not contain any special characters or start or finish with a space',
                    },
                  }}
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      placeholder="Enter your middle names (optional)"
                      autoComplete="additional-name"
                      label="Middle name(s)"
                      id="input_middleName"
                      error={errors.middleName}
                      {...field}
                    />
                  )}
                />
              </FormInputRow>
            ) : (
              <FormInputRow>
                <Controller
                  defaultValue={stepData.middleName || ''}
                  name="middleName"
                  rules={{
                    pattern: {
                      value: TEXT_VALIDATION_REGEX,
                      message: 'Middle name must not contain any special characters or start or finish with a space',
                    },
                  }}
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      placeholder="Enter your middle names (optional)"
                      autoComplete="additional-name"
                      label="Middle name(s)"
                      id="input_middleName"
                      error={errors.middleName}
                      {...field}
                    />
                  )}
                />
              </FormInputRow>
            )}
            <FormInputRow>
              {data && data.given_name ? (
                <Controller
                  defaultValue={data.family_name || ''}
                  name="lastName"
                  rules={{
                    required: 'Please provide your last name',
                    pattern: {
                      value: TEXT_VALIDATION_REGEX,
                      message: 'Last name must not contain any special characters or start or finish with a space',
                    },
                  }}
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      dataCy="last-name"
                      placeholder="Enter your last name"
                      autoComplete="family-name"
                      label="Last name"
                      id="input_lastName"
                      error={errors.lastName}
                      aria-required="true"
                      {...field}
                    />
                  )}
                />
              ) : (
                <Controller
                  defaultValue={stepData.lastName || ''}
                  name="lastName"
                  rules={{
                    required: 'Please provide your last name',
                    pattern: {
                      value: TEXT_VALIDATION_REGEX,
                      message: 'Last name must not contain any special characters or start or finish with a space',
                    },
                  }}
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      dataCy="last-name"
                      placeholder="Enter your last name"
                      autoComplete="family-name"
                      label="Last name"
                      id="input_lastName"
                      error={errors.lastName}
                      aria-required="true"
                      {...field}
                    />
                  )}
                />
              )}
            </FormInputRow>

            <FormInputRow>
              <Controller
                control={control}
                name="dateOfBirth"
                rules={{
                  required: true,
                  validate: isValidDate,
                }}
                defaultValue={stepData.dateOfBirth || ''}
                render={({ field: { onChange, value, ref } }) => (
                  <DateOfBirthInput
                    ref={ref}
                    onChange={onChange}
                    id="input_dateOfBirth"
                    error={errors.dateOfBirth}
                    value={value}
                    aria-required="true"
                  />
                )}
              />
            </FormInputRow>

            <FormInputRow>
              {/* Negative margin-bottom to offset RadioGroup's margin-top of 24px */}
              <Body2 color="white" mb={-16}>
                Do you want to provide an address?
              </Body2>
              <RadioGroup>
                <Radio
                  data-cy="is-providing-address-yes"
                  label={IS_PROVIDING_ADDRESS_TO_LABEL[IsProvidingAddressResponses.YES]}
                  id="radio-is-providing-address-yes"
                  {...register('isProvidingAddress', { required: true })}
                  value={IsProvidingAddressResponses.YES}
                  defaultChecked={stepData.isProvidingAddress === IsProvidingAddressResponses.YES}
                />
                <Radio
                  data-cy="is-providing-address-no"
                  label={IS_PROVIDING_ADDRESS_TO_LABEL[IsProvidingAddressResponses.NO]}
                  id="radio-is-providing-address-know-some"
                  {...register('isProvidingAddress', { required: true })}
                  value={IsProvidingAddressResponses.NO}
                  defaultChecked={stepData.isProvidingAddress === IsProvidingAddressResponses.NO}
                />
              </RadioGroup>
              {errors.isProvidingAddress && (
                <Box mt={12}>
                  <InputError>Please indicate whether you want to provide an address.</InputError>
                </Box>
              )}
            </FormInputRow>

            {watchIsProvidingAddress === IsProvidingAddressResponses.YES && (
              <>
                {!watchIsManualAddressEntry && (
                  <FormInputRow>
                    <AutoComplete
                      ref={autocompleteRef}
                      onChange={onAutocompleteChange}
                      onManualEntry={toggleManualEntry}
                      defaultValue={
                        !watchIsManualAddressEntry &&
                        stepData.address?.addressLine1 &&
                        stepData.address?.city &&
                        stepData.address?.postcode
                          ? formatAddress(stepData.address)
                          : undefined
                      }
                    />
                  </FormInputRow>
                )}

                {watchIsManualAddressEntry && (
                  <>
                    {/* Address line 1 */}
                    <FormInputRow>
                      <Controller
                        name="address.addressLine1"
                        defaultValue={
                          stepData.address && stepData.address.addressLine1 ? stepData.address.addressLine1 : ''
                        }
                        control={control}
                        rules={{
                          pattern: {
                            value: ADDRESS_VALIDATION_REGEX,
                            message: 'Please enter a valid address.',
                          },
                          required: watchIsProvidingAddress && copy.errors.addressLine1,
                        }}
                        render={({ field }) => (
                          <TextInput
                            // @ts-expect-error fix for missing autoFocus attribute
                            // eslint-disable-next-line
                            autoFocus
                            dataCy="address-line-1"
                            placeholder="Address line 1"
                            id="input_address_line_1"
                            autoComplete="address-line1"
                            label="Address line 1"
                            error={errors.address?.addressLine1}
                            {...field}
                          />
                        )}
                      />
                      <ButtonLink marginTop="8px" onClick={toggleManualEntry}>
                        Lookup your address
                      </ButtonLink>
                    </FormInputRow>
                    {/* Address line 2 */}
                    <FormInputRow>
                      <Controller
                        name="address.addressLine2"
                        defaultValue={
                          stepData.address && stepData.address.addressLine2 ? stepData.address.addressLine2 : ''
                        }
                        control={control}
                        rules={{
                          pattern: {
                            value: ADDRESS_VALIDATION_REGEX,
                            message: 'Please enter a valid address.',
                          },
                        }}
                        render={({ field }) => (
                          <TextInput
                            placeholder="Address line 2"
                            id="input_address_line_2"
                            autoComplete="address-line2"
                            label="Address line 2 (optional)"
                            error={errors.address?.addressLine2}
                            {...field}
                          />
                        )}
                      />
                    </FormInputRow>

                    {/* Suburb */}
                    <FormInputRow>
                      <Controller
                        name="address.suburb"
                        defaultValue={stepData.address && stepData.address.suburb ? stepData.address.suburb : ''}
                        control={control}
                        rules={{
                          pattern: {
                            value: ADDRESS_VALIDATION_REGEX,
                            message: 'Please enter a valid suburb.',
                          },
                        }}
                        render={({ field }) => (
                          <TextInput
                            placeholder="Suburb"
                            id="input_suburb"
                            autoComplete="address-level3"
                            label="Suburb (optional)"
                            error={errors.address?.suburb}
                            {...field}
                          />
                        )}
                      />
                    </FormInputRow>

                    {/* City */}
                    <FormInputRow>
                      <Controller
                        name="address.city"
                        defaultValue={stepData.address && stepData.address.city ? stepData.address.city : ''}
                        control={control}
                        rules={{
                          pattern: {
                            value: ADDRESS_VALIDATION_REGEX,
                            message: 'Please enter a valid city.',
                          },
                          required: watchIsProvidingAddress && 'Please enter a city.',
                        }}
                        render={({ field }) => (
                          <TextInput
                            dataCy="city"
                            placeholder="City"
                            id="input_city"
                            autoComplete="address-level2"
                            label="City"
                            error={errors.address?.city}
                            {...field}
                          />
                        )}
                      />
                    </FormInputRow>

                    {/* Postcode */}
                    <FormInputRow>
                      <Controller
                        name="address.postcode"
                        defaultValue={stepData.address && stepData.address.postcode ? stepData.address.postcode : ''}
                        control={control}
                        rules={{
                          pattern: {
                            value: POSTCODE_REGEX,
                            message: 'Please enter a valid postcode comprised of 4 digits.',
                          },
                          required: watchIsProvidingAddress && copy.errors.postcode,
                        }}
                        render={({ field }) => (
                          <TextInput
                            dataCy="postcode"
                            placeholder="Postcode"
                            id="input_postcode"
                            autoComplete="postal-code"
                            label="Postcode"
                            error={errors.address?.postcode}
                            maxLength={4}
                            {...field}
                          />
                        )}
                      />
                    </FormInputRow>
                  </>
                )}
              </>
            )}

            <Box width="100%" role="radiogroup" aria-labelledby="label_gender" mb={{ _: 56, md: 56 }}>
              <div id="label_gender">
                <Body2 color="white" mb="8px">
                  Gender (optional)
                </Body2>
              </div>
              <Controller
                defaultValue={stepData.gender}
                name="gender"
                control={control}
                render={({ field }) => (
                  <>
                    <Radio
                      label={GENDER_TO_LABEL[Gender.MALE]}
                      id="input_gender_male"
                      defaultChecked={stepData.gender === Gender.MALE}
                      {...field}
                      value={Gender.MALE}
                    />
                    <Radio
                      label={GENDER_TO_LABEL[Gender.FEMALE]}
                      id="input_gender_female"
                      {...field}
                      value={Gender.FEMALE}
                      defaultChecked={stepData.gender === Gender.FEMALE}
                    />
                    <Radio
                      label={GENDER_TO_LABEL[Gender.ANOTHER_GENDER]}
                      id="input_gender_non-binary"
                      {...field}
                      value={Gender.ANOTHER_GENDER}
                      defaultChecked={stepData.gender === Gender.ANOTHER_GENDER}
                    />
                    <Radio
                      label={GENDER_TO_LABEL[Gender.PREFER_NOT_TO_SAY]}
                      id="input_gender_no_value"
                      {...field}
                      value={Gender.PREFER_NOT_TO_SAY}
                      defaultChecked={stepData.gender === Gender.PREFER_NOT_TO_SAY}
                    />
                  </>
                )}
              />
            </Box>

            {(watchGender === Gender.ANOTHER_GENDER || stepData.genderAsStated) && (
              <FormInputRow>
                <Controller
                  name="genderAsStated"
                  defaultValue={stepData.genderAsStated || ''}
                  control={control}
                  rules={{
                    required: 'Please state your gender',
                    pattern: {
                      value: TEXT_VALIDATION_REGEX,
                      message:
                        'Please state your gender. It must not contain any special characters or start or finish with a space',
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      placeholder="Enter your gender"
                      id="input_gender_as_stated"
                      autoComplete="off"
                      label="Please state"
                      error={errors.genderAsStated}
                      maxLength={30}
                      {...field}
                    />
                  )}
                />
              </FormInputRow>
            )}
            <FormStepActions>
              <Button label="Next" type="submit" data-cy="next-button" />
            </FormStepActions>
          </Box>
        </FormStep>
      </DefaultLayout>
      <FlowFooter />
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const FormInputRow = styled.div`
  width: 100%;
  margin-bottom: 24px;
  position: relative;
`;

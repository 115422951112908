/**
 * This function returns a text formatted to lowercase
 * with their vowels swapped from their accent version to their
 * natural one.
 * @param text Text to be sanitised
 * @returns Sanitised text (vowels without accents or diacritics)
 */
export const sanitiseText = (text: string) => text.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '');

/**
 * Fixes https://alphero.sifterapp.com/issues/28864.
 *
 * Prevents the user from entering just spaces, or starting or ending with spaces.
 */
const validCharacter = "\\u00c0-\\u01ffA-Za-zÀ-ÿ'-";
export const TEXT_VALIDATION_REGEX = new RegExp(`^[${validCharacter}](?:[ ${validCharacter}]*[${validCharacter}])?$`);
export const TEXT_VALIDATION_REGEX_NO_MIN_LENGTH = new RegExp(`^[ ${validCharacter}]*[${validCharacter}]$`);
export const ADDRESS_VALIDATION_REGEX = new RegExp(`^[0-9\\s,'-./${validCharacter}]*$`);
export const POSTCODE_REGEX = /^\d{4}$/; // 4 digits
export const EMOJI_REGEX = /\p{Extended_Pictographic}/u;

export const validateTextInput = (text?: string) => {
  if (!text) {
    return true;
  }

  return TEXT_VALIDATION_REGEX_NO_MIN_LENGTH.test(text) && !EMOJI_REGEX.test(text);
};

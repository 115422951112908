import styled from 'styled-components';
import {
  flexbox,
  space,
  layout,
  position,
  border,
  color,
  background,
  typography,
  shadow,
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  PositionProps,
  BorderProps,
  ColorProps,
  BackgroundProps,
  TypographyProps,
  ShadowProps,
} from 'styled-system';

export type IBoxProps =
  | FlexboxProps
  | SpaceProps
  | LayoutProps
  | PositionProps
  | BorderProps
  | ColorProps
  | BackgroundProps
  | TypographyProps
  | ShadowProps;

export const Box = styled.div<IBoxProps>`
  ${flexbox};
  ${space};
  ${layout};
  ${position};
  ${border};
  ${color};
  ${background};
  ${typography};
  ${shadow};
`;

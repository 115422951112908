// import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useRef, useState } from 'react';
// import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import closeButton from '../assets/icons/closeButton.svg';
import externalLinkLight from '../assets/icons/externalLinkLight.svg';
// import plusIcon from '../assets/icons/plusIcon.svg';

import { recordEvents } from '@/components/Analytics/RecordEvents';
import { Box } from '@/components/Box/Box';
import { Button, LinkButton } from '@/components/Button/Button';
import { ScrollToTopButton } from '@/components/Button/ScrollToTopButton';
import { Container } from '@/components/Container/Container';
import { FooterNavigation } from '@/components/FooterNavigation/FooterNavigation';
import { GlobalHeader } from '@/components/GlobalHeader/GlobalHeader';
import { Head } from '@/components/Head/Head';
import { useLandingContext } from '@/components/LandingContextProvider/useLandingContext';
import { AccountDesignedLayoutBackground } from '@/components/Layouts/Layouts';
// import { Loader } from '@/components/Loader/Loader';
import { ToggleSwitch } from '@/components/ToggleSwitch/ToggleSwitch';
import { Body2, H1, H2 } from '@/components/Typography/Typography';
// import { useUserInfo } from '@/features/auth';
// import { useAccessTokenPromise } from '@/features/auth';
import { Navigation } from '@/features/iwiAffiliationForm/components/Navigation';
import { useIwiSubmissions } from '@/features/manage';
import { MessageWrapper, ToastWrapper } from '@/styles/common';
// import { makeDeleteRequest } from '@/tools/ajax';

enum IS_ON_BEHALF_OF {
  'NO',
  'YES',
}
// interface ResponseObj {
//   message: string;
//   correlationId?: string;
//   errorObj?: object;
// }
// const API_PATH = process.env.REACT_APP_DELETE_IWI_API_PATH;
export const ManagePage = () => {
  // const getAccessToken = useAccessTokenPromise();

  const previousStep = window.history.state.usr?.previousStep;
  const someoneElseData = window.history.state.usr?.someoneElseData;
  // const mergedData = window.history.state.usr?.mergedData;
  let toggleValue = IS_ON_BEHALF_OF.NO;
  if (previousStep === 'behalfOf') {
    toggleValue = IS_ON_BEHALF_OF.YES;
  }
  const [isOnBehalf, setIsOnBehalf] = useState<IS_ON_BEHALF_OF>(toggleValue);
  // const queryClient = useQueryClient();
  const optOut = window.sessionStorage.getItem('optOut');
  // const showToast = window.sessionStorage.getItem('showToast');
  const [showToast, setShowToast] = useState<string>(window.sessionStorage.getItem('showToast'));
  const [showBehalfOfToast, setShowBehalfOfToast] = useState<string>(
    window.sessionStorage.getItem('showBehalfOfToast'),
  );
  const [showDeleteToast, setShowDeleteToast] = useState<string>(window.sessionStorage.getItem('showDeleteToast'));
  const [mergedData, setMergedData] = useState<string>(window.sessionStorage.getItem('mergedData'));

  const navigate = useNavigate();
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  const isDesktop = useBreakpoint(up('md'));
  const {
    sectionConfig: { textColor },
  } = useLandingContext();
  // const { data } = useUserInfo();

  // const [isLoading, setIsLoading] = useState<boolean>(true);

  const { response, isSuccess, status } = useIwiSubmissions();

  let myself = response?.myself;
  let behalfOf = response?.behalfOf;
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowToast('false');
      setShowBehalfOfToast('false');
      setShowDeleteToast('false');
      window.sessionStorage.removeItem('showToast');
      window.sessionStorage.removeItem('showBehalfOfToast');
      window.sessionStorage.removeItem('showDeleteToast');
    }, 7000);
    // queryClient.invalidateQueries({ queryKey: 'getSubmissions' });
    return () => {
      clearTimeout(timeId);
    };
  }, []);

  let selectedValue = isSuccess ? behalfOf[0] : null;
  if (someoneElseData !== undefined) {
    selectedValue = someoneElseData;
  }
  // const initialDataForBehalfOf = selectedValue;
  const [dataToDisplayForBehalfOf, setDataToDisplayForBehalfOf] = useState(selectedValue);

  useEffect(() => {
    if (behalfOf && behalfOf[0]) {
      if (selectedValue) {
        const foundObject = behalfOf.find((item) => item.id === selectedValue.id);

        // Return the found object or null if not found
        const result = foundObject ? foundObject : selectedValue;

        setDataToDisplayForBehalfOf(result);
      } else if (behalfOf) {
        setDataToDisplayForBehalfOf(behalfOf[0]);
      }
    }
  }, [behalfOf, selectedValue]);
  if (!isSuccess) {
    return null;
  }
  if (status === 'success') {
    // setIsLoading(false);
  }

  // const { mySubmissions, onBehalfOfSubmissions } = data;

  // console.log('Response from Get Iwi : ', response);

  // if (response && response?.message) {
  //   console.log(response.message);
  // }
  const addDetails = () => {
    recordEvents({ submission_myself: true });
    navigate('/form', { state: { previousStep: 'manage' } });
  };

  const editDetails = () => {
    navigate('/edit-details', { state: { data: myself } });
  };
  const editIwi = () => {
    navigate('/edit-iwi-map', { state: { data: myself } });
  };
  // let dataToDisplayForBehalfOf = behalfOf[0];
  const switchPersonDetails = (event) => {
    behalfOf &&
      behalfOf.forEach((data: any) => {
        if (data.id === event.currentTarget.value) {
          setDataToDisplayForBehalfOf(data);
        }
      });
  };
  const editDetailsBehalfOf = (dataToDisplay) => {
    navigate('/edit-details-behalf-of', { state: { data: dataToDisplay } });
  };
  const editIwiBehalfOf = (dataToDisplay) => {
    navigate('/edit-iwi-map-behalf-of', { state: { data: dataToDisplay } });
    setDataToDisplayForBehalfOf(dataToDisplay);
  };
  const removeFromMyView = async (data) => {
    // const repsoneFromDeleteApi: ResponseObj = await makeDeleteRequest(API_PATH + '/' + id, getAccessToken);
    // console.log('response from delete api : ', repsoneFromDeleteApi);
    // if (repsoneFromDeleteApi.message === 'Deleted Successfully') {
    //   window.sessionStorage.setItem('showDeleteToast', 'true');
    //   const updatedArray = behalfOf.filter((data) => data.id !== id);
    //   setDataToDisplayForBehalfOf(updatedArray);
    //   console.log('updatedArray value is : ', updatedArray);
    // }
    navigate('/confirm-delete-account-behalf-of', { state: { data: data, redirectedFrom: 'removefrommyview' } });
  };

  const addAnotherPerson = () => {
    recordEvents({ submission_on_behalf_of: true });
    navigate('/behalf-of-form', { state: { previousStep: 'manage' } });
  };

  return (
    <>
      <Head />
      <Wrapper>
        <AccountDesignedLayoutBackground>
          <GlobalHeader variantOverride="dark" />
          <Container>
            <Navigation
              goToPreviousStep={() => navigate('/', { state: { previousStep: 'manage', optOut: optOut } })}
              labelled-by="linked-account"
              title="Home"
              padding="0px"
              marginTop={{ _: '115px' }}
            >
              {showToast === 'true' && (
                <ToastWrapper>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Body2> Your account details have been updated.</Body2>
                    <ImgButton
                      type="button"
                      onClick={() => {
                        setShowToast('false');
                        window.sessionStorage.removeItem('showToast');
                      }}
                    >
                      <img src={closeButton} alt="Plus Icon" />
                    </ImgButton>
                  </Box>
                </ToastWrapper>
              )}
              {showBehalfOfToast === 'true' && (
                <ToastWrapper>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Body2>
                      Account details for <StyledStrongTag>{dataToDisplayForBehalfOf?.preferredName}</StyledStrongTag>{' '}
                      have been updated.
                    </Body2>
                    <ImgButton
                      type="button"
                      onClick={() => {
                        setShowBehalfOfToast('false');
                        window.sessionStorage.removeItem('showBehalfOfToast');
                      }}
                    >
                      <img src={closeButton} alt="Plus Icon" />
                    </ImgButton>
                  </Box>
                </ToastWrapper>
              )}
              {showDeleteToast === 'true' && (
                <ToastWrapper>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Body2> Iwi affiliations and information has been deleted.</Body2>
                    <ImgButton
                      type="button"
                      onClick={() => {
                        setShowDeleteToast('false');
                        window.sessionStorage.removeItem('showDeleteToast');
                      }}
                    >
                      <img src={closeButton} alt="Plus Icon" />
                    </ImgButton>
                  </Box>
                </ToastWrapper>
              )}

              {mergedData === 'true' ? (
                <ToastWrapper>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Body2>
                      A previous submission using your NHI has been connected to your account. Please review the account
                      details and affiliations in this submission to make sure that they are accurate.
                      <br />
                      If you believe that this has been done in error, please send any relevant details to our support
                      team at{' '}
                      <StyledAnchorTag href="mailto:contact@tatai.maori.nz">contact@tatai.maori.nz</StyledAnchorTag>,
                      and we will investigate.
                    </Body2>
                    <ImgButton
                      type="button"
                      onClick={() => {
                        setMergedData('false');
                        window.sessionStorage.removeItem('mergedData');
                      }}
                    >
                      <img src={closeButton} alt="Plus Icon" />
                    </ImgButton>
                  </Box>
                </ToastWrapper>
              ) : null}
              <H1 color={textColor} mt={{ _: 24, md: 75 }}>
                Iwi affiliations
              </H1>
              {behalfOf.length > 0 ? (
                <ToggleSwitchWrapper>
                  <ToggleSwitch
                    id="target-user"
                    label="View submissions for"
                    hideLabel
                    onChange={(value) => setIsOnBehalf(value as IS_ON_BEHALF_OF)}
                    currentValue={isOnBehalf}
                    options={[
                      { label: 'Myself', value: IS_ON_BEHALF_OF.NO, id: 'mySubmissions' },
                      { label: 'Others', value: IS_ON_BEHALF_OF.YES, id: 'behalfOfSubmissions' },
                    ]}
                  />
                </ToggleSwitchWrapper>
              ) : null}
              {isOnBehalf === IS_ON_BEHALF_OF.NO && (
                <Box
                  width="100%"
                  maxWidth={{ md: '1226px' }}
                  pt={32}
                  pb={{ _: 120, md: 200 }}
                  display="flex"
                  flexDirection={{ _: 'column-reverse', xl: 'row' }}
                  justifyContent={{ xl: 'space-between' }}
                  flexGrow={0}
                  color={textColor}
                >
                  <Box width="100%" mt={{ _: '32px', xl: '0px' }} maxWidth={{ xl: 'calc(50% - 16px)' }}>
                    <H2>Account Details</H2>

                    {response?.message || Object.keys(myself).length === 0 ? (
                      <Box>
                        <Body2 mt="16px">No account details.</Body2>

                        <Button
                          type="button"
                          label="Add details"
                          onClick={addDetails}
                          data-cy="add-details-button"
                          maxWidth="154px"
                          mt="16px"
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Preferred name
                        </Body2>

                        <Body2 mb="8px">{myself?.preferredName ? myself.preferredName : '-'}</Body2>

                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Gender
                        </Body2>

                        {myself.gender ? (
                          <Body2Transform mb="8px">
                            {myself?.gender === 'Not Provided' ? '-' : myself.gender.replaceAll('_', ' ').toLowerCase()}
                          </Body2Transform>
                        ) : (
                          <Body2>-</Body2>
                        )}

                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Email
                        </Body2>

                        <Body2 mb="8px">{myself?.email ? myself.email : '-'}</Body2>

                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Contact number
                        </Body2>

                        <Body2 mb="8px">{myself?.phone ? myself.phone : '-'}</Body2>

                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Māori descent
                        </Body2>

                        {myself.isMaoriDescent ? (
                          <Body2Transform mb="8px">
                            {myself.isMaoriDescent === 'DONT_KNOW'
                              ? "Don't know"
                              : myself.isMaoriDescent.replace('_', ' ').toLowerCase()}
                          </Body2Transform>
                        ) : (
                          <Body2>-</Body2>
                        )}

                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Iwi name(s) knowns
                        </Body2>

                        {myself.doesKnowIwi ? (
                          <Body2Transform mb="8px">
                            {myself.doesKnowIwi ? myself.doesKnowIwi.toLowerCase() : '-'}
                          </Body2Transform>
                        ) : (
                          <Body2>-</Body2>
                        )}

                        <Button
                          type="button"
                          label="Edit details"
                          onClick={editDetails}
                          data-cy="edit-details-button"
                          maxWidth="154px"
                          mt="16px"
                        />
                      </Box>
                    )}

                    <H2 mt="32px">Other persons</H2>
                    <Body2 mt={{ _: '16px', md: '24px' }} mb="8px" fontWeight={700}>
                      Add iwi affiliations for another person
                    </Body2>
                    <Body2 mb="16px">
                      You can complete an iwi affiliation for another person if you are their legal guardian or if you
                      have their permission.
                    </Body2>
                    {/* <Link to="/behalf-of-form">
                      <Body2 mt="8px" mb="4px">
                        <Box display="flex" alignContent="flex-end">
                          <Box display="flex" alignContent="flex-end" mr="4px">
                            <img src={plusIcon} alt="Plus Icon" />
                          </Box>
                          Add iwi affiliations for another person
                        </Box>
                      </Body2>
                    </Link> */}
                    {/* <LinkButton to="/behalf-of-form" label="Add another person" /> */}
                    <Button
                      type="button"
                      onClick={addAnotherPerson}
                      label="Add another person"
                      data-cy="add-another-person-button"
                    />

                    {/* <p>{stringifiedResponse}</p> */}
                    {/* {responseObj.map((iwi) =>
                  iwi.affiliatedIwi.map((affiliatedIwi) => (
                    <Body2 mb="8px" key={affiliatedIwi.id}>
                      {affiliatedIwi.id}
                    </Body2>
                  )),
                )} */}
                  </Box>
                  {optOut === 'true' || Object.keys(myself).length === 0 ? null : (
                    <Box width="100%" maxWidth={{ xl: 'calc(50% - 16px)' }}>
                      <H2>Iwi affiliations</H2>
                      <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                        Iwi
                      </Body2>
                      {myself && myself.affiliatedIwi && myself.affiliatedIwi.length > 0 ? (
                        myself.affiliatedIwi.map((iwi) => {
                          return (
                            <>
                              <Body2>{iwi.label}</Body2>
                              {iwi.region?.label ? (
                                <Body2 mb="16px">{iwi?.region?.label}</Body2>
                              ) : (
                                <Body2 mb="16px">
                                  (This choice is not included in the{' '}
                                  <StyledAnchorTag
                                    href="https://aria.stats.govt.nz/aria/#ClassificationView:uri=http://stats.govt.nz/cms/ClassificationVersion/uH9AkXTnTlq40DHG"
                                    target="_blank"
                                  >
                                    Stats NZ classification <StyledImage src={externalLinkLight} alt="External Link" />
                                  </StyledAnchorTag>
                                  that Tātai uses.)
                                </Body2>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <>
                          <Body2 mb="16px">No iwi affiliations.</Body2>

                          <Button
                            type="button"
                            label="Add iwi"
                            onClick={editIwi}
                            data-cy="edit-iwi-button"
                            maxWidth="115px"
                          />
                        </>
                      )}

                      {myself && myself.affiliatedIwi && myself.affiliatedIwi.length > 0 ? (
                        <Button
                          type="button"
                          label="Edit iwi"
                          onClick={editIwi}
                          data-cy="edit-iwi-button"
                          maxWidth="115px"
                        />
                      ) : null}
                    </Box>
                  )}
                </Box>
              )}

              {behalfOf.length > 0 && isOnBehalf === IS_ON_BEHALF_OF.YES && (
                <Box
                  width="100%"
                  maxWidth={{ md: '1226px' }}
                  pt={32}
                  pb={{ _: 120, md: 200 }}
                  display="flex"
                  flexDirection={{ _: 'column-reverse', xl: 'row' }}
                  justifyContent={{ xl: 'space-between' }}
                  flexShrink={0}
                  flexGrow={0}
                  color={textColor}
                >
                  <Box width="100%" mt={{ _: '32px', xl: '0px' }} maxWidth={{ xl: 'calc(50% - 16px)' }}>
                    <H2>Profile</H2>
                    <Body2 mt={{ _: '16px' }} mb="8px" fontWeight={400}>
                      Switch accounts
                    </Body2>
                    <Box width="100%" mt={{ _: '32px', xl: '0px' }} mb={{ _: '16px', xl: '32px' }}>
                      <SelectWrapper>
                        <Select
                          style={{ width: '100%' }}
                          onChange={switchPersonDetails}
                          value={dataToDisplayForBehalfOf?.id}
                        >
                          {/* <option value="personone">Person One</option>
                        <option value="persontwo">Person Two</option>
                        <option value="personthree">Person Three</option> */}
                          {behalfOf?.map((data: any) => (
                            <option key={data.id} value={data.id}>
                              {data.preferredName}
                            </option>
                          ))}
                        </Select>
                      </SelectWrapper>
                    </Box>
                    <H2>Account Details</H2>
                    {dataToDisplayForBehalfOf.nhiMatchingStatus === 'PENDING' ||
                    dataToDisplayForBehalfOf.nhiMatchingStatus === 'UNMATCHED' ? (
                      <MessageWrapper>
                        <Body2 fontWeight={700}> Status</Body2>
                        <Body2>
                          This iwi affiliation is being processed. You cannot make changes until this has completed.
                        </Body2>
                      </MessageWrapper>
                    ) : null}
                    {dataToDisplayForBehalfOf.locked ? (
                      <MessageWrapper>
                        <Body2 fontWeight={700}> Status</Body2>
                        <Body2>
                          This person has taken ownership of their iwi affiliations in Tātai. You no longer have edit
                          access. The information shown is what you submitted.
                        </Body2>
                      </MessageWrapper>
                    ) : null}
                    {dataToDisplayForBehalfOf ? (
                      <Box>
                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Preferred name
                        </Body2>

                        <Body2 mb="8px">
                          {dataToDisplayForBehalfOf.preferredName ? dataToDisplayForBehalfOf.preferredName : '-'}
                        </Body2>

                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Gender
                        </Body2>

                        {dataToDisplayForBehalfOf.gender ? (
                          <Body2Transform mb="8px">
                            {dataToDisplayForBehalfOf?.gender === 'Not Provided'
                              ? '-'
                              : dataToDisplayForBehalfOf.gender.replaceAll('_', ' ').toLowerCase()}
                          </Body2Transform>
                        ) : (
                          <Body2>-</Body2>
                        )}

                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Email
                        </Body2>

                        {dataToDisplayForBehalfOf.email ? (
                          <Body2 mb="8px">
                            {dataToDisplayForBehalfOf.email ? dataToDisplayForBehalfOf.email : '-'}
                          </Body2>
                        ) : (
                          <Body2>-</Body2>
                        )}

                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Contact number
                        </Body2>

                        <Body2 mb="8px">{dataToDisplayForBehalfOf.phone ? dataToDisplayForBehalfOf.phone : '-'}</Body2>

                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Māori descent
                        </Body2>

                        {dataToDisplayForBehalfOf.isMaoriDescent ? (
                          <Body2Transform mb="8px">
                            {dataToDisplayForBehalfOf.isMaoriDescent === 'DONT_KNOW'
                              ? "Don't know"
                              : dataToDisplayForBehalfOf.isMaoriDescent.replace('_', ' ').toLowerCase()}
                          </Body2Transform>
                        ) : (
                          <Body2>-</Body2>
                        )}

                        <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                          Iwi name(s) knowns
                        </Body2>

                        {dataToDisplayForBehalfOf.doesKnowIwi ? (
                          <Body2Transform mb="8px">
                            {dataToDisplayForBehalfOf.doesKnowIwi
                              ? dataToDisplayForBehalfOf.doesKnowIwi.toLowerCase()
                              : '-'}
                          </Body2Transform>
                        ) : (
                          <Body2>-</Body2>
                        )}
                        {/* <LinkButton
                          data-cy="edit-details-button"
                          maxWidth="154px"
                          mt="16px"
                          label="Edit details"
                          to="/edit-details-behalf-of"
                        /> */}
                        {dataToDisplayForBehalfOf.locked ||
                        dataToDisplayForBehalfOf.nhiMatchingStatus === 'PENDING' ||
                        dataToDisplayForBehalfOf.nhiMatchingStatus === 'UNMATCHED' ? null : (
                          <Button
                            type="button"
                            label="Edit details"
                            onClick={() => editDetailsBehalfOf(dataToDisplayForBehalfOf)}
                            data-cy="edit-details-button"
                            maxWidth="154px"
                            mt="16px"
                          />
                        )}

                        {dataToDisplayForBehalfOf.locked && (
                          <Box>
                            <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                              Remove from your view
                            </Body2>
                            <Body2 mb="8px">
                              Remove this submission from your view of iwi submissions made on behalf of others.
                            </Body2>
                            <Button
                              type="button"
                              label="Remove from my view"
                              onClick={() => removeFromMyView(dataToDisplayForBehalfOf)}
                              data-cy="edit-details-button"
                              maxWidth="273px"
                              mt="16px"
                            />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box>
                        <Body2 mt="16px">No account details.</Body2>
                        <LinkButton
                          label="Add details"
                          to="/form"
                          data-cy="add-details-button"
                          maxWidth="154px"
                          mt="16px"
                        />
                      </Box>
                    )}
                  </Box>
                  <Box width="100%" maxWidth={{ xl: 'calc(50% - 16px)' }}>
                    <H2>Iwi affiliations</H2>
                    <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                      Iwi
                    </Body2>
                    {dataToDisplayForBehalfOf &&
                    dataToDisplayForBehalfOf.affiliatedIwi &&
                    dataToDisplayForBehalfOf.affiliatedIwi.length > 0 ? (
                      dataToDisplayForBehalfOf.affiliatedIwi.map((iwi) => {
                        return (
                          <>
                            <Body2>{iwi.label}</Body2>
                            {iwi.region?.label ? (
                              <Body2 mb="16px">{iwi?.region?.label}</Body2>
                            ) : (
                              <Body2 mb="16px">
                                (This choice is not included in the{' '}
                                <StyledAnchorTag
                                  href="https://aria.stats.govt.nz/aria/#ClassificationView:uri=http://stats.govt.nz/cms/ClassificationVersion/uH9AkXTnTlq40DHG"
                                  target="_blank"
                                >
                                  Stats NZ classification <StyledImage src={externalLinkLight} alt="External Link" />
                                </StyledAnchorTag>
                                that Tātai uses.)
                              </Body2>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <>
                        <Body2 mb="16px">No iwi affiliations.</Body2>
                        <Button
                          type="button"
                          label="Add iwi"
                          onClick={() => editIwiBehalfOf(dataToDisplayForBehalfOf)}
                          data-cy="edit-iwi-button"
                          maxWidth="115px"
                        />
                      </>
                    )}

                    {dataToDisplayForBehalfOf &&
                    dataToDisplayForBehalfOf.affiliatedIwi &&
                    dataToDisplayForBehalfOf.affiliatedIwi.length > 0 &&
                    !dataToDisplayForBehalfOf.locked ? (
                      <Button
                        type="button"
                        label="Edit iwi"
                        onClick={() => editIwiBehalfOf(dataToDisplayForBehalfOf)}
                        data-cy="edit-iwi-button"
                        maxWidth="115px"
                      />
                    ) : null}
                  </Box>
                </Box>
              )}
            </Navigation>
          </Container>
          <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }} mt="auto">
            <FooterNavigation />

            {!isDesktop && (
              <Box mt={32} display="flex" justifyContent={{ _: 'center' }} flexShrink={0} flexGrow={0}>
                <ScrollToTopButton focusRef={mainTitleRef} />
              </Box>
            )}
          </Box>
        </AccountDesignedLayoutBackground>
      </Wrapper>
    </>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const ImgButton = styled.button`
  border: none;
  background-color: transparent;
`;

export const ToggleSwitchWrapper = styled.div`
  padding-top: 20px;
  width: 100%;
  max-width: 600px;
  ${up('lg')} {
    padding-top: 50px;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  :after {
    pointer-events: none;
    content: '';
    display: inline-block;
    background-image: url('/icons/select-icons.svg');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    background-size: 16px;
    position: absolute;
    right: 16px;
    transform: translateY(-25%);
  }
`;

const Select = styled.select`
  appearance: none;
  text-overflow: ellipsis;
  background: ${(props) => props.theme.colors.ma};
  border: 1px solid rgba(191, 84, 34, 0.5);
  border-radius: 4px;
  padding: 8px 45px 8px 16px;
  height: 56px;
  margin-bottom: 8px;
  color: black;

  font-family: Poppins;
  font-size: 1rem;
  font-size: clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.25)), 1.25rem);
`;

const Body2Transform = styled(Body2)`
  text-transform: capitalize;
`;

export const StyledImage = styled.img`
  vertical-align: sub;
  font-weight: 700 !important;
`;
export const StyledAnchorTag = styled.a`
  font-weight: 700 !important;
  margin-right: 3px;
`;
export const StyledStrongTag = styled.strong`
  font-weight: 700 !important;
`;

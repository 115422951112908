/* A button.. that looks like a link */

import React, { forwardRef, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { space, SpaceProps, layout, LayoutProps } from 'styled-system';

import { Body2 } from '../Typography/Typography';

type ButtonVariant = 'dark' | 'light';

interface IButtonLinkProps extends React.HTMLAttributes<HTMLButtonElement>, SpaceProps, LayoutProps {
  variant?: ButtonVariant;
}

export const ButtonLink2 = forwardRef<HTMLButtonElement, PropsWithChildren<IButtonLinkProps>>(
  ({ children, variant, ...props }, ref) => {
    return (
      <ButtonRoot variant={variant} type="button" ref={ref} {...props}>
        <Body2 as="span">{children}</Body2>
      </ButtonRoot>
    );
  },
);

ButtonLink2.displayName = 'ButtonLink2';

const ButtonRoot = styled.button<LayoutProps & SpaceProps & { variant: ButtonVariant }>`
  appearance: none;
  border: 0;
  background-color: transparent;
  display: inline-flex;
  width: auto;
  padding: 0;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  ${space}
  ${layout}

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${(props) => props.theme.colors.pango};
    `};

  ${({ variant }) =>
    variant === 'dark' &&
    css`
      color: ${(props) => props.theme.colors.white};
    `};
`;

import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { UserInfo } from '../types';

import { useAccessTokenPromise, useAuth } from './auth';

export const useUserInfo = () => {
  const { isAuthenticated } = useAuth();
  const getAccessToken = useAccessTokenPromise();

  return useQuery({
    queryKey: 'userInfo',
    queryFn: async (): Promise<AxiosResponse<UserInfo>> =>
      axios.get(`${process.env.REACT_APP_MSAL_AUTHORITY}/openid/v2.0/userinfo`, {
        params: {
          client_id: process.env.REACT_APP_MSAL_CLIENT_ID,
        },
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }),
    staleTime: Infinity,
    enabled: isAuthenticated,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    select: (response) => response.data,
  });
};

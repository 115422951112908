import { HTMLAttributes, PropsWithChildren } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { layout, space, LayoutProps, SpaceProps } from 'styled-system';

import { Box } from '@/components/Box/Box';
import { TransparentButton } from '@/components/Button/TransparentButton';
import Icon from '@/components/Icon/Icon';
import { Body2 } from '@/components/Typography/Typography';

interface IFormStepProps extends LayoutProps, SpaceProps, HTMLAttributes<HTMLFormElement> {
  goToPreviousStep?: () => void;
}

export const FormStep = (props: PropsWithChildren<IFormStepProps>) => {
  const { children, goToPreviousStep, ...otherProps } = props;
  return (
    <FormStepRoot {...otherProps}>
      <Box alignSelf="flex-start" position={{ md: 'fixed' }} left={32}>
        {goToPreviousStep && (
          <TransparentButton onClick={goToPreviousStep} type="button" data-cy="previous-button">
            <Icon name="ic-arrow-left" size={24} />
            <Body2 m={0} ml="6px">
              {otherProps.title ? otherProps.title : 'Back to account'}
            </Body2>
          </TransparentButton>
        )}
      </Box>
      {children}
    </FormStepRoot>
  );
};

const FormStepRoot = styled.form`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  padding: 32px 24px 0;

  ${up('xl')} {
    padding: 0px 240px 0;
  }

  ${layout};
  ${space};
`;

import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';

export type BlockBackgroundVariant = 'light' | 'dark';

export interface InViewProps {
  onInViewChange?: (inView: boolean) => void;
}

interface IBlockProps extends React.HTMLAttributes<HTMLDivElement>, InViewProps {
  variant?: BlockBackgroundVariant;
  inViewThreshold?: number;
  inViewDelay?: number;
}

const DEFAULT_IN_VIEW_THRESHOLD = 0.5;

export const Block = (props: React.PropsWithChildren<IBlockProps>) => {
  const { children, inViewThreshold, inViewDelay, onInViewChange, ...otherProps } = props;

  const { ref, inView } = useInView({
    threshold: inViewThreshold || DEFAULT_IN_VIEW_THRESHOLD,
    delay: inViewDelay || 0,
  });

  useEffect(() => {
    onInViewChange?.(inView);
  }, [inView, onInViewChange]);

  return (
    <BlockRoot ref={ref} {...otherProps}>
      {children}
    </BlockRoot>
  );
};

const BlockRoot = styled.div<IBlockProps>`
  display: flex;
  flex-direction: column;

  color: ${(props) => props.theme.colors.pango};
  min-height: 100vh;
  justify-content: center;

  ${({ variant }) =>
    variant === 'dark' &&
    css`
      color: ${(props) => props.theme.colors.white};
    `}
`;

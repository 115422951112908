/* A button.. that looks like a link */

import React, { forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { space, SpaceProps, layout, LayoutProps } from 'styled-system';

import { Body2 } from '../Typography/Typography';

interface IButtonLinkProps extends React.HTMLAttributes<HTMLButtonElement>, SpaceProps, LayoutProps {}

export const ButtonLink = forwardRef<HTMLButtonElement, PropsWithChildren<IButtonLinkProps>>(
  ({ children, ...props }, ref) => {
    return (
      <ButtonRoot type="button" ref={ref} {...props}>
        <Body2 as="span">{children}</Body2>
      </ButtonRoot>
    );
  },
);

ButtonLink.displayName = 'ButtonLink';

const ButtonRoot = styled.button<LayoutProps & SpaceProps>`
  appearance: none;
  border: 0;
  background-color: transparent;
  display: inline-flex;
  width: auto;
  padding: 0;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white};

  :hover {
    text-decoration: underline;
  }

  ${space}
  ${layout}
`;

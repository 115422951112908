import { cloneElement } from 'react';
import styled from 'styled-components';
import { space, layout, flexbox, SpaceProps, LayoutProps, FlexboxProps } from 'styled-system';

import { ICONS } from './svg-content';

export type IconName = keyof typeof ICONS;

export interface IconProps {
  className?: string;
  name: IconName;
  color?: string;
}

const IconWrapper = styled.span<{
  color?: string;
}>`
  ${space}
  ${layout}
  ${flexbox}

  display: inline-block;

  > svg {
    /* width: 100%; */
    fill: ${(props) => props.color};
    stroke: ${(props) => props.color};
    height: 100%;
    width: 100%;
  }
`;

const Icon = ({ className, name, ...rest }: IconProps & SpaceProps & LayoutProps & FlexboxProps) => {
  const icon = ICONS[name];

  const trueIcon = cloneElement(icon, { preserveAspectRatio: 'none' });

  if (!icon) {
    // eslint-disable-next-line
    console.warn(`No icon found for ${name}`);
    return null;
  }

  return (
    <IconWrapper flexShrink={0} flexGrow={0} className={className} {...rest}>
      {trueIcon}
    </IconWrapper>
  );
};

export default Icon;

import { rest } from 'msw';

import { getCurrentMockAccount } from '../helpers/getCurrentMockAccount';

import { ConfidenceLevel, UserInfo } from '@/features/auth';

export enum MOCK_USERS {
  level1NoSubmissions = 'level1NoSubmissions',
  level1TwoSubmissions = 'level1TwoSubmissions',
  level3NNoSubmissions = 'level3NNoSubmissions',
  level3NTwoSubmissions = 'level3NTwoSubmissions',
  level3NOneSubmissionOneOnBehalf = 'level3NOneSubmissionOneOnBehalf',
  level3NNoSubmissionsOneOnBehalf = 'level3NNoSubmissionsOneOnBehalf',
  level3NOneSubmissionFiveOnBehalf = 'level3NOneSubmissionFiveOnBehalf',
}

export const MOCK_USER_RESPONSES: { [key in MOCK_USERS]: UserInfo } = {
  [MOCK_USERS.level1NoSubmissions]: {
    birthdate: '1985-11-14',
    email: 'john-doe@mocks.com',
    family_name: 'Doe',
    given_name: 'John',
    nickname: null,
    'urn:login:health:nz:claims:confidence_level': ConfidenceLevel.LEVEL1,
    'urn:login:health:nz:claims:mobile_number': '021123123',
    'urn:login:health:nz:claims:nhi': 'ABC1234',
  },
  [MOCK_USERS.level1TwoSubmissions]: {
    birthdate: '1991-10-10',
    email: 'rachael-doe@mocks.com',
    family_name: 'Doe',
    given_name: 'Rachael',
    nickname: 'Rach',
    'urn:login:health:nz:claims:confidence_level': ConfidenceLevel.LEVEL1,
    'urn:login:health:nz:claims:mobile_number': '02444444444',
    'urn:login:health:nz:claims:nhi': 'AA99999',
  },
  [MOCK_USERS.level3NNoSubmissions]: {
    birthdate: '1979-02-01',
    email: 'sally-doe@mocks.com',
    family_name: 'Doe',
    given_name: 'Sally',
    nickname: 'Salz',
    'urn:login:health:nz:claims:confidence_level': ConfidenceLevel.LEVEL3N,
    'urn:login:health:nz:claims:mobile_number': '0299999999',
    'urn:login:health:nz:claims:nhi': 'ZZ12346',
  },
  [MOCK_USERS.level3NTwoSubmissions]: {
    birthdate: '1980-10-08',
    email: 'tristian-webb@mocks.com',
    family_name: 'Webb',
    given_name: 'Tristian',
    nickname: null,
    'urn:login:health:nz:claims:confidence_level': ConfidenceLevel.LEVEL3N,
    'urn:login:health:nz:claims:mobile_number': '0299999999',
    'urn:login:health:nz:claims:nhi': 'ZZ9999',
  },
  [MOCK_USERS.level3NOneSubmissionOneOnBehalf]: {
    birthdate: '1980-10-08',
    email: 'rowan-le@mocks.com',
    family_name: 'Le',
    given_name: 'Rowan',
    nickname: null,
    'urn:login:health:nz:claims:confidence_level': ConfidenceLevel.LEVEL3N,
    'urn:login:health:nz:claims:mobile_number': '0299999999',
    'urn:login:health:nz:claims:nhi': 'ZZ5555',
  },
  [MOCK_USERS.level3NNoSubmissionsOneOnBehalf]: {
    birthdate: '1985-07-12',
    email: 'faye-thomas@mocks.com',
    family_name: 'Thomas',
    given_name: 'Faye',
    nickname: null,
    'urn:login:health:nz:claims:confidence_level': ConfidenceLevel.LEVEL3N,
    'urn:login:health:nz:claims:mobile_number': '0299999999',
    'urn:login:health:nz:claims:nhi': 'ZZ6666',
  },
  [MOCK_USERS.level3NOneSubmissionFiveOnBehalf]: {
    birthdate: '1976-03-18',
    email: 'nick-staunton@mocks.com',
    family_name: 'Staunton',
    given_name: 'Nick',
    nickname: null,
    'urn:login:health:nz:claims:confidence_level': ConfidenceLevel.LEVEL3N,
    'urn:login:health:nz:claims:mobile_number': '0299999999',
    'urn:login:health:nz:claims:nhi': 'ZZ7878',
  },
};

export const handlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/userInfo`, (req, res, ctx) => {
    const mockAccount = getCurrentMockAccount() || undefined;

    if (!mockAccount) {
      return res(ctx.delay(1000), ctx.status(403), ctx.json({ error: 'You must be logged in' }));
    }

    const response = MOCK_USER_RESPONSES[mockAccount as MOCK_USERS];

    return res(ctx.delay(1000), ctx.status(200), ctx.json(response));
  }),
];

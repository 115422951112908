import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
  PublicClientApplication,
  InteractionStatus,
  InteractionRequiredAuthError,
  EventType,
  AuthenticationResult,
} from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCallback, useMemo, useEffect, useState, createContext, useContext, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
    knownAuthorities: process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES.split(','),
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    secureCookies: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message);
            return;
          case LogLevel.Info:
            if (process.env.NODE_ENV === 'development') {
              // eslint-disable-next-line no-console
              console.info(message);
            }
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message);
            return;
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.warn(message);
        }
      },
    },
  },
};

export const msalApplication = new PublicClientApplication(MSAL_CONFIG);

export enum AuthStatus {
  LOADING,
  LOGIN,
  LOGOUT,
  AcquireToken,
  SsoSilent,
  NONE,
}

const clearWizardStates = () => {
  if (sessionStorage.getItem('showToast') !== null) {
    sessionStorage.removeItem('showToast');
  }
  if (sessionStorage.getItem('showBehalfOfToast') !== null) {
    sessionStorage.removeItem('showBehalfOfToast');
  }
  if (sessionStorage.getItem('showDeleteToast') !== null) {
    sessionStorage.removeItem('showDeleteToast');
  }
  if (sessionStorage.getItem('mergedData') !== null) {
    sessionStorage.removeItem('mergedData');
  }
};

const getAuthState = (status: InteractionStatus) => {
  if (status === InteractionStatus.Login) {
    return AuthStatus.LOGIN;
  }

  if (status === InteractionStatus.Logout) {
    return AuthStatus.LOGOUT;
  }

  if ([InteractionStatus.HandleRedirect, InteractionStatus.Startup].includes(status)) {
    return AuthStatus.LOADING;
  }

  if (status === InteractionStatus.AcquireToken) {
    return AuthStatus.AcquireToken;
  }

  if (status === InteractionStatus.SsoSilent) {
    return AuthStatus.SsoSilent;
  }

  return AuthStatus.NONE;
};

/**
 * A utility hook for handling authentication state
 */
export const useAuth = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance } = useMsal();

  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('Must be wrapped in an AuthProvider.');
  }

  const { logout, ...authFunctions } = authContext;

  const interactionState = useMemo(() => getAuthState(inProgress), [inProgress]);

  const handleLogOut = useCallback(() => {
    // Only fire logout request if it isn't already in the process of logging out
    if (interactionState !== AuthStatus.LOGOUT) {
      logout();
    }
  }, [interactionState, logout]);

  return {
    isAuthenticated,
    interactionState,
    instance,
    logout: handleLogOut,
    ...authFunctions,
  };
};

/**
 * Returns a promise that gets the user's access token.
 */
export const useAccessTokenPromise = () => {
  const { accounts, instance } = useMsal();

  const request = {
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    scopes: process.env.REACT_APP_MSAL_SCOPE.split(','),
    account: accounts[0] !== null ? accounts[0] : undefined,
  };

  return () =>
    instance
      .acquireTokenSilent(request)
      .then((res) => res.accessToken)
      .catch((error) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request);
        }
      });
};

const AuthContext = createContext<
  | {
      idToken?: string;
      setIdToken: (idToken: string | undefined) => void;
      login: (props?: { redirectTo?: string }) => void;
      signup: () => void;
      logout: () => void;
    }
  | undefined
>(undefined);

const accounts = msalApplication.getAllAccounts();
if (accounts.length > 0 && accounts[0]) {
  msalApplication.setActiveAccount(accounts[0]);
}

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const navigate = useNavigate();
  const [idToken, setIdToken] = useState<string | undefined>(undefined);

  /**
   * Auth event side-effects:
   *   1. LOGIN_SUCCESS: Set active account & id token (for the idTokenHint when logging out)
   *   2. ACQUIRE_TOKEN_SUCCESS: Set id token (for the idTokenHint when logging out)
   *   3. LOGIN_SUCCESS: Clear wizard states
   *   4. LOGOUT_START: Clear wizard states & clear 'hasRedirected' state
   *
   * Events: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md#table-of-events
   */
  useEffect(() => {
    const unsubscribeId = msalApplication.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        // Set active account
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalApplication.setActiveAccount(account);

        // Record id token
        setIdToken(payload.idToken);
      } else if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
        // Record id token
        const payload = event.payload as AuthenticationResult;
        setIdToken(payload.idToken);
      } else if (event.eventType === EventType.LOGIN_SUCCESS) {
        // ...
      } else if (event.eventType === EventType.LOGOUT_START) {
        // ...
      }
    });

    // Redirect to the page the user was trying to visit before being redirected to the login page.
    // This needs to be here in the code because it causes the interactionState to be stuck in LOADING.
    // This is related to useAuth.login
    msalApplication.handleRedirectPromise().then((response) => {
      if (response?.state && !window.sessionStorage.getItem('hasRedirected')) {
        window.sessionStorage.setItem('hasRedirected', 'true');
        navigate(response.state);
      }
    });

    return () => {
      if (unsubscribeId) {
        msalApplication.removeEventCallback(unsubscribeId);
      }
    };
  }, [navigate]);

  const login = useCallback((props?: { redirectTo?: string }) => {
    window.sessionStorage.removeItem('hasRedirected');
    clearWizardStates();
    msalApplication
      .loginRedirect({
        scopes: process.env.REACT_APP_MSAL_SCOPE.split(','),
        // we get this state back in the redirect from B2C and use it to redirect
        // the user to the page they were on.
        // This is related to useAuth's auth event side-effects useEffect (msalApplication.handleRedirectPromise())
        state: props?.redirectTo || '/verify-account',
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const signup = useCallback(
    () =>
      msalApplication
        .loginRedirect({
          scopes: process.env.REACT_APP_MSAL_SCOPE.split(','),
          extraQueryParameters: {
            flowType: 'register',
          },
        })
        .catch((error) => {
          console.error(error);
        }),
    [],
  );

  const logout = useCallback(() => {
    clearWizardStates();
    return msalApplication.logoutRedirect({
      // Provide the ID token when logging out to prevent My Health Account error
      // from appearing on log out.
      idTokenHint: idToken,
    });
  }, [idToken]);

  return (
    <MsalProvider instance={msalApplication}>
      <AuthContext.Provider value={{ idToken, setIdToken, login, signup, logout }}>{children}</AuthContext.Provider>
    </MsalProvider>
  );
};

import { useState } from 'react';
import { useMutation } from 'react-query';

import { makePatchRequest } from '../../../tools/ajax';

import { useAccessTokenPromise } from '@/features/auth';

const API_PATH = process.env.REACT_APP_BEHALF_OF_UPDATE_AUTHENTICATED_API_PATH;

interface IUseUpdateIwiAffiliationProps {
  onSuccessOfSubmission?: () => void;
  onErrorOfSubmission?: () => void;
}

export const useUpdateIwiAffiliation = ({
  onSuccessOfSubmission,
  onErrorOfSubmission,
}: IUseUpdateIwiAffiliationProps) => {
  const getAccessToken = useAccessTokenPromise();
  // const getAccessToken = useAccessTokenPromise();

  const updateIwiAffiliationApi = async (data: any) => makePatchRequest(API_PATH, data, getAccessToken);

  const { mutate, isLoading } = useMutation(updateIwiAffiliationApi);
  const [showSubmissionError, setShowSubmissionError] = useState<boolean>();

  const updateIwiAffiliation = (data: any) => {
    mutate(data, {
      onSuccess: () => {
        window.sessionStorage.setItem('SelectedValueData', data);
        setShowSubmissionError(false);
        if (onSuccessOfSubmission) {
          onSuccessOfSubmission();
        }
      },
      onError: () => {
        setShowSubmissionError(true);
        if (onErrorOfSubmission) {
          onErrorOfSubmission();
        }
      },
    });
  };

  return {
    isLoading,
    updateIwiAffiliation,
    showSubmissionError,
    setShowSubmissionError,
  };
};

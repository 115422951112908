import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { useIwiAffiliationFormContext } from '../../hooks/useIwiAffiliationFormContext';
import { IContactDetailsData } from '../../types';
import { FormStep } from '../FormStep';
import { FormStepActions } from '../FormStepActions';
import { FormSteps } from '../IwiAffiliationFormContext';

import { MaoriLanguage } from '@/components/Accessibility/MaoriLanguage';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { FlowFooter } from '@/components/FlowFooter/FlowFooter';
import { DefaultLayout } from '@/components/Layouts/Layouts';
import { TextInput } from '@/components/TextInput/TextInput';
import { Body2, H2 } from '@/components/Typography/Typography';
import { useUserInfo } from '@/features/auth';
import { isEmailValid } from '@/tools/email';
import { isPhoneValid } from '@/tools/phone';

export const Step4ContactDetails = () => {
  const { formData, goToNextStep, setFormData, goToPreviousStep } = useIwiAffiliationFormContext();
  const stepData = formData[FormSteps.CONTACT_DETAILS];
  const { data } = useUserInfo();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    clearErrors,
  } = useForm<IContactDetailsData>({ defaultValues: stepData });
  const watchPhone = watch('phone', stepData.phone);
  const watchEmail = watch('email', stepData.email);

  const stepSubmitHandler = (data: IContactDetailsData) => {
    const formDataUpdated = { ...formData, [FormSteps.CONTACT_DETAILS]: data };

    setFormData(formDataUpdated);
    goToNextStep(formDataUpdated);
  };

  return (
    <Wrapper>
      <DefaultLayout>
        <FormStep
          goToPreviousStep={() => goToPreviousStep(formData)}
          onSubmit={handleSubmit(stepSubmitHandler)}
          labelled-by="label_formStep"
          pt="8px"
          mt={{ _: '65px', md: '144px' }}
        >
          <Box
            maxWidth={689}
            mt={{ _: 30, md: 55 }}
            width="100%"
            pb={36}
            flexGrow={1}
            flexShrink={0}
            display="flex"
            flexDirection="column"
          >
            <Box alignSelf="flex-start" my={16} mt={{ md: 0 }} color="white">
              <H2 id="label_formStep">Contact Details</H2>
            </Box>
            <Box mb={16}>
              <Body2 mb="1rem" color="white">
                We will collect these details so we, and your <MaoriLanguage>iwi</MaoriLanguage>, can contact you if
                required, for instance, the <MaoriLanguage>iwi</MaoriLanguage> you have selected may contact you to
                confirm your affiliation to that <MaoriLanguage>iwi</MaoriLanguage>.
              </Body2>
              <Body2 color="white">Please enter an email and/or contact number.</Body2>
            </Box>
            <FormInputRow>
              {data && data.email ? (
                <Controller
                  defaultValue={data.email || ''}
                  name="email"
                  control={control}
                  rules={{
                    validate: (value?: string) => {
                      if (!value && !watchPhone) {
                        return 'Please enter an email or phone number before continuing';
                      } else {
                        clearErrors('phone');
                      }

                      if (value) {
                        const isValidEmail = isEmailValid(value);

                        if (!isValidEmail) {
                          return 'Please enter a valid email';
                        }
                      }

                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      dataCy="email"
                      placeholder="Enter your email"
                      autoComplete="off"
                      label="Email"
                      id="input_email"
                      error={errors.email}
                      {...field}
                    />
                  )}
                />
              ) : (
                <Controller
                  defaultValue={stepData.email || ''}
                  name="email"
                  control={control}
                  rules={{
                    validate: (value?: string) => {
                      if (!value && !watchPhone) {
                        return 'Please enter an email or phone number before continuing';
                      } else {
                        clearErrors('phone');
                      }

                      if (value) {
                        const isValidEmail = isEmailValid(value);

                        if (!isValidEmail) {
                          return 'Please enter a valid email';
                        }
                      }

                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      dataCy="email"
                      placeholder="Enter your email"
                      autoComplete="off"
                      label="Email"
                      id="input_email"
                      error={errors.email}
                      {...field}
                    />
                  )}
                />
              )}
            </FormInputRow>

            <FormInputRow>
              {data && data['urn:login:health:nz:claims:mobile_number'] ? (
                <Controller
                  defaultValue={data['urn:login:health:nz:claims:mobile_number'] || ''}
                  name="phone"
                  control={control}
                  rules={{
                    validate: (value?: string) => {
                      if (!value && !watchEmail) {
                        return 'Please enter a phone number or email before continuing';
                      } else {
                        if (!watchEmail) {
                          clearErrors('email');
                        }
                      }

                      if (value) {
                        const isValidPhone = isPhoneValid(value);

                        if (!isValidPhone) {
                          return 'Please provide a valid contact number';
                        }
                      }

                      // clearErrors('email');

                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      dataCy="phone"
                      placeholder="Enter your contact number"
                      autoComplete="off"
                      label="Contact number"
                      id="input_phoneNumber"
                      error={errors.phone}
                      {...field}
                    />
                  )}
                />
              ) : (
                <Controller
                  defaultValue={stepData.phone || ''}
                  name="phone"
                  control={control}
                  rules={{
                    validate: (value?: string) => {
                      if (!value && !watchEmail) {
                        return 'Please enter a phone number or email before continuing';
                      } else {
                        if (!watchEmail) {
                          clearErrors('email');
                        }
                      }

                      if (value) {
                        const isValidPhone = isPhoneValid(value);

                        if (!isValidPhone) {
                          return 'Please provide a valid contact number';
                        }
                      }

                      // clearErrors('email');

                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <TextInput
                      dataCy="phone"
                      placeholder="Enter your contact number"
                      autoComplete="off"
                      label="Contact number"
                      id="input_phoneNumber"
                      error={errors.phone}
                      {...field}
                    />
                  )}
                />
              )}
            </FormInputRow>
            <Box mt={{ _: 'auto', md: '8px' }}>
              <FormStepActions>
                <Button label="Next" type="submit" data-cy="next-button" />
              </FormStepActions>
            </Box>
          </Box>
        </FormStep>
      </DefaultLayout>
      <FlowFooter />
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const FormInputRow = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

import { useState } from 'react';
import { createContext } from 'react';

import {
  Gender,
  IContactDetailsData,
  IIwiLookupData,
  IMaoriDescentData,
  IsMaoriDescentResponses,
  IsProvidingAddressResponses,
  ISummaryData,
  IYourDetailsData,
  KnowsIwiResponses,
} from '../types';

import { Step1Descent } from './steps/Step1Descent';
import { Step2IwiLookup } from './steps/Step2IwiLookup';
import { Step3YourDetails } from './steps/Step3YourDetails';
import { Step4ContactDetails } from './steps/Step4ContactDetails';
import { Step5Summary } from './steps/Step5Summary';
import { Step6Confirmation } from './steps/Step6Confirmation';
import { Step7ConfirmationNonMaori } from './steps/Step7ConfirmationNonMaori';

const useMocks = process.env.REACT_APP_USE_MOCKS === 'true';

export enum FormSteps {
  MAORI_DESCENT = 'MAORI_DESCENT',
  IWI_LOOKUP = 'IWI_LOOKUP',
  YOUR_DETAILS = 'YOUR_DETAILS',
  CONTACT_DETAILS = 'CONTACT_DETAILS',
  SUMMARY = 'SUMMARY',
  CONFIRMATION = 'CONFIRMATION',
  CONFIRMATION_NON_MAORI = 'CONFIRMATION_NON_MAORI',
}

export interface IIwiAffiliationFormData {
  [FormSteps.MAORI_DESCENT]: IMaoriDescentData;
  [FormSteps.IWI_LOOKUP]: IIwiLookupData;
  [FormSteps.YOUR_DETAILS]: IYourDetailsData;
  [FormSteps.CONTACT_DETAILS]: IContactDetailsData;
  [FormSteps.SUMMARY]: ISummaryData;
}

export interface IIwiAffiliationFormContext {
  currentStep: number;
  formData: IIwiAffiliationFormData;
  setFormData: (data: IIwiAffiliationFormData) => void;
  goToNextStep: (data?: IIwiAffiliationFormData) => void;
  goToPreviousStep: (data?: IIwiAffiliationFormData) => void;
  goToStep: (formStep: FormSteps) => void;
}

export const FORM_STEPS_ORDER = [
  FormSteps.MAORI_DESCENT,
  FormSteps.IWI_LOOKUP,
  FormSteps.YOUR_DETAILS,
  FormSteps.CONTACT_DETAILS,
  FormSteps.SUMMARY,
  FormSteps.CONFIRMATION,
  FormSteps.CONFIRMATION_NON_MAORI,
];

export const FORM_STEP_TO_COMPONENT = {
  [FormSteps.MAORI_DESCENT]: Step1Descent,
  [FormSteps.IWI_LOOKUP]: Step2IwiLookup,
  [FormSteps.YOUR_DETAILS]: Step3YourDetails,
  [FormSteps.CONTACT_DETAILS]: Step4ContactDetails,
  [FormSteps.SUMMARY]: Step5Summary,
  [FormSteps.CONFIRMATION]: Step6Confirmation,
  [FormSteps.CONFIRMATION_NON_MAORI]: Step7ConfirmationNonMaori,
};

const DEFAULT_INITIAL_STATE: IIwiAffiliationFormData = {
  [FormSteps.MAORI_DESCENT]: {},
  [FormSteps.IWI_LOOKUP]: {},
  [FormSteps.YOUR_DETAILS]: {
    address: {
      addressLine1: '',
      city: '',
      postcode: '',
    },
  },
  [FormSteps.CONTACT_DETAILS]: {},
  [FormSteps.SUMMARY]: {},
};

const FULL_MOCK: IIwiAffiliationFormData = {
  [FormSteps.MAORI_DESCENT]: {
    isMaoriDescent: IsMaoriDescentResponses.YES,
    doesKnowIwi: KnowsIwiResponses.YES,
  },
  [FormSteps.IWI_LOOKUP]: {
    affiliatedIwi: [
      {
        id: '0101',
        label: 'Te Aupōuri',
        sanitisedLabel: 'Te Aupouri',
        region: {
          id: '01',
          label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) ',
        },
      },
      {
        id: '0103',
        label: 'Ngāti Kurī',
        sanitisedLabel: 'Ngati Kuri',
        region: {
          id: '01',
          label: 'Te Tai Tokerau/Tāmaki-makaurau (Northland/Auckland) ',
        },
      },
    ],
  },
  [FormSteps.YOUR_DETAILS]: {
    nhiNumber: 'ABC1234',
    firstName: 'Phillip',
    middleName: 'Michael',
    lastName: 'Thomas',
    isProvidingAddress: IsProvidingAddressResponses.YES,
    address: {
      addressLine1: '73 W Flagler St',
      addressLine2: 'Unit 2',
      suburb: 'Karori',
      city: 'Wellington',
      postcode: '0987',
    },
    dateOfBirth: '26/05/1949',
    gender: Gender.MALE,
  },
  [FormSteps.CONTACT_DETAILS]: {
    email: 'phillip@miamivice.com',
    phone: '0274655582',
  },
  [FormSteps.SUMMARY]: {},
};

type IStepConditions = {
  [key in FormSteps]?: {
    canEnter: (data: IIwiAffiliationFormData) => boolean;
  };
};

const CONDITIONAL_STEPS: IStepConditions = {
  [FormSteps.IWI_LOOKUP]: {
    canEnter: (data: IIwiAffiliationFormData) => {
      const { isMaoriDescent, doesKnowIwi } = data[FormSteps.MAORI_DESCENT];

      const isMaoriDescentAndDoesKnowIwi =
        isMaoriDescent === IsMaoriDescentResponses.YES && doesKnowIwi === KnowsIwiResponses.YES;
      const isMaoriDescentAndDoesKnowSomeIwi =
        isMaoriDescent === IsMaoriDescentResponses.YES && doesKnowIwi === KnowsIwiResponses.PARTIALLY;
      const isMaoriDescentAndDoesNotKnowIwi = IsMaoriDescentResponses.YES && doesKnowIwi === KnowsIwiResponses.NO;
      const doesntKnowMaoriDescentAndDoesKnowIwi =
        isMaoriDescent === IsMaoriDescentResponses.DONT_KNOW && doesKnowIwi === KnowsIwiResponses.YES;
      const doesntKnowMaoriDescentAndDoesKnowSomeIwi =
        isMaoriDescent === IsMaoriDescentResponses.DONT_KNOW && doesKnowIwi === KnowsIwiResponses.PARTIALLY;
      const doesntKnowMaoriDescentAndDoesNotKnowIwi =
        isMaoriDescent === IsMaoriDescentResponses.DONT_KNOW && doesKnowIwi === KnowsIwiResponses.NO;

      return (
        isMaoriDescentAndDoesKnowIwi ||
        isMaoriDescentAndDoesKnowSomeIwi ||
        isMaoriDescentAndDoesNotKnowIwi ||
        doesntKnowMaoriDescentAndDoesKnowIwi ||
        doesntKnowMaoriDescentAndDoesKnowSomeIwi ||
        doesntKnowMaoriDescentAndDoesNotKnowIwi
      );
    },
  },
};

export const IwiAffiliationFormContext = createContext<IIwiAffiliationFormContext | null>(null);

export const IwiAffiliationFormProvider = () => {
  const [formData, setFormData] = useState<IIwiAffiliationFormData>(useMocks ? FULL_MOCK : DEFAULT_INITIAL_STATE);
  const [currentStep, setStep] = useState<number>(0);

  const goToStep = (step: FormSteps) => {
    setStep(FORM_STEPS_ORDER.indexOf(step));
  };

  /**
   * Subtracts 1 from the current step and calls the function that resolves
   * the next step to go to.
   * @param data Form data
   * @returns void
   */
  const goToPreviousStep = (data?: IIwiAffiliationFormData) => {
    const nextStep = currentStep - 1;

    if (nextStep === -1) {
      console.log('There has been an error in the form. Next step reached a negative value.');
      return;
    }

    handleStepChange(nextStep, data, false);
  };

  /**
   * Adds 1 to the current step and calls the function that resolves
   * the next step to go to.
   * @param data Form data
   * @returns void
   */
  const goToNextStep = (data?: IIwiAffiliationFormData) => {
    const nextStep = currentStep + 1;
    handleStepChange(nextStep, data);
  };

  /**
   * This function checks on the conditions for the next step to go to.
   * If the data indicates that the user has answered no to the Māori Descent step
   * first question, then the user is automatically taken to the CONFIRMATION_NON_MAORI
   * step.
   * @param nextStep The step that would follow prior to check on conditions
   * @param data Form data
   * @param isMovingForward If it's going ahead or back on the form
   * @returns void
   */
  const handleStepChange = (nextStep: number, data?: IIwiAffiliationFormData, isMovingForward = true) => {
    if (!data) {
      setStep(nextStep);
      return;
    }

    if (data[FormSteps.MAORI_DESCENT].isMaoriDescent === IsMaoriDescentResponses.NO) {
      nextStep = FORM_STEPS_ORDER.indexOf(FormSteps.CONFIRMATION_NON_MAORI);
      setStep(nextStep);
      return;
    }

    const nextStepConditions = CONDITIONAL_STEPS[FORM_STEPS_ORDER[nextStep]];

    if (!nextStepConditions) {
      setStep(nextStep);
      return;
    }

    const canEnterStep = nextStepConditions.canEnter(data);

    if (!canEnterStep) {
      nextStep = isMovingForward ? nextStep + 1 : nextStep - 1;
    }

    setStep(nextStep);
  };

  const StepComponent = FORM_STEP_TO_COMPONENT[FORM_STEPS_ORDER[currentStep]];

  return (
    <IwiAffiliationFormContext.Provider
      value={{ currentStep, formData, goToStep, setFormData, goToNextStep, goToPreviousStep }}
    >
      <StepComponent />
    </IwiAffiliationFormContext.Provider>
  );
};

import styled from 'styled-components';

export const TransparentButton = styled.button`
  display: inline-flex;
  align-items: center;
  background: none;
  width: auto;
  border: 0;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  padding: 0;

  svg {
    fill: ${(props) => props.theme.colors.white};
  }
`;

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Navigate } from 'react-router';

import { GlobalHeader } from '@/components/GlobalHeader/GlobalHeader';
import { Head } from '@/components/Head/Head';
import { useAuth } from '@/features/auth';
import { useBindMha } from '@/features/signin/api';
import { MHALinked } from '@/features/signin/components';

export const LinkMha = () => {
  useAuth();
  const { data } = useBindMha();

  if (data.data.responseObj && data.data.responseObj.optOut) {
    window.sessionStorage.setItem('optOut', data.data.responseObj.optOut.toString());
  }
  window.sessionStorage.setItem('mergedData', data.data.responseObj.mergedData.toString());

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}>
      <Head />

      <GlobalHeader variantOverride="dark" />

      <main>
        {data && data.status === 200 && data.data.message === 'NHI Processed' ? (
          <MHALinked />
        ) : (
          <Navigate to="/manage" />
        )}
      </main>
    </GoogleReCaptchaProvider>
  );
};

import { ListboxButton, ListboxInput, ListboxOption, ListboxPopover, ListboxList } from '@reach/listbox';
import { useState } from 'react';
import React from 'react';
import { useQueryClient } from 'react-query';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { useSessionStorage } from 'usehooks-ts';

import version from '../../../../package.json';
import Icon from '../../../components/Icon/Icon';
import { USERS_TO_SUBMISSIONS } from '../handlers/submissions';
import { MOCK_USERS, MOCK_USER_RESPONSES } from '../handlers/userInfo';

import { Box } from '@/components/Box/Box';
import { Fineprint } from '@/components/Typography/Typography';
import { useAuth, useUserInfo } from '@/features/auth';

import '@reach/listbox/styles.css';

const VersionNumber = styled(Fineprint)`
  text-align: center;
`;

const MockPanelWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  inset: 0;
  z-index: 100;
  transition: all 200ms ease-in-out;
  pointer-events: none;
  background: rgba(51, 51, 51, 0);

  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: initial;
      background: rgba(51, 51, 51, 0.8);
    `}
`;

const MockPanelButton = styled.button<{ isOpen: boolean }>`
  border: 0;
  background-color: white;
  position: absolute;
  border-radius: 0 0 50% 50%;
  height: 60px;
  width: 60px;
  top: 99%;
  right: 50%;
  transform: translateX(50%);
  cursor: pointer;

  ${up('md')} {
    border-radius: 0 50% 50% 0;
    transform: translateX(0%);
    left: 99%;
    bottom: 10%;
    top: unset;
    right: unset;
  }
`;

const MockPanelButtonIcon = styled(Icon)<{ isOpen: boolean }>`
  transition: transform 500ms ease-in-out;
  transform: rotate(90deg);

  ${up('md')} {
    transform: rotate(0deg);
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(90deg) scaleX(-1);

      ${up('md')} {
        transform: scaleX(-1);
      }
    `}
`;

const MockPanelDrawer = styled.div<{ isOpen: boolean }>`
  pointer-events: initial;
  position: fixed;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: transform 200ms ease-in-out;
  right: 0;
  left: 0;
  min-height: 250px;
  transform: translateY(-100%);

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(0);
    `}

  ${up('md')} {
    min-width: 250px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: unset;
    transform: translateX(-100%);

    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: translateX(0);
      `}
  }
`;

const MockPanelSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  & > * {
    margin-bottom: 8px;
  }
`;

export const MocksPanel = () => {
  const queryClient = useQueryClient();
  const [showDialog, setShowDialog] = useState(false);
  const [mockAccount, setMockAccount] = useSessionStorage<MOCK_USERS | undefined>('mock-account', undefined);

  const { isAuthenticated, login, logout } = useAuth();
  const { data } = useUserInfo();

  const handleMockAccountChange = (newValue) => {
    setMockAccount(() => newValue);

    queryClient.resetQueries('userInfo');
    queryClient.resetQueries('submissions');
  };

  return (
    <MockPanelWrapper isOpen={showDialog}>
      <MockPanelDrawer isOpen={showDialog}>
        <MockPanelButton isOpen={showDialog} onClick={() => setShowDialog(() => !showDialog)}>
          <MockPanelButtonIcon name="ic-arrow-right" size={40} isOpen={showDialog} />
        </MockPanelButton>

        <MockPanelSection>
          {!isAuthenticated ? (
            <button
              onClick={() => {
                login();
              }}
            >
              Sign in
            </button>
          ) : (
            <>
              <span>{`Logged in as ${data?.given_name} ${data?.family_name}`}</span>
              <button onClick={logout}>Sign out</button>
            </>
          )}
        </MockPanelSection>

        <MockPanelSection>
          <label htmlFor="mock-account-select">Select account:</label>

          <MocksPanelListboxInput
            id="mock-account-select"
            onChange={handleMockAccountChange}
            value={mockAccount || undefined}
          >
            <MocksPanelListboxButton>
              {mockAccount
                ? `${MOCK_USER_RESPONSES[mockAccount].given_name} ${MOCK_USER_RESPONSES[mockAccount].family_name}`
                : 'Select an account...'}
              <Icon name="ic-arrow-right" size={16} />
            </MocksPanelListboxButton>
            <MocksPanelPopover portal={false}>
              <ListboxList>
                {Object.keys(MOCK_USERS).map((user) => {
                  return (
                    <MocksPanelListboxOption key={user} value={user}>
                      <MockAccountSummary user={user as unknown as MOCK_USERS} />
                    </MocksPanelListboxOption>
                  );
                })}
              </ListboxList>
            </MocksPanelPopover>
          </MocksPanelListboxInput>
        </MockPanelSection>

        <Box marginTop="auto">
          <VersionNumber>version: {version.version}</VersionNumber>
        </Box>
      </MockPanelDrawer>
    </MockPanelWrapper>
  );
};

const MocksPanelListboxInput = styled(ListboxInput)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const MocksPanelListboxButton = styled(ListboxButton)`
  flex-grow: 1;
  padding: 8px 16px;
`;

const MocksPanelPopover = styled(ListboxPopover)`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  top: 100%;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const MocksPanelListboxOption = styled(ListboxOption)`
  padding: 8px 8px;
`;

const MockAccountSummary = ({ user }: { user: MOCK_USERS }) => {
  const userInfo = MOCK_USER_RESPONSES[user];
  const submissions = USERS_TO_SUBMISSIONS[user];

  if (!userInfo) {
    return null;
  }

  const mySubmissions = submissions.filter(
    (submission) => submission.nhiNumber === userInfo['urn:login:health:nz:claims:nhi'],
  );

  const onBehalfSubmissions = submissions.filter(
    (submission) => submission.nhiNumber !== userInfo['urn:login:health:nz:claims:nhi'],
  );

  return (
    <>
      <strong>{`${userInfo.given_name} ${userInfo.nickname ? ` (${userInfo.nickname}) ` : ''} ${
        userInfo.family_name
      }`}</strong>
      <ul>
        <li>NHI: {userInfo['urn:login:health:nz:claims:nhi']}</li>
        <li># Submissions: {mySubmissions.length}</li>
        <li># On-behalf submissions: {onBehalfSubmissions.length}</li>
        <li>Confidence level: {userInfo['urn:login:health:nz:claims:confidence_level']}</li>
      </ul>
    </>
  );
};

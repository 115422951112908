import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import { Box } from '@/components/Box/Box';
import { Button, LinkButton } from '@/components/Button/Button';
import { ScrollToTopButton } from '@/components/Button/ScrollToTopButton';
import { FooterNavigation } from '@/components/FooterNavigation/FooterNavigation';
import { useLandingContext } from '@/components/LandingContextProvider/useLandingContext';
import { DefaultLayout } from '@/components/Layouts/Layouts';
import { Body, H2 } from '@/components/Typography/Typography';
import { Navigation } from '@/features/iwiAffiliationForm/components/Navigation';

export const MHALinked = () => {
  const mergedData = window.sessionStorage.getItem('mergedData');
  const {
    sectionConfig: { textColor },
  } = useLandingContext();
  /* We refocus the main title when using the back to top button */
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  const isDesktop = useBreakpoint(up('md'));
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate('/manage', { state: { mergedData: mergedData } });
  };
  return (
    <Wrapper>
      <DefaultLayout>
        <Navigation
          goToPreviousStep={() => navigate('/', { state: { previousStep: 'signin' } })}
          labelled-by="linked-account"
          padding="0px"
          marginTop={{ _: '72px' }}
        >
          <Box color={textColor} display="flex" flexDirection="column" alignItems="center" mt={{ _: 16, md: 32 }}>
            <StyledHeading>My Health Account successfully linked </StyledHeading>
            <Body mb="32px" mt="32px" textAlign={{ _: 'left', md: 'center' }}>
              The iwi affiliations you add will be linked to your health records.
            </Body>
            {mergedData === 'true' ? (
              // <LinkButton
              //   label="Next"
              //   to="/who-is-this-iwi-affiliation-for"
              //   data-cy="linked-account-next-button"
              //   maxWidth="101px"
              //   mb={{ _: '171.5px' }}
              // />
              <Button
                type="button"
                variant="dark"
                label="Next"
                onClick={navigateTo}
                maxWidth="101px"
                mb={{ _: '171.5px' }}
              />
            ) : (
              <LinkButton
                label="Next"
                to="/who-is-this-iwi-affiliation-for"
                data-cy="linked-account-next-button"
                maxWidth="101px"
                mb={{ _: '171.5px' }}
              />
            )}
          </Box>
        </Navigation>
      </DefaultLayout>
      {/* Footer navigation and back to top buttons */}
      <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }} zIndex={10} mt="auto">
        <FooterNavigation />

        {!isDesktop && (
          <Box mt={32} display="flex" justifyContent={{ _: 'center' }} flexShrink={0} flexGrow={0}>
            <ScrollToTopButton focusRef={mainTitleRef} />
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledHeading = styled(H2)`
  margin-top: 32px;
  ${up('md')} {
    margin-top: 150px;
  }
`;

import { useContext } from 'react';

import { LandingContext } from './LandingContextProvider';

export const useLandingContext = () => {
  const context = useContext(LandingContext);
  if (!context) {
    throw new Error('Ensure consuming component is wrapped in a LandingContextProvider');
  }

  return context;
};

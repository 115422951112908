import { useEffect, useRef, useState } from 'react';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

import { Iwi } from '../types';

import { MaoriLanguage } from '@/components/Accessibility/MaoriLanguage';
import { ScreenReaderOnly } from '@/components/Accessibility/ScreenReaderOnly';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { TransparentButton } from '@/components/Button/TransparentButton';
import Icon from '@/components/Icon/Icon';
import { Body2, Fineprint } from '@/components/Typography/Typography';

interface IStatusBarProps {
  selectedItems: Iwi[];
  recentlyAdded: Iwi | undefined;
  recentlyRemoved: Iwi | undefined;
  showDialog: any;
}

export const NotificationBar = (props: IStatusBarProps) => {
  const { selectedItems, recentlyAdded, recentlyRemoved, showDialog } = props;

  const addedPopoverRef = useRef<HTMLDivElement>(null);
  const removedPopoverRef = useRef<HTMLDivElement>(null);

  const [addedPopoverActive, setAddedPopoverActive] = useState<boolean>(false);
  const [removedPopoverActive, setRemovedPopoverActive] = useState<boolean>(false);

  useEffect(() => {
    setRemovedPopoverActive(false);
    setAddedPopoverActive(true);

    const addedTimeout = window.setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setAddedPopoverActive(false);
    }, 2500);

    return () => {
      clearTimeout(addedTimeout);
    };
  }, [recentlyAdded]);

  useEffect(() => {
    setAddedPopoverActive(false);
    setRemovedPopoverActive(true);

    const removedTimeout = window.setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setRemovedPopoverActive(false);
    }, 2500);

    return () => {
      clearTimeout(removedTimeout);
    };
  }, [recentlyRemoved]);

  // Allow skipping based on issue TIA-50
  // if (!selectedItems.length) {
  //   return null;
  // }

  return (
    <StatusBarRoot>
      {!!recentlyAdded && (
        <Popover type="added" aria-hidden ref={addedPopoverRef} active={addedPopoverActive}>
          <PopoverItem key={`added-${recentlyAdded?.id}`} aria-live="polite">
            <Box display="flex" mb="1rem" flexShrink={0} alignItems="center">
              <Icon name="ic-success" size={24} flexShrink={0} />
              <Fineprint ml={16}>{`${recentlyAdded?.label} successfully selected.`}</Fineprint>
            </Box>
            <Fineprint ml={40}>Select another iwi or proceed to the next step.</Fineprint>
          </PopoverItem>
        </Popover>
      )}
      {!!recentlyRemoved && (
        <Popover type="removed" aria-hidden ref={removedPopoverRef} active={removedPopoverActive}>
          <PopoverItem key={`removed-${recentlyRemoved?.id}`} aria-live="polite">
            <Box display="flex" flexShrink={0} alignItems="center">
              <Icon name="ic-info-blue" size={24} flexShrink={0} />
              <Fineprint ml={16}>{`${recentlyRemoved?.label} successfully removed.`}</Fineprint>
            </Box>
          </PopoverItem>
        </Popover>
      )}
      <StatusInfo>
        <Body2 m={0} role="status">
          {selectedItems.length} iwi given
          <TransparentButton type="button" onClick={() => showDialog(true)}>
            <Body2 fontWeight={700} color="pango">
              &nbsp;(view)
              <ScreenReaderOnly>
                {' '}
                selected <MaoriLanguage>iwi</MaoriLanguage>
              </ScreenReaderOnly>
            </Body2>
          </TransparentButton>
        </Body2>

        {selectedItems.length > 0 ? (
          <Button label="Next" type="submit" data-cy="next-button" />
        ) : (
          <Button label="Skip" type="submit" data-cy="skip-button" />
        )}
      </StatusInfo>
    </StatusBarRoot>
  );
};

const Popover = styled.div<{ active: boolean; type: 'removed' | 'added' }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: absolute;
  bottom: -100%;
  padding: 12px 16px;

  transition-property: all;
  transition-timing-function: ease-out;
  transition-duration: 0.5s;

  ${(props) =>
    props.active &&
    css`
      bottom: 100%;
    `}

  ${(props) =>
    props.type === 'removed'
      ? css`
          background: linear-gradient(0deg, rgba(18, 105, 218, 0.1), rgba(18, 105, 218, 0.1)), #fffdfb;
        `
      : css`
          background: linear-gradient(0deg, rgba(109, 131, 60, 0.3), rgba(109, 131, 60, 0.3)), #fffdfb;
        `};

  ${up('md')} {
    padding: 12px 24px;
  }
`;

const PopoverItem = styled.div`
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
  flex-direction: column;
  flex-basis: 100%;

  svg {
    flex-shrink: 0;
  }
`;

const StatusInfo = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  z-index: 10;
  ${up('md')} {
    padding: 16px 32px;
  }
`;

const StatusBarRoot = styled.div`
  /* pointer-events: none; */
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.pango};
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  height: 88px;
  max-height: 88px;
  flex-grow: 0;
  box-sizing: border-box;

  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;

  button {
    width: auto;
  }

  ${up('md')} {
    position: sticky;
  }

  // This is to prevent the _custom entry_ offer
  // to go over the top of the next button.
  // See issue https://alphero.sifterapp.com/issues/28910
  // for more details
  z-index: 100;
`;

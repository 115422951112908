import React, { PropsWithChildren } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

export const FormStepActions = ({ children }: PropsWithChildren<any>) => (
  <FormStepActionsWrapper>{children}</FormStepActionsWrapper>
);

export const FormStepActionsWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${up('md')} {
    margin-top: 0;
    padding: 0px;
  }
`;

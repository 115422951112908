import { recordCustomEvents, resetEvents } from '@/components/Analytics/RecordEvents';
import { Box } from '@/components/Box/Box';
import { AlternativeLayout } from '@/components/Layouts/Layouts';
import { Message, MessageType } from '@/components/Message/Message';
import { Quote } from '@/components/Quote/Quote';
import { useAuth } from '@/features/auth';

export const Step6Confirmation = () => {
  const { isAuthenticated } = useAuth();
  recordCustomEvents('iwi_submitted', { isAuthenticated: isAuthenticated, iwi_submitted: true });
  resetEvents();
  return (
    <AlternativeLayout>
      <Message type={MessageType.SUCCESS} title="Ngā mihi." message="Their details have been submitted successfully">
        <Box mb={32}>
          <Quote color="white" cite="Māori participant in Tātai research" hideIcon>
            “Power in numbers, more people in decision making &amp; strategic positions can support the desired
            outcomes”
          </Quote>
        </Box>
      </Message>
    </AlternativeLayout>
  );
};

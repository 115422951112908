import React from 'react';

import { Box } from '../Box/Box';

export const Container2 = ({ children }: React.PropsWithChildren<unknown>) => (
  <Box
    display="flex"
    flexShrink={0}
    flexGrow={0}
    flexDirection="column"
    alignItems="center"
    width="100%"
    m="0 auto"
    p={{ _: '0 24px', md: '0 272px', xxl: '0 272px' }}
  >
    {children}
  </Box>
);

Container2.displayName = 'Container2';

export const Container3 = ({ children }: React.PropsWithChildren<unknown>) => (
  <Box
    display="flex"
    flexShrink={0}
    flexGrow={0}
    flexDirection="column"
    alignItems="center"
    width="100%"
    m="0 auto"
    p="0 34px"
  >
    {children}
  </Box>
);

Container3.displayName = 'Container3';
export const Container4 = ({ children }: React.PropsWithChildren<unknown>) => (
  <Box
    display="flex"
    flexShrink={0}
    flexGrow={0}
    flexDirection="column"
    alignItems="center"
    width="100%"
    m="0 auto"
    p={{ _: '0 24px', md: '0 237px', lg: '0 272px', xl: '0 272px', xxl: '0 272px' }}
  >
    {children}
  </Box>
);

Container4.displayName = 'Container4';

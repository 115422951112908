import React, { forwardRef } from 'react';
import { useHover, mergeProps, usePress } from 'react-aria';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';
import { space, SpaceProps, layout, LayoutProps } from 'styled-system';

import { Box } from '../Box/Box';
import Icon from '../Icon/Icon';
import { ButtonPrint } from '../Typography/Typography';

// import ImageButtonBackground from '../../public/button-background.svg';
// import ImageButtonBackgroundActive from '../../public/button-background-active.svg';

type ButtonVariant = 'dark' | 'light';

interface IButtonProps extends SpaceProps, LayoutProps, React.HTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  type: 'button' | 'submit' | 'reset';
  label: string;
  dataCy?: string;
  icon?: any;
}

interface ILinkButtonProps extends SpaceProps, LayoutProps, React.HTMLAttributes<HTMLAnchorElement> {
  variant?: ButtonVariant;
  to: string;
  label: string;
  dataCy?: string;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const { label, variant = 'dark', dataCy, icon, ...otherProps } = props;
  const { hoverProps } = useHover({});

  const combinedProps = mergeProps(otherProps, hoverProps);

  return (
    <ButtonRoot ref={ref} variant={variant} data-cy={dataCy} {...combinedProps}>
      <ButtonBackground variant={variant} />
      <Box position="relative" display="flex" flexShrink={0} flexGrow={0}>
        <ButtonPrint as="span">
          {label} {icon && <StyledImage src={icon} alt="External Link" />}
        </ButtonPrint>
      </Box>
    </ButtonRoot>
  );
});

Button.displayName = 'Button';

export const LinkButton = forwardRef<HTMLAnchorElement, ILinkButtonProps>((props, ref) => {
  const { label, to, variant = 'dark', dataCy, ...otherProps } = props;
  const { hoverProps } = useHover({});
  const { pressProps } = usePress({});

  const combinedProps = mergeProps(otherProps, pressProps, hoverProps);

  return (
    <ButtonRoot variant={variant} as={Link} to={to} ref={ref} data-cy={dataCy} {...combinedProps}>
      <ButtonBackground variant={variant} />
      <Box position="relative" zIndex={20}>
        <ButtonPrint as="span">{label}</ButtonPrint>
      </Box>
    </ButtonRoot>
  );
});

const ButtonBackground = (props: { variant: ButtonVariant }) => (
  <Box
    size="100%"
    display="flex"
    flexGrow={0}
    flexShrink={0}
    flexDirection="row"
    position="absolute"
    zIndex={5}
    left={0}
    top={0}
    bottom={0}
    right={0}
    aria-hidden="true"
  >
    <Box width={14} position="absolute" left="0" top="0" bottom="0">
      <Icon name="ic-button-left" height="100%" />
    </Box>
    <ButtonMiddle variant={props.variant}>
      <IconNoStroke width="100%" name="ic-button-center" height="100%" />
    </ButtonMiddle>
    <Box width={14} position="absolute" right="0" top="0" bottom="0">
      <Icon name="ic-button-right" height="100%" />
    </Box>
  </Box>
);

// export const ExternalButtonLink = (props, ref) => {
//   const { label, to, variant = 'dark', dataCy, ...otherProps } = props;
//   return (
//     <button {...props}>
//       <img src={externalLink} alt="External Link" />
//       {children}
//     </button>
//   );
// };

const ButtonMiddle = styled(Box)<{ variant: ButtonVariant }>`
  position: absolute;
  left: 8px;
  right: 8px;
  top: 0;
  bottom: 0;

  flex-grow: 1;
  z-index: 5;

  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.colors.pango};

  /* Remove border if dark theme. */
  ${(props) => props.variant === 'dark' && 'border: none;'}
`;

const IconNoStroke = styled(Icon)`
  svg {
    stroke: none !important;
  }
`;

LinkButton.displayName = 'LinkButton';

const ButtonRoot = styled.button<{ variant: ButtonVariant }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 56px;
  padding: 13px 45px;
  max-width: 280px;
  cursor: pointer;
  border: 0;
  background: 0;
  border-radius: 4px;
  overflow: hidden;
  appearance: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

  ${space};
  ${layout};

  span {
    z-index: 20;
    white-space: nowrap;
  }

  svg {
    stroke-width: 2px;
  }

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${(props) => props.theme.colors.pango};

      svg {
        /* stroke-width: px; */
        fill: ${(props) => props.theme.colors.white};
        stroke: ${(props) => props.theme.colors.pango};
      }

      :hover {
        svg {
          fill: #ddd;
        }
      }

      :active {
        svg {
          fill: ${(props) => props.theme.colors.white};
        }
      }
    `};

  ${({ variant }) =>
    variant === 'dark' &&
    css`
      color: ${(props) => props.theme.colors.white};

      svg {
        fill: ${(props) => props.theme.colors.pango};
        stroke: ${(props) => props.theme.colors.pango};
      }

      :hover {
        svg {
          fill: #666;
          stroke: #666;
        }
      }

      :active {
        svg {
          fill: ${(props) => props.theme.colors.pango};
          stroke: ${(props) => props.theme.colors.pango};
        }
      }
    `};
`;

const StyledImage = styled.img`
  vertical-align: top;
  ${up('md')} {
    margin-left: 16px;
  }
`;

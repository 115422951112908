import { isAfter, isBefore, isValid, subYears } from 'date-fns';

const DEFAULT_SEPARATOR = '/';
const DATE_EXPECTED_LENGTH = 10;

export const formatInputAsDate = (value: string, separator = DEFAULT_SEPARATOR) => {
  const numbersOnly = value.replace(/[^0-9\\.]+/g, '');
  const date = numbersOnly.substring(0, 2);
  const month = numbersOnly.substring(2, 4);
  const year = numbersOnly.substring(4, 8);

  if (date && month && year) {
    return `${date}${separator}${month}${separator}${year}`;
  }

  if (date && month) {
    return `${date}${separator}${month}`;
  }
  return date;
};

export const formatStringForDateParse = (value: string) =>
  formatInputAsDate(value).split(DEFAULT_SEPARATOR).reverse().join(DEFAULT_SEPARATOR);

export const isValidDate = (value?: string) => {
  if (!value) {
    return false;
  }

  const valueWithoutUnderscores = value.replace(/_/g, '');
  if (valueWithoutUnderscores.length < DATE_EXPECTED_LENGTH) {
    return false;
  }

  const valueAsDateArray = formatStringForDateParse(value);
  const valueAsDate = new Date(valueAsDateArray);

  if (!isValid(valueAsDate)) {
    return false;
  }

  const now = new Date();

  return isAfter(valueAsDate, subYears(now, 120)) && isBefore(valueAsDate, now);
};

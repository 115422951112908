import React, { useEffect, useRef } from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled, { css, keyframes } from 'styled-components';

import { useLandingContext } from '../LandingContextProvider/useLandingContext';

const BACKGROUND_IMAGE_MAPPINGS: {
  [key: string]: { small: string; large: string };
} = {
  pango: {
    small: '/backgrounds/background-pango-small.png',
    large: '/backgrounds/background-pango.jpg',
  },
  harakeke: {
    small: '/backgrounds/background-harakeke-small.png',
    large: '/backgrounds/background-harakeke.jpg',
  },
  papatuanuku: {
    small: '/backgrounds/background-papatuanuku-small.png',
    large: '/backgrounds/background-papatuanuku.jpg',
  },
};

export const AnimatedBackground = () => {
  const {
    sectionConfig: { backgroundColor },
  } = useLandingContext();

  const isDesktop = useBreakpoint(up('md'));
  const currentSize = isDesktop ? 'large' : 'small';

  const prevColorRef = useRef<string>();

  useEffect(() => {
    prevColorRef.current = backgroundColor;
  });

  const prevBackgroundColor = prevColorRef.current;

  if (prevBackgroundColor === backgroundColor) {
    return (
      <BackgroundBlockRoot
        data-cy="background-block-root"
        key={`${backgroundColor}`}
        backgroundColor={backgroundColor}
        backgroundImageUrl={BACKGROUND_IMAGE_MAPPINGS[backgroundColor][currentSize]}
        active={true}
      />
    );
  }

  return (
    <>
      <BackgroundBlockRoot
        data-cy="background-block-root"
        key={`${backgroundColor}`}
        backgroundColor={backgroundColor}
        backgroundImageUrl={BACKGROUND_IMAGE_MAPPINGS[backgroundColor][currentSize]}
        active={true}
      />

      {!!prevBackgroundColor && (
        <BackgroundBlockRoot
          data-cy="background-block-root-prev"
          key={`${prevBackgroundColor}`}
          backgroundColor={prevBackgroundColor}
          backgroundImageUrl={BACKGROUND_IMAGE_MAPPINGS[prevBackgroundColor][currentSize]}
          active={false}
        />
      )}
    </>
  );
};

const fadeOut = keyframes`
  0% {
    opacity: 1;
  } 
  100% {
    opacity: 0;
  }
`;

interface IBackgroundBlockRootProps {
  active: boolean;
  backgroundImageUrl: string;
  backgroundColor: string;
}

export const BackgroundBlockRoot = styled.div<IBackgroundBlockRootProps>`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors[props.backgroundColor]};
  background-image: url('${(props) => props.backgroundImageUrl}');
  background-size: cover;
  background-position: top center;
  z-index: -20;
  opacity: 1;

  ${(props) =>
    !props.active &&
    css`
      z-index: -19;
      animation: ${fadeOut} 0.4s linear forwards;
    `}
`;

import axios, { AxiosResponse } from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3002/api';
const API_KEY = process.env.REACT_APP_API_KEY || '';

const handleSuccess = <T>(response: AxiosResponse<T>) => response.data;

export const makePostRequest = async <T>(url: string, data: any, func: () => Promise<string | void> | undefined) => {
  console.log('Url is : ', process.env.REACT_APP_API_URL);

  if (func) {
    const token = await func();
    if (token) {
      return axios
        .post<T>(`${BASE_URL}/${url}`, data, { headers: { 'x-api-key': API_KEY, Authorization: `Bearer ${token}` } })
        .then(handleSuccess);
    } else {
      // throw Error('Unauthenticated');
      return axios.post<T>(`${BASE_URL}/${url}`, data, { headers: { 'x-api-key': API_KEY } }).then(handleSuccess);
    }
  } else {
    axios.post<T>(`${BASE_URL}/${url}`, data, { headers: { 'x-api-key': API_KEY } }).then(handleSuccess);
  }

  // const requestConfig = {
  //   headers: headers,
  // } as AxiosRequestConfig;
  // axios.post<T>(`${BASE_URL}/${url}`, data, requestConfig).then(handleSuccess);
};
export const makeGetRequest = async <T>(url: string, data?: any) =>
  axios
    .get<T>(`${BASE_URL}/${url}`, {
      data,
    })
    .then(handleSuccess);

export const makePatchRequest = async <T>(url: string, data: any, func: () => Promise<string | void> | undefined) => {
  if (func) {
    const token = await func();
    if (token) {
      return axios
        .patch<T>(`${BASE_URL}/${url}`, data, { headers: { 'x-api-key': API_KEY, Authorization: `Bearer ${token}` } })
        .then(handleSuccess);
    }
  }
};
export const makeDeleteRequest = async <T>(url: string, func: () => Promise<string | void> | undefined) => {
  if (func) {
    const token = await func();
    if (token) {
      return axios
        .delete<T>(`${BASE_URL}/${url}`, { headers: { 'x-api-key': API_KEY, Authorization: `Bearer ${token}` } })
        .then(handleSuccess);
    }
  }
};

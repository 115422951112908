import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import { recordEvents } from '@/components/Analytics/RecordEvents';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { ScrollToTopButton } from '@/components/Button/ScrollToTopButton';
import { Container } from '@/components/Container/Container';
import { FooterNavigation } from '@/components/FooterNavigation/FooterNavigation';
import { GlobalHeader } from '@/components/GlobalHeader/GlobalHeader';
import { Head } from '@/components/Head/Head';
import { useLandingContext } from '@/components/LandingContextProvider/useLandingContext';
import { DefaultLayout } from '@/components/Layouts/Layouts';
import { Radio } from '@/components/Radio/Radio';
import { Body2, H2 } from '@/components/Typography/Typography';
import { useAuth } from '@/features/auth';
import { AffiliationForData, WHOTHISAFFILIATIONFOR } from '@/features/iwiAffiliationForm';
import { Navigation } from '@/features/iwiAffiliationForm/components/Navigation';
import { defaultTheme } from '@/styles/themes/default';

export const AffiliationFor = () => {
  const { isAuthenticated } = useAuth();
  const {
    sectionConfig: { textColor },
  } = useLandingContext();

  /* We refocus the main title when using the back to top button */
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  const isDesktop = useBreakpoint(up('md'));

  const navigate = useNavigate();
  const { register } = useForm<AffiliationForData>();

  let whoThisAffiliationForProps = register('mySelf', { required: true });
  let whoIsThisAffiliationFor;

  const isaffiliationFor = (e: any) => {
    whoIsThisAffiliationFor = e.target.value;
  };

  const handleClick = () => {
    if (whoIsThisAffiliationFor === WHOTHISAFFILIATIONFOR.ON_BEHALF_OF) {
      if (isAuthenticated) {
        recordEvents({ submission_on_behalf_of: true });
        navigate('/behalf-of-form', { state: { affiliationfor: WHOTHISAFFILIATIONFOR.ON_BEHALF_OF } });
      } else {
        navigate('/signin', { state: { affiliationfor: WHOTHISAFFILIATIONFOR.ON_BEHALF_OF } });
      }
    } else if (whoIsThisAffiliationFor === WHOTHISAFFILIATIONFOR.MY_SELF) {
      if (isAuthenticated) {
        recordEvents({ submission_myself: true });
      } else {
        recordEvents({ no_sign_in_myself: true });
      }
      navigate('/form', { state: { affiliationfor: WHOTHISAFFILIATIONFOR.MY_SELF } });
    }
  };
  const redirectedFrom = window.history.state.usr?.redirectedFrom;

  return (
    <>
      <Head />
      <Wrapper>
        <GlobalHeader variantOverride="dark" />
        <DefaultLayout>
          <Container>
            <Navigation
              goToPreviousStep={() =>
                // !isAuthenticated
                redirectedFrom === 'signin' || !isAuthenticated
                  ? navigate('/signin', { state: { previousStep: 'signin' } })
                  : navigate('/linked-account', { state: { previousStep: 'signin' } })
              }
              labelled-by="affiliation-for"
              padding="0px"
              marginTop={{ _: '72px' }}
            >
              <Box color={textColor} display="flex" flexDirection="column" alignItems="center" mt={{ md: 32 }}>
                <Box width="100%">
                  <StyledHeading>Who is this iwi affiliation for? </StyledHeading>
                </Box>
                <Body2
                  mb={{ _: '16px', md: '32px' }}
                  mt={{ _: '16px', md: '32px' }}
                  textAlign={{ _: 'left', md: 'center' }}
                >
                  You can complete an iwi affiliation for another person with their permission.
                </Body2>
                <RadioGroup>
                  <Radio
                    data-cy="mySelf"
                    label="Myself"
                    id="myself"
                    value={WHOTHISAFFILIATIONFOR.MY_SELF}
                    {...whoThisAffiliationForProps}
                    onBlur={whoThisAffiliationForProps.onBlur}
                    onChange={isaffiliationFor}
                  />
                  <Radio
                    data-cy="onBehalfOF"
                    label="Someone I know who has given me their permission"
                    id="onBehalfOf"
                    value={WHOTHISAFFILIATIONFOR.ON_BEHALF_OF}
                    {...whoThisAffiliationForProps}
                    onBlur={whoThisAffiliationForProps.onBlur}
                    onChange={isaffiliationFor}
                  />
                </RadioGroup>

                <Button
                  type="button"
                  mt="64px"
                  maxWidth="101px"
                  label="Next"
                  onClick={handleClick}
                  data-cy="linked-account-next-button"
                  mb={{ _: '64px' }}
                />
              </Box>
            </Navigation>
          </Container>
        </DefaultLayout>
        {/* Footer navigation and back to top buttons */}
        <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }} zIndex={10} mt="auto">
          <FooterNavigation />

          {!isDesktop && (
            <Box mt={32} display="flex" justifyContent={{ _: 'center' }} flexShrink={0} flexGrow={0}>
              <ScrollToTopButton focusRef={mainTitleRef} />
            </Box>
          )}
        </Box>
      </Wrapper>
    </>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledHeading = styled(H2)`
  margin-top: 32px;
  text-align: left;
  ${up('md')} {
    text-align: center;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  width: 100%;
  max-width: 598px;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  color: ${defaultTheme.colors.pango};

  label:last-of-type {
    margin-bottom: 0;
  }
`;

import { SyntheticEvent } from 'react';
import { useVisuallyHidden } from 'react-aria';
import styled from 'styled-components';

import { useIwiAffiliationData } from '../hooks/useIwiAffiliationData';

import { IdToLabel } from '@/tools/constants/iwi';

interface IRegionSelectProps {
  region?: IdToLabel;
  onRegionSelect: (region?: IdToLabel) => void;
}
export const RegionSelect = ({ region, onRegionSelect }: IRegionSelectProps) => {
  const { regions } = useIwiAffiliationData();
  const { visuallyHiddenProps } = useVisuallyHidden();

  const handleRegionSelect = (e: SyntheticEvent<HTMLSelectElement>) => {
    const selectedRegionId = e.currentTarget.value;

    if (!selectedRegionId) {
      onRegionSelect();
      return;
    }

    const selectedRegion = regions?.find((region: IdToLabel) => region.id === selectedRegionId);

    if (!selectedRegion) {
      onRegionSelect();
      return;
    }

    onRegionSelect(selectedRegion);
  };

  return (
    <SelectWrapper>
      <label {...visuallyHiddenProps} htmlFor="label_region-select">
        Select region if known
      </label>
      <Select
        onBlur={handleRegionSelect}
        onChange={handleRegionSelect}
        placeholder="Select region (if known)"
        value={region ? region.id : ''}
        style={{ width: '100%' }}
      >
        <option value="">Select region (if known)</option>
        {regions?.map((region: IdToLabel) => (
          <option key={region.id} value={region.id}>
            {region.label}
          </option>
        ))}
      </Select>
    </SelectWrapper>
  );
};

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  :after {
    pointer-events: none;
    content: '';
    display: inline-block;
    background-image: url('/icons/ic-select-down.svg');
    background-repeat: no-repeat;
    width: 16px;
    height: 12px;
    background-size: 16px 12px;
    position: absolute;
    right: 16px;
    transform: translateY(-25%);
  }
`;

const Select = styled.select`
  appearance: none;
  text-overflow: ellipsis;
  background: ${(props) => props.theme.colors.ma};
  border: 1px solid rgba(191, 84, 34, 0.5);
  border-radius: 4px;
  padding: 8px 45px 8px 16px;
  height: 56px;
  margin-bottom: 8px;
  color: black;

  font-family: Poppins;
  font-size: 1rem;
  font-size: clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.25)), 1.25rem);
`;

import Ga4React from 'ga-4-react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import { initMocks } from './features/mocks';
import reportWebVitals from './reportWebVitals';

window.APP_VERSION = process.env.REACT_APP_VERSION;

const ga = new Ga4React(process.env.REACT_APP_GA_ID || '');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  await ga.initialize();
  await initMocks();

  root.render(<App />);
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

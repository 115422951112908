import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BoldA = styled(Link)`
  font-weight: 700;
  text-decoration: underline;
`;

export const LightA = styled(Link)`
  font-weight: 500;
  text-decoration: underline;
`;

import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';

import { recordEvents } from '../Analytics/RecordEvents';

import { useAuth } from '@/features/auth';

interface IHeadConfig {
  title: string;
}
interface IPageHeadConfig {
  [keyof: string]: IHeadConfig;
}

const DEFAULT_PAGE_CONFIG = {
  title: 'Tātai - Share your iwi',
};

const PAGES_CONFIG: IPageHeadConfig = {
  '/': DEFAULT_PAGE_CONFIG,
  '/signin': {
    title: 'Tātai | Sign in',
  },
  '/who-is-this-iwi-affiliation-for': {
    title: 'Tātai | Who is this for?',
  },
  '/form': {
    title: 'Tātai | Form',
  },
  '/about': {
    title: 'Tātai | Whakapapa (About)',
  },
  '/benefits': {
    title: 'Tātai | Whāinga (Benefits)',
  },
  '/questions': {
    title: 'Tātai | Pātai (Questions)',
  },
  '/terms-of-use': {
    title: 'Tātai | Terms of use',
  },
  '/privacy-policy': {
    title: 'Tātai | Privacy policy',
  },
  '/manage': {
    title: 'Tātai | View and manage your iwi',
  },
  '/edit-iwi-map': {
    title: 'Tātai | Edit iwi map',
  },
  '/behalf-of-form': {
    title: 'Tātai | Submission on behalf of',
  },
  '/delete-account': {
    title: 'Tātai | Delete account',
  },
  '/edit-details': {
    title: 'Tātai | Edit details',
  },
  '/confirm-delete-account': {
    title: 'Tātai | Confirm delete account',
  },
};

export const Head = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const headConfig = {
    ...DEFAULT_PAGE_CONFIG,
    ...PAGES_CONFIG[location.pathname],
  };
  useEffect(() => {
    recordEvents({ path: location.pathname, title: headConfig.title, isAuthenticated: isAuthenticated });
  }, [location.pathname, headConfig.title, isAuthenticated]);
  return (
    <Helmet>
      <title>{headConfig.title}</title>
      <meta property="og:title" content={headConfig.title} />
    </Helmet>
  );
};

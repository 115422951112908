import { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { Box } from '@/components/Box/Box';
import { Button, LinkButton } from '@/components/Button/Button';
import { Container } from '@/components/Container/Container';
import { FooterNavigation } from '@/components/FooterNavigation/FooterNavigation';
import { GlobalHeader } from '@/components/GlobalHeader/GlobalHeader';
import { Head } from '@/components/Head/Head';
import { ErrorBanner } from '@/components/InputError/InputError';
import { useLandingContext } from '@/components/LandingContextProvider/useLandingContext';
import { DesignedLayoutBackground } from '@/components/Layouts/Layouts';
import { Body2, H1, H2 } from '@/components/Typography/Typography';
import { useAccessTokenPromise } from '@/features/auth';
import { Navigation } from '@/features/iwiAffiliationForm/components/Navigation';
import { makeDeleteRequest } from '@/tools/ajax';

const API_PATH = process.env.REACT_APP_DELETE_IWI_API_PATH;
interface ResponseObj {
  message: string;
  correlationId?: string;
  errorObj?: object;
  status?: string;
  id?: string;
}

export const DeleteData = () => {
  const name = window.history.state.usr?.name;
  const data = window.history.state.usr?.data;
  const [showDeleteError, setShowDeleteError] = useState<boolean>(false);
  const {
    sectionConfig: { textColor },
  } = useLandingContext();
  const navigate = useNavigate();
  const getAccessToken = useAccessTokenPromise();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteData = async () => {
    setIsLoading(true);
    const repsoneFromDeleteApi: ResponseObj = await makeDeleteRequest(API_PATH, getAccessToken);
    if (repsoneFromDeleteApi.message === 'Deleted Successfully') {
      setIsLoading(false);
      window.sessionStorage.setItem('optOut', 'true');
      window.sessionStorage.setItem('showDeleteToast', 'true');
      window.sessionStorage.removeItem('preferredName');
      navigate('/manage', { state: { status: 'Deleted Successfully' } });
    } else if (repsoneFromDeleteApi && repsoneFromDeleteApi.status && repsoneFromDeleteApi.status === 'error') {
      setShowDeleteError(true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Head />
      <DesignedLayoutBackground>
        <GlobalHeader variantOverride="dark" />

        <Container>
          <Navigation
            goToPreviousStep={() =>
              navigate('/edit-details', { state: { previousStep: 'confirm-delete', name: name, data: data } })
            }
            labelled-by="confirm-delete-account"
            title="Update account details"
            padding="0px"
            marginTop={{ _: '115px' }}
            minHeight={{ _: 'calc(100vh - 350px)', lg: 'calc(100vh - 215px)' }}
            display="block"
            width="100%"
            maxWidth="600px"
          >
            <Box width="100%" textAlign="left">
              <H1 color={textColor} mt={{ _: 24, md: 75 }}>
                Delete data
              </H1>
            </Box>
            <Box width="100%" pb={{ _: 120, md: 200 }} display="block" flexShrink={0} flexGrow={0} color={textColor}>
              <Box width="100%" mt={{ _: '32px' }}>
                <H2>Confirm deletion</H2>
                <Body2 mt={{ _: '16px', md: '32px' }} mb="8px" fontWeight={700}>
                  Name
                </Body2>
                <Body2>{name}</Body2>
                <Body2 mt={{ _: '16px', md: '32px' }}>
                  Are you sure you want to delete your iwi affiliations and health information from Tātai&apos;s
                  records?
                </Body2>
                <Body2 mt={{ _: '16px', md: '32px' }}>This step cannot be undone.</Body2>
                {showDeleteError && (
                  <Box mb={32} mt="16px">
                    <ErrorBanner>
                      Oops! That didn’t work.
                      <br />
                      An error occurred while processing your record. Please try again, and if the error persists, email
                      our support team at{' '}
                      <StyledAnchorTag href="mailto:contact@tatai.maori.nz">
                        contact@tatai.maori.nz
                      </StyledAnchorTag>{' '}
                      with details of what you were attempting to do.
                    </ErrorBanner>
                  </Box>
                )}
                <Box mt="32px">
                  <LinkButton
                    label="Cancel"
                    to="/manage"
                    data-cy="cancel-button"
                    maxWidth="103px"
                    variant="light"
                    mr="16px"
                  />
                  {isLoading ? (
                    <Button type="button" label="Loading..." maxWidth="125px" />
                  ) : (
                    <Button
                      type="button"
                      label="Delete"
                      onClick={deleteData}
                      data-cy="confirm-delete-account-button"
                      maxWidth="103px"
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Navigation>
        </Container>

        <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }}>
          <FooterNavigation />
        </Box>
      </DesignedLayoutBackground>
      <p>Hello</p>
    </>
  );
};

export const StyledAnchorTag = styled.a`
  font-weight: 700 !important;
  margin-right: 3px;
`;

import React, { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';

import { Box } from '../Box/Box';
import { LinkButton } from '../Button/Button';
import { GlobalHeader } from '../GlobalHeader/GlobalHeader';
import { DefaultLayout } from '../Layouts/Layouts';
import { Body2 } from '../Typography/Typography';

type LegalPageWrapperProps = {
  children?: React.ReactNode;
};

export const LegalPageWrapper: React.FC<LegalPageWrapperProps> = ({ children }) => {
  return (
    <>
      <GlobalHeader variantOverride="dark" />
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems="center"
        height="100%"
        width="100%"
        pt={{ _: 80, md: 56 }}
        px={{ _: 24, md: 240 }}
      >
        <DefaultLayout>
          <Box maxWidth={689} width="100%" pb={36} flexGrow={1} display="flex" flexDirection="column">
            <Box alignSelf="flex-start" my={16} mt={{ md: 0 }} color="white">
              {children}
            </Box>
            <StyledLinkButton label="Back to home" to="/" />
          </Box>
        </DefaultLayout>
      </Box>
    </>
  );
};

const StyledLinkButton = styled(LinkButton)`
  margin-top: 2rem;
  align-self: center;
`;

export const LegalH2 = (props: ComponentPropsWithRef<'h2'>) => <StyledH2AsBody2 as="h2" {...props} />;

const StyledH2AsBody2 = styled(Body2)`
  margin: 1rem 0;
  font-weight: 700;
`;

export const LegalBody2 = styled(Body2)`
  margin: 1rem 0;
`;

/* eslint-disable jsx-a11y/no-autofocus */
import { FocusScope, usePreventScroll, VisuallyHidden } from 'react-aria';
import { up } from 'styled-breakpoints';
// import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import { Box } from '../../../components/Box/Box';
import { Button } from '../../../components/Button/Button';
import { TransparentButton } from '../../../components/Button/TransparentButton';
import Icon from '../../../components/Icon/Icon';
import { Body2, Fineprint } from '../../../components/Typography/Typography';
import { Iwi } from '../types';

import { FormStepActions } from './FormStepActions';

interface ISelectedIwiDialogProps {
  selectedItems: Iwi[];
  onItemSelect: (iwi: Iwi) => void;
  setSelectedItems: any;
  onClose: any;
}

export const SelectedIwiDialog = ({
  selectedItems,
  setSelectedItems,
  onItemSelect,
  onClose,
}: ISelectedIwiDialogProps) => {
  usePreventScroll();

  const removeAll = () => {
    setSelectedItems([]);
  };

  return (
    <>
      <DialogBackground></DialogBackground>
      <Dialog role="dialog" aria-modal="true" aria-labelledby="dialog_label">
        <FocusScope contain restoreFocus autoFocus>
          <DialogContentWrapper>
            <Box pb={16} borderBottom="1px solid rgba(191, 84, 34, 0.5);">
              <Body2 fontWeight={700}>Iwi given so far</Body2>
            </Box>
            <SelectedIwiList>
              {selectedItems.map((selectedItem) => (
                <SelectedIwiListItem key={selectedItem.id}>
                  <Box flexGrow={1}>
                    <Body2 fontWeight={700}>{selectedItem.label}</Body2>
                    <Fineprint>{selectedItem.region?.label}</Fineprint>
                  </Box>
                  <Box flexShrink={0} ml={16}>
                    <TransparentButton type="button" onClick={() => onItemSelect(selectedItem)}>
                      <Box backgroundColor="error" padding="6px" borderRadius="50%" width={24} height={24}>
                        <VisuallyHidden>Remove {selectedItem.label}</VisuallyHidden>
                        <Icon name="ic-close" size={12} color="white" />
                      </Box>
                    </TransparentButton>
                  </Box>
                </SelectedIwiListItem>
              ))}
            </SelectedIwiList>
            {selectedItems.length > 0 && (
              <Box mt="8px" display="flex" justifyContent="flex-end" alignContent="center" flexShrink={0} flexGrow={0}>
                <TransparentButton type="button" onClick={removeAll}>
                  <Body2 color="error" fontWeight={700}>
                    Remove all
                  </Body2>
                  <Box ml={20} backgroundColor="error" padding="6px" borderRadius="50%" width={24} height={24}>
                    <Icon name="ic-close" size={12} color="white" />
                  </Box>
                </TransparentButton>
              </Box>
            )}
            <Box
              position="absolute"
              py="8px"
              left="0"
              right="0"
              bottom="0"
              boxShadow="0px 4px 30px rgba(0, 0, 0, 0.25);"
              justifySelf="flex-end"
            >
              <FormStepActions>
                <Button type="button" onClick={onClose} label="Back to list" />
              </FormStepActions>
            </Box>
          </DialogContentWrapper>
        </FocusScope>
      </Dialog>
    </>
  );
};

const DialogBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 199;
`;

const Dialog = styled.div`
  border-radius: 4px 4px 0 0;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  padding-bottom: 72px;

  ${up('md')} {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    width: auto;
    margin: auto;
  }
`;

const DialogContentWrapper = styled.div`
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
`;

const SelectedIwiList = styled.ul``;

const SelectedIwiListItem = styled.li`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid rgba(191, 84, 34, 0.5);
`;

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { GlobalHeader } from '../components/GlobalHeader/GlobalHeader';
import { Head } from '../components/Head/Head';

import { IwiAffiliationForm } from '@/features/iwiAffiliationForm';

const Form = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}>
    <Head />

    <GlobalHeader variantOverride="dark" />

    <main>
      <IwiAffiliationForm />
    </main>
  </GoogleReCaptchaProvider>
);
export default Form;

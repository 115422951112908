import { useVisuallyHidden } from 'react-aria';
import { Link } from 'react-router-dom';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled, { css } from 'styled-components';

import { ReactComponent as TataiLogoMedium } from '../../assets/logos/Tatai-logo-medium.svg';
import { ReactComponent as TataiLogoSmall } from '../../assets/logos/tatai-logo-small.svg';
import { ReactComponent as TataiLogo } from '../../assets/logos/tatai-logo.svg';
import { BlockBackgroundVariant } from '../Block/Block';
import { Box } from '../Box/Box';
import { ButtonLink2 } from '../ButtonLink/ButtonLink2';
import { useLandingContext } from '../LandingContextProvider/useLandingContext';

import { useAuth } from '@/features/auth';

interface IGlobalHeaderProps {
  variantOverride?: BlockBackgroundVariant;
}

export const GlobalHeader = ({ variantOverride }: IGlobalHeaderProps) => {
  const { logout, isAuthenticated } = useAuth();
  const {
    sectionConfig: { blockBackgroundVariant },
  } = useLandingContext();
  const isDesktop = useBreakpoint(up('md'));
  const isLargeDesktop = useBreakpoint(up('xxl'));
  const { visuallyHiddenProps } = useVisuallyHidden();

  return (
    <Box
      as="header"
      top={0}
      left={0}
      width="100%"
      paddingTop={{ _: 16, md: 32 }}
      px={{ _: 24, md: 32 }}
      position={{ _: 'absolute', md: 'fixed' }}
      zIndex="10"
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Link to="/">
          <span {...visuallyHiddenProps}>Tātai</span>
          <LogoContainer data-cy="logo-container" currentBackgroundVariant={variantOverride ?? blockBackgroundVariant}>
            {/* {isDesktop ? <TataiLogo aria-hidden /> : <TataiLogoSmall />} */}

            {isLargeDesktop ? (
              <TataiLogo aria-hidden />
            ) : isDesktop ? (
              <TataiLogoMedium aria-hidden />
            ) : (
              <TataiLogoSmall />
            )}
          </LogoContainer>
        </Link>

        {isAuthenticated && (
          <Box>
            <ButtonLink2 onClick={logout} variant={blockBackgroundVariant === 'dark' ? 'dark' : 'light'}>
              Sign out
            </ButtonLink2>
          </Box>
        )}
        {/* {!isDesktop && !isAuthenticated && (
          <Box textAlign="end">
            <ButtonLink
              color="white"
              onClick={() => {
                login();
              }}
            >
              Sign in
            </ButtonLink>
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

const LogoContainer = styled.div<{ currentBackgroundVariant: BlockBackgroundVariant }>`
  fill: ${(props) => props.theme.colors.white};
  width: 100px;

  ${up('md')} {
    width: 150px;
  }

  ${({ currentBackgroundVariant }) =>
    currentBackgroundVariant === 'light' &&
    css`
      fill: ${(props) => props.theme.colors.pango};
    `}
`;

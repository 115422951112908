import { createGlobalStyle } from 'styled-components';

import { defaultTheme } from './themes/default';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  *::placeholder {
    color: ${defaultTheme.colors.placeholder};
  }

  html,
  body {  
    /* scroll-behavior: smooth; */
    padding: 0;
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
    min-height: 100vh;
  }

  /**
   * Background colours used to fix https://alphero.sifterapp.com/issues/28856
   */
  body {
    position: relative;
    background-color: white;
  }
  @media (prefers-color-scheme: dark) {
    body {
      background-color: black;
    }
  }

  main {
    position: relative;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .grecaptcha-badge {
    visibility: hidden;
    pointer-events: none;
  }
`;

export const defaultTheme = {
  colors: {
    papatuanuku: '#BF5422',
    harakeke: '#6D833C',
    ma: '#FFEFD8',
    maLight: '#FFFDFB',
    pango: '#333333',
    focus: '#1269DA',
    white: '#FFFDFB',
    error: '#D91811',
    errorBackground: 'rgba(217, 24, 17, 0.2)',
    placeholder: '#a9a9a9',
  },
  fonts: {
    heading: 'Quando',
    body: 'Poppins',
  },
  breakpoints: {
    xs: '375px',
    sm: '480px',
    md: '768px',
    lg: '1024px',
    lgJ: '1024px',
    xl: '1200px',
    xxl: '1400px',
  },
};

import format from 'date-fns/format';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm, Controller } from 'react-hook-form';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

import { useIwiAffiliationFormContext } from '../../hooks/useIwiAffiliationFormContext';
import { useSubmitIwiAffiliation } from '../../hooks/useSubmitIwiAffiliation';
import {
  Gender,
  GENDER_TO_LABEL,
  IAddressPayload,
  IsMaoriDescentResponses,
  IsProvidingAddressResponses,
  ISummaryData,
  KNOWS_IWI_TO_LABEL,
  MAORI_DESCENT_TO_LABEL,
} from '../../types';
import { FormStep } from '../FormStep';
import { FormStepActions } from '../FormStepActions';
import { FormSteps } from '../IwiAffiliationFormContext';

import { MaoriLanguage } from '@/components/Accessibility/MaoriLanguage';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { Checkbox } from '@/components/Checkbox/Checkbox';
import { FlowFooter } from '@/components/FlowFooter/FlowFooter';
import Icon from '@/components/Icon/Icon';
import { ErrorBanner } from '@/components/InputError/InputError';
import { DefaultLayout } from '@/components/Layouts/Layouts';
import { BoldA } from '@/components/Links/Links';
import { Body, Body2, ButtonPrint, H2 } from '@/components/Typography/Typography';
import { Iwi, IYourDetailsData, KnowsIwiResponses } from '@/features/iwiAffiliationForm';
import { formatStringForDateParse } from '@/tools/date';

export const Step5Summary = () => {
  const { goToNextStep, formData, goToPreviousStep } = useIwiAffiliationFormContext();
  const { isLoading, submitIwiAffiliation, showSubmissionError } = useSubmitIwiAffiliation({
    onSuccessOfSubmission: () => {
      if (!isLoading) {
        goToNextStep();
      }
    },
  });

  const { executeRecaptcha } = useGoogleReCaptcha();
  /**
   * Since we are grabbing the token right before submission to ensure
   * is still valid, we need an additional flag for the time it would take
   * for the token to be generated upon submission.
   */
  const [isAttemptingSubmission, setIsAttemptingSubmissions] = useState<boolean>(false);
  const [showRecaptchaError, setShowRecaptchaError] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ISummaryData>();

  const submitDetails = async () => {
    if (isLoading || isAttemptingSubmission) {
      return;
    }

    setIsAttemptingSubmissions(true);

    if (!executeRecaptcha) {
      console.log('Waiting for recaptcha to finish loading.');
      return;
    }

    let recaptchaToken;

    try {
      recaptchaToken = await executeRecaptcha('submit');
    } catch (error) {
      console.log('recaptcha error');
      setIsAttemptingSubmissions(false);
      setShowRecaptchaError(true);
    }

    const dataToSubmit = {
      ...formData,
      [FormSteps.SUMMARY]: {
        recaptchaToken,
      },
    };

    submitIwiAffiliation(dataToSubmit);
  };

  return (
    <Wrapper>
      <DefaultLayout>
        {/* to do, screen reader only info */}
        <FormStep
          goToPreviousStep={() => goToPreviousStep(formData)}
          onSubmit={handleSubmit(submitDetails)}
          labelled-by="label_formStep"
          pt="8px"
          mt={{ _: '65px', md: '144px' }}
        >
          <Box
            maxWidth={689}
            mt={{ _: 30, md: 55 }}
            width="100%"
            pb={36}
            flexGrow={1}
            display="flex"
            flexDirection="column"
            flexShrink={0}
          >
            <Box alignSelf="flex-start" my={16} mt={{ md: 0 }} color="white">
              <H2 id="label_formStep">Summary</H2>
            </Box>
            <Box mb={16}>
              <Body2 color="white">Check their details before submitting. You can edit them if needed.</Body2>
            </Box>

            <MaoriDescentSummary />
            <IwiLookupSummary />
            <YourDetailsSummary />
            <ContactDetailsSummary />

            <Controller
              name="agreeToPrivacyPolicy"
              control={control}
              rules={{ required: 'You must agree to the privacy policy' }}
              render={({ field: { value, ref, ...restOfField } }) => (
                <Checkbox
                  id="input_agreeToPrivacyPolicy"
                  error={errors.agreeToPrivacyPolicy}
                  checked={value || false}
                  ref={ref}
                  {...restOfField}
                >
                  <Box>
                    I understand that information I provide may be shared with the <MaoriLanguage>iwi</MaoriLanguage> I
                    select. It may also be used to create statistical information that government agencies and{' '}
                    <MaoriLanguage>iwi</MaoriLanguage> may use to support <MaoriLanguage>Māori</MaoriLanguage> health
                    and wellbeing.
                  </Box>
                  <Box mt={16}>
                    Read the{' '}
                    {/* target="_blank" so that they don't have to redo the whole form by accidentally changing the page */}
                    <BoldA to="/privacy-policy" target="_blank">
                      <MaoriLanguage>Tātai</MaoriLanguage> Privacy Policy
                    </BoldA>
                    .
                  </Box>
                </Checkbox>
              )}
            />

            <Box>
              {showSubmissionError && (
                <Box mb={32}>
                  <ErrorBanner>
                    There&apos;s been an error trying to submit their <MaoriLanguage>Iwi</MaoriLanguage> Affiliation.
                    Please try again later.
                  </ErrorBanner>
                </Box>
              )}
              {showRecaptchaError && (
                <Box mb={32}>
                  <ErrorBanner>
                    There&apos;s been an error trying to reach Google&apos;s recaptcha. Please try again.
                  </ErrorBanner>
                </Box>
              )}
              {executeRecaptcha && (
                <Box mt={{ _: 16, md: 0 }}>
                  <FormStepActions>
                    <Button
                      type="submit"
                      label={isLoading || isAttemptingSubmission ? 'Loading...' : 'Submit details'}
                    />
                  </FormStepActions>
                </Box>
              )}
            </Box>
          </Box>
        </FormStep>
      </DefaultLayout>
      <FlowFooter />
    </Wrapper>
  );
};

const MaoriDescentSummary = () => {
  const { goToStep, formData } = useIwiAffiliationFormContext();
  const stepData = formData[FormSteps.MAORI_DESCENT];

  return (
    <StepSummaryWrapper>
      <SummaryData
        hideBorder
        label="Are they of Māori descent?"
        value={MAORI_DESCENT_TO_LABEL[stepData.isMaoriDescent || IsMaoriDescentResponses.DONT_KNOW]}
        onEdit={() => goToStep(FormSteps.MAORI_DESCENT)}
      />
      {stepData.isMaoriDescent !== IsMaoriDescentResponses.DONT_KNOW && (
        <SummaryData
          label="Do you know the names of their iwi?"
          value={KNOWS_IWI_TO_LABEL[stepData.doesKnowIwi || KnowsIwiResponses.PARTIALLY]}
          onEdit={() => goToStep(FormSteps.MAORI_DESCENT)}
        />
      )}
    </StepSummaryWrapper>
  );
};

const IwiLookupSummary = () => {
  const { goToStep, formData } = useIwiAffiliationFormContext();
  const { affiliatedIwi } = formData[FormSteps.IWI_LOOKUP];

  return (
    <StepSummaryWrapper>
      <Box py={16} display="flex" justifyContent="space-between" flexShrink={0} flexGrow={0}>
        <Body as="h2">Their Iwi</Body>
        <EditButton type="button" onClick={() => goToStep(FormSteps.IWI_LOOKUP)}>
          <Icon size={20} name="ic-edit" mr="4px" />
          <ButtonPrint fontFamily="Poppins" fontWeight={700} fontSize={14}>
            Edit
          </ButtonPrint>
        </EditButton>
      </Box>
      {!affiliatedIwi ||
        (!affiliatedIwi.length && (
          <Box py={16} borderTop="1px solid rgba(191, 84, 34, 0.5)">
            <Body2>Not provided</Body2>
          </Box>
        ))}
      {affiliatedIwi &&
        affiliatedIwi.length > 0 &&
        affiliatedIwi.map((iwi: Iwi) => (
          <SummaryData key={iwi.id} label={iwi.label} value={iwi.region ? iwi.region.label : ''} />
        ))}
    </StepSummaryWrapper>
  );
};

const formatName = ({ firstName, middleName, lastName }: IYourDetailsData) => {
  return [firstName, middleName, lastName].filter((nameFragment?: string) => !!nameFragment).join(' ');
};

interface IGenderSummaryProps {
  gender?: Gender;
  genderAsStated?: string;
}

const GenderSummary = ({ gender, genderAsStated }: IGenderSummaryProps) => {
  if (!gender) {
    return <SummaryData label="Gender" value="Not provided" />;
  }

  if (gender === Gender.ANOTHER_GENDER) {
    return <SummaryData label="Gender" value={genderAsStated} />;
  }

  return <SummaryData label="Gender" value={GENDER_TO_LABEL[gender]} />;
};

const YourDetailsSummary = () => {
  const { goToStep, formData } = useIwiAffiliationFormContext();
  const stepData = formData[FormSteps.YOUR_DETAILS];

  const nameFormatted = formatName(stepData);
  const dateOfBirthFormatted = stepData.dateOfBirth
    ? format(new Date(formatStringForDateParse(stepData.dateOfBirth)), 'd MMM yyyy')
    : '';

  return (
    <StepSummaryWrapper>
      <Box py={16} display="flex" justifyContent="space-between" flexShrink={0} flexGrow={0}>
        <Body as="h2">Their details</Body>
        <EditButton type="button" onClick={() => goToStep(FormSteps.YOUR_DETAILS)}>
          <Icon size={20} name="ic-edit" mr="4px" />
          <ButtonPrint fontFamily="Poppins" fontWeight={700} fontSize={14}>
            Edit
          </ButtonPrint>
        </EditButton>
      </Box>
      <SummaryData label="NHI number" value={stepData.nhiNumber || 'Not provided'} />
      <SummaryData label="Name" value={nameFormatted} />
      <SummaryData label="Date of Birth" value={dateOfBirthFormatted} />
      <SummaryData label="Address" value={getAddressSummary(stepData.isProvidingAddress, stepData.address)} />
      <GenderSummary gender={stepData.gender} genderAsStated={stepData.genderAsStated} />
    </StepSummaryWrapper>
  );
};

function getAddressSummary(isProvidingAddress: IsProvidingAddressResponses | undefined, address: IAddressPayload) {
  if (isProvidingAddress === IsProvidingAddressResponses.NO) {
    return 'Not provided';
  }

  return formatAddress(address);
}

/**
 * Formats the address like so:
 * Address format: '[Line 1], [Line 2], [Suburb], [City] [Postcode]'
 * Note the missing comma between the city and postcode.
 */
export function formatAddress({ addressLine1, addressLine2, suburb, city, postcode }: IAddressPayload): string {
  // filter(Boolean) filters out falsy values like '' and undefined
  const allButPostcode = [addressLine1, addressLine2 === suburb ? '' : addressLine2, suburb, city].filter(Boolean);

  return allButPostcode.join(', ') + ' ' + postcode;
}

const ContactDetailsSummary = () => {
  const { goToStep, formData } = useIwiAffiliationFormContext();
  const stepData = formData[FormSteps.CONTACT_DETAILS];

  return (
    <StepSummaryWrapper>
      <Box py={16} display="flex" justifyContent="space-between" flexShrink={0} flexGrow={0}>
        <Body as="h2">Contact details</Body>
        <Box ml="8px">
          <EditButton type="button" onClick={() => goToStep(FormSteps.CONTACT_DETAILS)}>
            <Icon size={20} name="ic-edit" mr="4px" />
            <ButtonPrint fontFamily="Poppins" fontWeight={700} fontSize={14}>
              Edit
            </ButtonPrint>
          </EditButton>
        </Box>
      </Box>
      {stepData.email && <SummaryData label="Email" value={stepData.email} />}
      {stepData.phone && <SummaryData label="Phone number" value={stepData.phone} />}
    </StepSummaryWrapper>
  );
};

interface ISummaryDataItem {
  label: string;
  value?: string;
  onEdit?: () => void;
  hideBorder?: boolean;
}

const SummaryData = ({ label, value, onEdit, hideBorder }: ISummaryDataItem) => {
  return (
    <Box py={16} borderTop={hideBorder ? 'none' : '1px solid rgba(191, 84, 34, 0.5)'}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" flexShrink={0} flexGrow={0}>
        <Body>{label}</Body>
        {onEdit && (
          <EditButton type="button" onClick={onEdit}>
            <Icon size={20} name="ic-edit" mr="4px" />
            <ButtonPrint fontFamily="Poppins" fontWeight={700} fontSize={14}>
              Edit
            </ButtonPrint>
          </EditButton>
        )}
      </Box>
      <Body2>{value}</Body2>
    </Box>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const EditButton = styled.button`
  background: 0;
  margin: 0;
  border: 0;
  padding: 0;
  padding-left: 12px;
  margin-top: 2px;

  display: inline-flex;
  align-items: center;

  cursor: pointer;
`;

const StepSummaryWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.ma};
  border: 1px solid rgba(191, 84, 34, 0.5);
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 0 16px;

  ${up('md')} {
    margin-bottom: 32px;
    padding: 0 24px;
  }
`;

import Autocomplete from 'accessible-autocomplete/react';
import { forwardRef, HTMLAttributes, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { makeGetRequest } from '../../tools/ajax';
import { ButtonLink } from '../ButtonLink/ButtonLink';
import { InputLabel } from '../TextInput/TextInput';
import { Body2 } from '../Typography/Typography';

import { IAddressPayload } from '@/features/iwiAffiliationForm';

interface IAddressSuggestion {
  UniqueId: string;
  FullAddress: string;
  SourceDesc: string;
}

interface IAdressESAMResponse {
  AddressLine1: string;
  AddressLine2: string;
  Suburb: string;
  City: string;
  Postcode: string;
  EsamId: string;
}

interface IAddressAutocompleteProps extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
  hasError?: boolean;
  onChange: (address: IAddressPayload) => void;
  onManualEntry: () => void;
}

export const AutoComplete = forwardRef<HTMLInputElement, IAddressAutocompleteProps>(
  ({ onChange, onManualEntry, hasError = false, ...props }, ref) => {
    const getRichAddress = useCallback(
      async (a: IAddressSuggestion) => {
        if (a) {
          const richAddress: IAdressESAMResponse = await makeGetRequest(`/address-details?addressId=${a.UniqueId}`);

          onChange({
            addressLine1: richAddress.AddressLine1,
            addressLine2: richAddress.AddressLine2,
            suburb: richAddress.Suburb,
            city: richAddress.City,
            postcode: richAddress.Postcode,
            esamId: richAddress.EsamId,
          });
        }
      },
      [onChange],
    );

    const suggest = useCallback(async (query: string, populateResults: any) => {
      makeGetRequest<IAddressSuggestion[]>(`/address-suggestions?searchFor=${query}`)
        .then((addressSuggestions: IAddressSuggestion[]) => {
          if (addressSuggestions && addressSuggestions.length > 0) {
            populateResults([...addressSuggestions.slice(0, 5)]);
          }
        })
        .catch(() => {
          populateResults([]);
        });
    }, []);

    return (
      <Wrapper hasError={!!hasError}>
        <InputLabel htmlFor="autocomplete">
          <Body2>Address search</Body2>
        </InputLabel>
        <Autocomplete
          ref={ref}
          autocomplete="off"
          showNoOptionsFound={false}
          placeholder="Start typing an address"
          minLength={5}
          templates={{
            inputValue: (suggestion: IAddressSuggestion | undefined) => suggestion?.FullAddress ?? '',
            suggestion: (suggestion: IAddressSuggestion | undefined) => suggestion?.FullAddress ?? '',
          }}
          onConfirm={getRichAddress}
          id="autocomplete"
          source={suggest}
          name="autocomplete"
          {...props}
        />
        <ButtonLink data-cy="address-manual-entry" marginTop="8px" onClick={onManualEntry}>
          Enter address manually
        </ButtonLink>
      </Wrapper>
    );
  },
);

AutoComplete.displayName = 'AutoComplete';

const Wrapper = styled.div<{ hasError: boolean }>`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  ${({ hasError }) =>
    hasError &&
    css`
      input {
        border: 1px solid ${(props) => props.theme.colors.error};
      }
    `}

  input {
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: #ffefd8;
    border: none;
    padding: 15px;
    height: 56px;
    line-height: 24px;
    border: 1px solid rgba(191, 84, 34, 0.5);

    font-family: Poppins;
    font-size: 1rem;
    font-size: clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.25)), 1.25rem);
    /* line-height: 1.5px; */

    :focus {
      background-color: #fff;
    }
  }

  li {
    padding: 8px 16px;

    :hover {
      cursor: pointer;
    }
  }

  .autocomplete__menu--visible {
    display: block;
  }

  .autocomplete__menu--hidden {
    display: none;
  }

  ul {
    font-family: Poppins;
    background-color: #ffefd8;

    font-family: Poppins;
    font-size: 1rem;
    font-size: clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.25)), 1.25rem);
  }

  .autocomplete__menu {
    z-index: 100;
  }
`;

import { useNavigate } from 'react-router';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import externalIcon_12px from '../../../assets/icons/externalIcon_12px.svg';
import externalIconLight_12px from '../../../assets/icons/externalIconLight_12px.svg';
import externalLink from '../../../assets/icons/externalLink.svg';
import externalLinkLight from '../../../assets/icons/externalLinkLight.svg';
import { ReactComponent as TeWhatuOraLogo } from '../../../assets/logos/TeWhatuOraLogo.svg';

import { recordEvents, recordCustomEvents } from '@/components/Analytics/RecordEvents';
import { Box } from '@/components/Box/Box';
import { Button, LinkButton } from '@/components/Button/Button';
import { Button2, Button3 } from '@/components/Button/Button2';
import { GlobalHeader } from '@/components/GlobalHeader/GlobalHeader';
import { Head } from '@/components/Head/Head';
import { useLandingContext } from '@/components/LandingContextProvider/useLandingContext';
import { DefaultLayout } from '@/components/Layouts/Layouts';
import { Body2, H2 } from '@/components/Typography/Typography';
import { useAuth } from '@/features/auth';
import { Navigation } from '@/features/iwiAffiliationForm/components/Navigation';

export const SignIn = () => {
  const {
    sectionConfig: { textColor },
  } = useLandingContext();
  const { login } = useAuth();
  const isDesktop = useBreakpoint(up('md'));
  const navigate = useNavigate();
  const affiliationfor = window.history.state.usr?.affiliationfor;
  const handleRedirect = () => {
    recordEvents({ no_sign_in: true });
    navigate('/who-is-this-iwi-affiliation-for', { state: { redirectedFrom: 'signin' } });
  };
  return (
    <>
      <Head />
      <Wrapper>
        <GlobalHeader variantOverride="dark" />
        <DefaultLayout>
          {/* <DefaultLayout> */}

          {affiliationfor === 'onBehalfOf' ? (
            <Navigation
              goToPreviousStep={() =>
                navigate('/who-is-this-iwi-affiliation-for', { state: { previousStep: 'affiliationFor' } })
              }
              labelled-by="who-is-this-affiliation-for"
              title="Previous step"
              padding="0px"
              mt={{ _: '72px', md: '120px' }}
            >
              <Box
                color={textColor}
                display="flex"
                flexDirection="column"
                alignItems={{ _: 'left', md: 'center' }}
                mt={{ _: 32 }}
              >
                <StyledHeading>Sign in if you want to re-access this persons iwi affiliations</StyledHeading>
                <Body2 mb="32px" mt="32px" textAlign={{ _: 'left', md: 'center' }}>
                  You can continue without signing in. You won&apos;t be able to reaccess and edit this persons iwi
                  affiliation later.
                </Body2>

                {!isDesktop ? (
                  <LinkButton
                    variant="dark"
                    label="Continue without signing in"
                    maxWidth="290px"
                    mb="64px"
                    to="/form"
                    onClick={() => {
                      recordEvents({ no_sign_in_on_behalf_of: true });
                    }}
                  />
                ) : (
                  <LinkButton
                    variant="dark"
                    label="Continue without signing in"
                    maxWidth="360px"
                    mb="64px"
                    to="/form"
                    onClick={() => {
                      recordEvents({ no_sign_in_on_behalf_of: true });
                    }}
                  />
                )}

                <Body2 mb="32px" textAlign={{ _: 'left', md: 'center' }}>
                  Signing in with your My Health Account will allow you to reaccess and edit this persons iwi
                  affiliation later.
                </Body2>

                {/* <Button2
                  variant="light"
                  type="button"
                  onClick={() => {
                    login();
                  }}
                  label="Login with"
                  label2=" My Health Account"
                  icon={externalLink}
                  maxWidth="550px"
                  mb={{ _: '32px' }}
                /> */}

                {!isDesktop ? (
                  <Button2
                    variant="light"
                    type="button"
                    onClick={() => {
                      recordCustomEvents('login_with_mha', { login_with_mha: true });
                      login();
                    }}
                    label="Login with"
                    label2=" My Health Account"
                    icon={externalIcon_12px}
                    maxWidth="296px"
                    mb="32px"
                  />
                ) : (
                  <Button2
                    variant="light"
                    type="button"
                    onClick={() => {
                      recordCustomEvents('login_with_mha', { login_with_mha: true });
                      login();
                    }}
                    label="Login with"
                    label2=" My Health Account"
                    icon={externalLink}
                    maxWidth="356px"
                    mb="32px"
                  />
                )}
                <Box mb={{ _: '32px' }}>
                  <TeWhatuOraLogo aria-hidden />
                </Box>
              </Box>
            </Navigation>
          ) : (
            <Box
              color={textColor}
              display="flex"
              flexDirection="column"
              alignItems={{ _: 'left', md: 'center' }}
              mt={{ _: '16px', md: '32px' }}
            >
              <StyledHeadingOptions>Options for adding your iwi affiliations</StyledHeadingOptions>
              <Body2 mb="32px" mt="32px" textAlign={{ _: 'left', md: 'center' }}>
                Signing in with your My Health Account will allow you to reaccess and edit your iwi affiliations later.
              </Body2>
              {/* <Button3
                type="button"
                variant="dark"
                label="Login with My Health Account"
                onClick={() => {
                  login();
                }}
                maxWidth="550px"
                mb="32px"
                icon={externalLinkLight}
              /> */}

              {!isDesktop ? (
                <Button3
                  variant="dark"
                  type="button"
                  onClick={() => {
                    recordCustomEvents('login_with_mha', { login_with_mha: true });
                    login();
                  }}
                  label="Login with"
                  label2=" My Health Account"
                  icon={externalIconLight_12px}
                  maxWidth="296px"
                  mb="32px"
                />
              ) : (
                <Button3
                  variant="dark"
                  type="button"
                  onClick={() => {
                    recordCustomEvents('login_with_mha', { login_with_mha: true });
                    login();
                  }}
                  label="Login with"
                  label2=" My Health Account"
                  icon={externalLinkLight}
                  maxWidth="356px"
                  mb="32px"
                />
              )}
              <Box mb="64px">
                <TeWhatuOraLogo aria-hidden />
              </Box>
              <Body2 mb="32px" textAlign={{ _: 'left', md: 'center' }}>
                You can continue without signing in. You won&apos;t be able to reaccess and edit your iwi affiliations
                later.
              </Body2>
              {!isDesktop ? (
                <Button
                  type="button"
                  variant="light"
                  label="Continue without signing in >"
                  onClick={handleRedirect}
                  maxWidth="290px"
                  mb={{ _: '50px' }}
                />
              ) : (
                <Button
                  type="button"
                  variant="light"
                  label="Continue without signing in >"
                  onClick={handleRedirect}
                  maxWidth="360px"
                  mb={{ _: '50px' }}
                />
              )}
            </Box>
          )}
        </DefaultLayout>
      </Wrapper>
    </>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledHeading = styled(H2)`
  ${up('md')} {
    margin-top: 100px;
  }
`;

export const StyledHeadingOptions = styled(H2)`
  margin-top: 100px;
  ${up('md')} {
    margin-top: 200px;
  }
`;

import { useRef } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import { Box } from '@/components/Box/Box';
import { ScrollToTopButton } from '@/components/Button/ScrollToTopButton';
import { Container } from '@/components/Container/Container';
import { FooterNavigation } from '@/components/FooterNavigation/FooterNavigation';
import { GlobalHeader } from '@/components/GlobalHeader/GlobalHeader';
// import { Head } from '@/components/Head/Head';
import { SignIn } from '@/features/signin/components';

export const SignInPage = () => {
  /* We refocus the main title when using the back to top button */
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  const isDesktop = useBreakpoint(up('md'));
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}>
      {/* <Head /> */}
      <Wrapper>
        <GlobalHeader variantOverride="dark" />

        <main>
          <Container>
            <SignIn />
          </Container>
        </main>
        {/* Footer navigation and back to top buttons */}
        <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }} zIndex={10} mt="auto">
          <FooterNavigation />

          {!isDesktop && (
            <Box mt={32} mb={32} display="flex" justifyContent={{ _: 'center' }} flexShrink={0} flexGrow={0}>
              <ScrollToTopButton focusRef={mainTitleRef} />
            </Box>
          )}
        </Box>
      </Wrapper>
    </GoogleReCaptchaProvider>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

import React, { ChangeEventHandler, KeyboardEventHandler, FocusEventHandler, forwardRef } from 'react';
import { VisuallyHidden, useVisuallyHidden } from 'react-aria';
import { FieldError } from 'react-hook-form';
import styled, { css } from 'styled-components';

import Icon from '../Icon/Icon';
import { InputError } from '../InputError/InputError';
import { Body2 } from '../Typography/Typography';

export interface ITextInputProps extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  dataCy?: string;
  label: string;
  error?: FieldError;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  setValue?: any;
  className?: string;
  placeholder?: string;
  autoComplete?: string;
  value?: any;
  hideLabel?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
}

export const TextInput = forwardRef<HTMLInputElement, ITextInputProps>((props: ITextInputProps, ref) => {
  const {
    id,
    dataCy,
    label,
    error,
    hideLabel,
    readOnly,
    disabled,
    onChange,
    value,
    onBlur,
    onKeyPress,
    className,
    maxLength,
    setValue,
    ...otherProps
  } = props;
  const { visuallyHiddenProps } = useVisuallyHidden();
  const hiddenLabelProps = hideLabel ? visuallyHiddenProps : {};

  const handleClear = () => {
    if (setValue) {
      setValue('');
    }
  };

  return (
    <InputWrapper hasError={!!error} className={className}>
      <InputLabel {...hiddenLabelProps} htmlFor={id}>
        <Body2>{label}</Body2>
      </InputLabel>
      <ErrorBarWrapper>
        <InputRoot
          data-cy={dataCy}
          id={id}
          aria-invalid={!!error}
          type="text"
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          maxLength={maxLength || 50}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          {...otherProps}
        />
        {!!error && <ErrorBar />}
        {value && setValue && (
          <InputClearButton type="button" onClick={handleClear}>
            <VisuallyHidden>Clear the filter input</VisuallyHidden>
            <Icon size={24} name="ic-clear" />
          </InputClearButton>
        )}
      </ErrorBarWrapper>
      {!!error && error.message && (
        <InputError aria-live="polite" aria-describedby={id}>
          {error.message}
        </InputError>
      )}
    </InputWrapper>
  );
});

TextInput.displayName = 'TextInput';

const InputClearButton = styled.button`
  position: absolute;
  right: 16px;
  top: 15px;
  width: 24px;
  height: 24px;
  background: 0;
  border: 0;
  padding: 0;
  cursor: pointer;
`;

export const InputWrapper = styled.div<{ hasError: boolean }>`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  ${({ hasError }) =>
    hasError &&
    css`
      input {
        border: 1px solid ${(props) => props.theme.colors.error};
      }
    `}
`;

export const InputLabel = styled.label`
  color: white;
  margin-bottom: 8px;
`;

export const InputRoot = styled.input`
  background: ${(props) => props.theme.colors.ma};
  border: 1px solid rgba(191, 84, 34, 0.5);
  border-radius: 4px;
  padding: 8px 16px;
  height: 56px;
  width: 100%;

  font-family: Poppins;
  font-size: 1rem;
  font-size: clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.25)), 1.25rem);
  /* line-height: 1.5px; */

  &:focus {
    background-color: ${(props) => props.theme.colors.white};
    border-color: ${(props) => props.theme.colors.pango};
  }
`;

export const ErrorBarWrapper = styled.div`
  position: relative;
`;

export const ErrorBar = styled.div`
  height: 6px;
  background: ${(props) => props.theme.colors.error};
  opacity: 0.5;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

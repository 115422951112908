import styled from 'styled-components';

export const ToastWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #ffefd8;
  padding: 24px;
  border: 1px solid rgba(191, 84, 34, 0.5);
  z-index: 99;
`;
export const MessageWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #ffefd8;
  padding: 16px;
  border: 1px solid rgba(191, 84, 34, 0.5);
  z-index: 99;
  color: black;
  margin-top: 16px;
`;

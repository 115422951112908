// import { useNavigate } from 'react-router';
import { Navigate } from 'react-router';
import styled from 'styled-components';

import { LinkMha } from './LinkMha';

import { DefaultLayout } from '@/components/Layouts/Layouts';
import { useAuth, useUserInfo } from '@/features/auth';

export const VerifyAccount = () => {
  const { isAuthenticated } = useAuth();
  const { data } = useUserInfo();
  // const navigate = useNavigate();
  console.log({ isAuthenticated });
  const confidence_level = data && data['urn:login:health:nz:claims:confidence_level'];
  window.sessionStorage.setItem('confidence_level', confidence_level);
  // if (confidence_level && (confidence_level === '2N' || confidence_level === '3N')) {
  //   navigate('/linked-account');
  // } else {
  //   navigate('/upgrade-account');
  // }

  return (
    <Wrapper>
      <DefaultLayout>
        <main>{confidence_level === '1' ? <Navigate to="/upgrade-account" /> : <LinkMha />}</main>
      </DefaultLayout>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

import { ReactElement } from 'react';

import { useAuth } from './hooks/auth';

/**
 * A wrapper for protected routes that require authentication.
 * This is only to be used for routes that cannot be added to the protected routes list.
 * For instance, if a parent route is protected, but the child routes are not,
 */
export const RequireAuth = ({ redirectTo, children }: { redirectTo?: string; children: ReactElement }) => {
  const { isAuthenticated, login } = useAuth();

  if (!isAuthenticated) {
    login({ redirectTo });

    return null;
  } else {
    return children;
  }
};

export enum IsMaoriDescentResponses {
  YES = 'YES',
  DONT_KNOW = 'DONT_KNOW',
  NO = 'NO',
}

export enum KnowsIwiResponses {
  YES = 'YES',
  PARTIALLY = 'PARTIALLY',
  NO = 'NO',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  ANOTHER_GENDER = 'ANOTHER_GENDER',
  PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
}

export interface IMaoriDescentData {
  isMaoriDescent?: IsMaoriDescentResponses;
  doesKnowIwi?: KnowsIwiResponses;
}

export interface IIwiLookupData {
  affiliatedIwi?: Iwi[];
}

export enum IsProvidingAddressResponses {
  YES = 'YES',
  NO = 'NO',
}

export interface IAddressPayload {
  addressLine1?: string;
  addressLine2?: string;
  suburb?: string;
  city?: string;
  postcode?: string;
  esamId?: string;
}

export interface IYourDetailsData {
  nhiNumber?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dateOfBirth?: string;
  isProvidingAddress?: IsProvidingAddressResponses;
  isManualAddressEntry?: boolean;
  address: IAddressPayload;
  gender?: Gender;
  genderAsStated?: string;
}

export interface IContactDetailsData {
  email?: string;
  phone?: string;
}

export interface ISummaryData {
  agreeToPrivacyPolicy?: boolean;
  recaptchaToken?: string;
}

export interface IwiRegion {
  id: string;
  formattedId: string;
  label: string;
}

export interface Iwi {
  id: string;
  label: string;
  sanitisedLabel?: string;
  region?: IdToLabel;
  priority?: number;
}

export type IdToLabel = {
  id: string;
  label: string;
};

export type IwiAffiliationsPerRegion = {
  region: IdToLabel;
  iwis: IdToLabel[];
};

export type IwiAffiliationData = {
  additionalData: IdToLabel[];
  iwiAffiliationsPerRegion: IwiAffiliationsPerRegion[];
};

/* Label mappings */

export const KNOWS_IWI_TO_LABEL = {
  [KnowsIwiResponses.YES]: 'Yes',
  [KnowsIwiResponses.PARTIALLY]: 'I know some of them',
  [KnowsIwiResponses.NO]: 'No',
};

export const MAORI_DESCENT_TO_LABEL = {
  [IsMaoriDescentResponses.YES]: 'Yes',
  [IsMaoriDescentResponses.DONT_KNOW]: "Don't know",
  [IsMaoriDescentResponses.NO]: 'No',
};

export const GENDER_TO_LABEL = {
  [Gender.MALE]: 'Male',
  [Gender.FEMALE]: 'Female',
  [Gender.ANOTHER_GENDER]: 'Another gender',
  [Gender.PREFER_NOT_TO_SAY]: 'Prefer not to say',
};

export const IS_PROVIDING_ADDRESS_TO_LABEL = {
  [IsProvidingAddressResponses.YES]: 'Yes',
  [IsProvidingAddressResponses.NO]: 'No',
};

export enum WHOTHISAFFILIATIONFOR {
  MY_SELF = 'mySelf',
  ON_BEHALF_OF = 'onBehalfOf',
}

export interface AffiliationForData {
  mySelf?: 'mySelf';
  onBehalfOf?: 'onBehalfOf';
}

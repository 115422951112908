import { useCallback, useEffect, useRef, useState } from 'react';
import { useVisuallyHidden } from 'react-aria';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';

import { ReactComponent as MapSVG } from '../../assets/images/map-test.svg';
import { IdToLabel } from '../../tools/constants/iwi';
import { calculateElementCenterCoordinates } from '../../tools/map-utilities';
import { Box } from '../Box/Box';
import { TransparentButton } from '../Button/TransparentButton';
import Icon from '../Icon/Icon';

interface IViewBoxConfig {
  x: number;
  y: number;
  scale: number;
  isZoomed: boolean;
}

interface IMapProps {
  region?: string;
  onZoom?: (regionId: string) => void;
  onRegionSelect?: (region?: IdToLabel) => void;
}

const MAP_WIDTH = 800;
const MAP_HEIGHT = 800;
const ZOOM_SCALE = 1.65;
const INITIAL_TRANSFORM_STATE = {
  x: 0,
  y: 0,
  scale: 1,
  isZoomed: false,
};

export const Map = ({ region, onZoom, onRegionSelect }: IMapProps) => {
  const mapWrapperRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<HTMLDivElement>(null);
  const [{ x, y, scale, isZoomed }, setViewBoxConfig] = useState<IViewBoxConfig>(INITIAL_TRANSFORM_STATE);
  const { visuallyHiddenProps } = useVisuallyHidden();

  const zoomIntoRegion = useCallback(
    (pathWrapper: HTMLDivElement, path: HTMLElement[]) => {
      const regionCenterCoordinates = calculateElementCenterCoordinates(path);
      const mapWrapperCenterCoordinates = calculateElementCenterCoordinates([pathWrapper]);

      const centerRegionTranslation = {
        x: mapWrapperCenterCoordinates.x - regionCenterCoordinates.x,
        y: mapWrapperCenterCoordinates.y - regionCenterCoordinates.y,
      };

      let newX;
      let newY;

      if (isZoomed) {
        // Already translated. Add the result to the current translation.
        newX = x + centerRegionTranslation.x;
        newY = y + centerRegionTranslation.y;
      } else {
        // Not zoomed, so need to account for the zoom scale when translating.
        newX = centerRegionTranslation.x * ZOOM_SCALE;
        newY = centerRegionTranslation.y * ZOOM_SCALE;
      }

      // IMPORTANT: Round to avoid invoking an update to the callback by having
      // the float values of x and y be invalidated by small changes. This way,
      // the dependency array won't be invalidated.
      newX = Math.round(newX);
      newY = Math.round(newY);

      setViewBoxConfig({
        x: newX,
        y: newY,
        scale: ZOOM_SCALE,
        isZoomed: true,
      });
    },
    [x, y, isZoomed],
  );

  const reset = useCallback(() => {
    setViewBoxConfig(INITIAL_TRANSFORM_STATE);

    if (onRegionSelect) {
      onRegionSelect();
    }
  }, [onRegionSelect]);

  const onRegionClick = useCallback(
    (path: any) => {
      if (!path) {
        return;
      }

      if (onZoom) {
        onZoom(path.id);
      }
    },
    [onZoom],
  );

  // Attaches a click handler to the all the maps paths
  useEffect(() => {
    if (!region) {
      // console.log('It is at !region.');
      reset();
    }

    if (!mapWrapperRef.current) {
      return;
    }

    /**
     * When setting id's on elements the browser removes the leading 0's.
     * However, the iwi region id's, might start with 0.
     */
    const regionToZoomIn = [
      ...mapWrapperRef.current.querySelectorAll(`path[id="${region?.replace(/^0+/, '')}"]`),
    ] as HTMLElement[];

    if (!regionToZoomIn.length) {
      // console.log('It is at !regionToZoomIn.');
      reset();
      return;
    }

    zoomIntoRegion(mapWrapperRef.current, regionToZoomIn);
  }, [region, zoomIntoRegion, reset]);
  useEffect(() => {
    let localRef = null;
    if (mapRef.current) {
      localRef = mapRef.current;
    }
    const regionClicked = (event: any) => {
      onRegionClick(event.target);
    };

    if (localRef) {
      localRef.querySelectorAll('path').forEach((path) => {
        path.addEventListener('click', regionClicked);
      });
    }

    return () => {
      if (localRef) {
        localRef.querySelectorAll('path').forEach((path) => {
          path.removeEventListener('click', regionClicked);
        });
      }
    };
  }, [onRegionClick]);

  /* We have to strip leading 0's from the region, as the IDs in HTML
  do it automatically */
  const htmlRegion = (region || '').replace(/^0+/, '');

  return (
    <>
      <MapWrapper region={htmlRegion} ref={mapWrapperRef}>
        <Box position="relative" height="100%" maxHeight="100%" ref={mapRef}>
          <StyledMap viewBox={`0 0 ${MAP_WIDTH} ${MAP_HEIGHT}`} transform={`translate(${x}, ${y}) scale(${scale})`} />
          {isZoomed && (
            <Box position="absolute" backgroundColor="pango" borderRadius="4px" top={16} right="8px">
              <TransparentButton
                type="button"
                style={{ padding: '8px', width: '40px', height: '40px' }}
                onClick={reset}
              >
                <span {...visuallyHiddenProps}>Reset map</span>
                <Icon size={24} name="ic-zoom-out" />
              </TransparentButton>
            </Box>
          )}
        </Box>
      </MapWrapper>
    </>
  );
};

const StyledMap = styled(MapSVG)`
  max-height: 100%;

  ${up('lg')} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

const MapWrapper = styled.div<{ region: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  text-align: center;

  path:hover {
    fill: white;
    opacity: 1;
  }

  path[id='${(props) => props.region}'] {
    fill: rgb(214, 157, 120);
  }
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

import { DynamicStyledSystemComponent, StyledSystemTypographyProps } from './DynamicStyledSystemComponent';

export const QuotePrint = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  as: props.as || 'p',
  fontWeight: props.fontWeight || 400,
  lineHeight: props.lineHeight || 1.375,
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 3rem;
  font-size: min(max(1.5rem, 3vw), 3rem);
  font-size: clamp(1.5rem, calc(1.5rem + ((1vw - 0.2rem) * 0.6)), 3rem);
`;

export const H1 = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  as: props.as || 'h1',
  fontWeight: props.fontWeight || 400,
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 3rem;
  font-size: min(max(2rem, 3vw), 3rem);
  font-size: clamp(2rem, calc(2rem + ((1vw - 0.2rem) * 5)), 3rem);
`;

export const H2 = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  as: props.as || 'h2',
  fontWeight: props.fontWeight || 400,
  lineHeight: props.lineHeight || 1.5,
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 2rem;
  font-size: min(max(1.5rem, 3vw), 2rem);
  font-size: clamp(1.5rem, calc(1.5rem + ((1vw - 0.2rem) * 0.5)), 2rem);
`;

export const LandingH2 = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  as: props.as || 'p',
  fontWeight: props.fontWeight || 400,
  lineHeight: props.lineHeight || 1.5,
  fontSize: props.fontSize,
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 2rem;
  font-size: min(max(1.5rem, 3vw), 2rem);
  font-size: clamp(1.5rem, calc(1.5rem + ((1vw - 0.2rem) * 0.5)), 2rem);
`;
export const LandingH3 = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  as: props.as || 'p',
  fontWeight: props.fontWeight || 400,
  lineHeight: props.lineHeight || 1.5,
  fontSize: props.fontSize,
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 1.5rem;
  font-size: min(max(1.5rem, 3vw), 1.5rem);
  font-size: clamp(1.5rem, calc(1.5rem + ((1vw - 0.2rem) * 0.5)), 1.5rem);
`;

export const LandingBody = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  fontWeight: props.fontWeight || 500,
  lineHeight: props.lineHeight || 1.75,
  as: props.as || 'p',
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.body};
  font-weight: 500;
  font-size: 1.5rem;
  font-size: min(max(1.125rem, 3vw), 1.5rem);
  font-size: 24px;
  line-height: 1.75;
`;

export const Body = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  fontWeight: props.fontWeight || 500,
  lineHeight: props.lineHeight || 1.75,
  as: props.as || 'p',
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.body};
  font-weight: 500;
  font-size: 1.5rem;
  font-size: min(max(1.125rem, 3vw), 1.5rem);
  font-size: clamp(1.125rem, calc(1.125rem + ((1vw - 0.2rem) * 0.25)), 1.5rem);
  line-height: 1.75;
`;

export const Body2 = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  fontWeight: props.fontWeight || 400,
  lineHeight: props.lineHeight || 1.5,
  as: props.as || 'p',
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 1.25rem;
  font-size: min(max(1rem, 3vw), 1.25rem);
  font-size: clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.25)), 1.25rem);
`;

export const ButtonPrint = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  as: props.as || 'span',
  fontWeight: props.fontWeight || 400,
  lineHeight: props.lineHeight || 1.5,
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 1.25rem;
  font-size: min(max(1rem, 3vw), 1.25rem);
  font-size: clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.25)), 1.25rem);
`;
export const ButtonPrint2 = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  as: props.as || 'span',
  lineHeight: props.lineHeight || 1.5,
}))<StyledSystemTypographyProps>`
  font-family: 'Fira Sans';
  font-size: 1.25rem;
  font-size: min(max(1rem, 3vw), 1.25rem);
  font-size: clamp(1.125rem, calc(1.125rem + ((1vw - 0.2rem) * 0.25)), 1.25rem);
`;

export const Fineprint = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  as: props.as || 'p',
  fontWeight: props.fontWeight || 500,
  lineHeight: props.lineHeight || 1.5,
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 1rem;
  font-size: min(max(0.875rem, 3vw), 1rem);
  font-size: clamp(0.875rem, calc(0.875rem + ((1vw - 0.2rem) * 0.1)), 1rem);
`;

export const Ul = styled.ul`
  padding-top: 16px;
  list-style-image: url('/icons/ic-list-item.svg');

  li {
    margin-left: 19px;
    margin-bottom: 16px;
    word-wrap: wrap;
  }
`;

export const Body3 = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  fontWeight: props.fontWeight || 400,
  lineHeight: props.lineHeight || 1.5,
  as: props.as || 'p',
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 1rem;
`;
export const Body4 = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  fontWeight: props.fontWeight || 400,
  lineHeight: props.lineHeight || 0.875,
  as: props.as || 'p',
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 0.875rem;
  font-size: min(max(0.875rem, 3vw), 0.875rem);
  font-size: clamp(0.875rem, calc(0.875rem + ((1vw - 0.2rem) * 0.25)), 0.875rem);
`;

export const Body5 = styled(DynamicStyledSystemComponent).attrs<StyledSystemTypographyProps>((props: any) => ({
  fontWeight: props.fontWeight || 500,
  lineHeight: props.lineHeight || 1.75,
  as: props.as || 'p',
}))<StyledSystemTypographyProps>`
  font-family: ${(props) => props.theme.fonts.heading};
  font-weight: 500;
  font-size: 1.5rem;
  font-size: min(max(1.125rem, 3vw), 1.5rem);
  font-size: clamp(1.125rem, calc(1.125rem + ((1vw - 0.2rem) * 0.25)), 1.5rem);
  line-height: 1.75;
`;

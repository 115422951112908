import React, { useEffect, KeyboardEventHandler, useRef, useCallback } from 'react';
import { SyntheticEvent, useState } from 'react';
import { useVisuallyHidden } from 'react-aria';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import externalLink from '../../../assets/icons/externalLink.svg';
import { useIwiAffiliationData } from '../hooks/useIwiAffiliationData';
import { doesIwiMatch } from '../hooks/useIwiLookup';
import { Iwi } from '../types';

import { ScreenReaderOnly } from '@/components/Accessibility/ScreenReaderOnly';
import { Box } from '@/components/Box/Box';
import { Button } from '@/components/Button/Button';
import { TextInput } from '@/components/TextInput/TextInput';
import { Body2, Fineprint } from '@/components/Typography/Typography';
import { IdToLabel } from '@/tools/constants/iwi';
import { validateTextInput } from '@/tools/text';
import { sanitiseText } from '@/tools/text';

interface IwiSelectProps {
  selectedItems?: Iwi[];
  region?: IdToLabel;
  disableTextSearch?: boolean;
  onItemSelect: (iwi: Iwi) => void;
  includeTextFilter?: boolean;
}

const FILTER_THRESHOLD = 3;

export const IwiSelect = ({ selectedItems, region, onItemSelect, includeTextFilter = true }: IwiSelectProps) => {
  const isDesktopOrAbove = useBreakpoint(up('md'));
  const { visuallyHiddenProps } = useVisuallyHidden();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSearchTermValid, setIsSearchTermValid] = useState<boolean>(true);
  const { iwis } = useIwiAffiliationData();

  const iwiInputsListRef = useRef<(HTMLInputElement | null)[]>([]);

  const iwiListRef = useRef<HTMLDivElement>(null);
  const addCustomIwiButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setIsSearchTermValid(validateTextInput(searchTerm.trim()));
  }, [searchTerm]);

  const scrollEvent = (e: any) => {
    e.stopPropagation();
    const event = window.event || e;
    const delta = event.detail ? event.detail * -120 : event.wheelDelta;
    if (iwiListRef.current) {
      iwiListRef.current.scrollTop = iwiListRef.current.scrollTop - delta;
    }
  };

  useEffect(() => {
    let localRef = null;
    if (iwiListRef.current) {
      localRef = iwiListRef.current;
    }
    const stopListScroll = (e: any) => {
      if (isDesktopOrAbove) {
        e.preventDefault();
      }
    };

    if (localRef) {
      document.addEventListener('mousewheel', scrollEvent, { passive: false });
      localRef.addEventListener('mousewheel', stopListScroll);
    }

    return () => {
      if (localRef) {
        document.removeEventListener('mousewheel', scrollEvent, false);
        localRef.removeEventListener('mousewheel', stopListScroll, false);
      }
    };
  }, [isDesktopOrAbove]);

  /**
   * Set the first element to be focusable when the filteredIwiList changes.
   */
  useEffect(() => {
    if (iwiInputsListRef.current[0]) {
      resetTabIndexes();
      iwiInputsListRef.current[0].tabIndex = 0;
    }

    function resetTabIndexes() {
      iwiInputsListRef.current.forEach((ref) => {
        if (ref) ref.tabIndex = -1;
      });
    }
  }, [searchTerm, region]);

  const focusableIwiElement = useCallback(() => iwiInputsListRef.current.find((ref) => ref?.tabIndex === 0), []);

  const focusRef = useCallback(
    (refToFocus: HTMLInputElement) => {
      const currentlyFocusableIwiElement = focusableIwiElement(); // Do this to avoid typechecking error with the guard below

      if (!currentlyFocusableIwiElement) return;

      currentlyFocusableIwiElement.tabIndex = -1;
      refToFocus.tabIndex = 0;
      refToFocus.focus();
    },
    [focusableIwiElement],
  );

  const focusNewRefIndex = useCallback(
    (refToFocusIndex: number) => {
      const refToFocus = iwiInputsListRef.current[refToFocusIndex];
      if (!refToFocus) return;
      focusRef(refToFocus);
    },
    [focusRef],
  );

  const currentIwiIndex = useCallback(() => {
    const currentlyFocusableIwiElement = focusableIwiElement();
    if (!currentlyFocusableIwiElement) return -1;
    return iwiInputsListRef.current.findIndex((ref) => ref && currentlyFocusableIwiElement.id === ref.id);
  }, [focusableIwiElement]);

  useEffect(() => {
    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);

    function onKeydown(event: KeyboardEvent) {
      switch (event.code) {
        case 'ArrowUp':
        case 'ArrowLeft':
          onUpArrow(event);
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          onDownArrow(event);
          break;
      }
    }

    function onUpArrow(event: KeyboardEvent) {
      if (!isKeydownWhileFocusedOnIwi(event)) return;
      event.preventDefault();
      focusNewRefIndex(currentIwiIndex() - 1);
    }

    function onDownArrow(event: KeyboardEvent) {
      if (!isKeydownWhileFocusedOnIwi(event)) return;
      event.preventDefault();
      focusNewRefIndex(currentIwiIndex() + 1);
    }

    function isKeydownWhileFocusedOnIwi(event: KeyboardEvent): boolean {
      return !!(event.target && iwiInputsListRef.current.includes(event.target as unknown as HTMLInputElement));
    }
  }, [currentIwiIndex, focusNewRefIndex]);

  const onFocus: React.FocusEventHandler<HTMLInputElement> = useCallback((event) => focusRef(event.target), [focusRef]);

  const addRefToRefList = useCallback((ref: HTMLInputElement | null, index: number) => {
    iwiInputsListRef.current[index] = ref;
  }, []);

  /**
   * User can enter whatever they want and if it's not found, then there
   * he's offered with the possibility to have what he entered recorded as an iwi.
   * This function handles that case to set the data entered to what's manageable
   * going down the form.
   */
  const handleCustomEntry = () => {
    const customIwi = {
      region,
      id: uuidv4(),
      label: searchTerm,
      sanitisedLabel: '',
    };

    onItemSelect(customIwi);
    setSearchTerm('');
  };

  const handleFormReset = () => {
    setSearchTerm('');
  };

  if (!iwis || !iwis.length) {
    return null;
  }

  let filteredIwiList = iwis;

  if (region) {
    filteredIwiList = filteredIwiList.filter((iwi: Iwi) => iwi.region && iwi.region.id === region.id);
  }

  const iwiFilterPressHandler: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (filteredIwiList && filteredIwiList.length === 0 && searchTerm) {
        if (addCustomIwiButtonRef && addCustomIwiButtonRef.current) {
          addCustomIwiButtonRef.current.focus();
        }
      } else {
        if (iwiListRef && iwiListRef.current) {
          const firstInput = iwiListRef.current.querySelector('input');

          if (firstInput) {
            firstInput.focus();
          }
        }
      }
      return false;
    }

    return true;
  };

  if (searchTerm && searchTerm.length >= FILTER_THRESHOLD) {
    /**
     * Checks if there's anything matching taking both the search term
     * and the iwi names to an "accent free" version of them.
     */
    const searchTermSanitised = sanitiseText(searchTerm);
    filteredIwiList = filteredIwiList.filter(
      (iwi: Iwi) => iwi.sanitisedLabel && iwi.sanitisedLabel.indexOf(searchTermSanitised) !== -1,
    );
  }

  return (
    <>
      <ScreenReaderOnly>
        The following is a long list of iwi checkboxes that change based on the filter text box. To control the list
        with the keyboard, focus onto a checkbox, then use the arrow keys to navigate and the space bar to select.
      </ScreenReaderOnly>
      {/* <ScrollCapture ref={scrollCaptureRef} /> */}
      {includeTextFilter && (
        <Box flexShrink={0}>
          <TextInput
            label="Filter the iwi list by text"
            id="input_iwiFilter"
            onChange={(event: SyntheticEvent<HTMLInputElement>) => setSearchTerm(event.currentTarget.value)}
            onKeyPress={iwiFilterPressHandler}
            aria-controls="iwi-affiliation-list"
            placeholder="Enter the name of their iwi"
            autoComplete="off"
            value={searchTerm}
            hideLabel
            setValue={setSearchTerm}
            error={isSearchTermValid ? undefined : { type: 'pattern', message: 'Please enter a valid search term.' }}
          />
        </Box>
      )}

      {filteredIwiList.length > 0 && (
        <Box
          mt={24}
          mb="8px"
          aria-hidden
          display="flex"
          flex-shrink={0}
          color="white"
          justifyContent="space-between"
          alignItems="center"
          flexGrow={0}
        >
          <Body2 fontWeight="bold" m={0}>
            Name
          </Body2>
          <Body2 m={0}>Select</Body2>
        </Box>
      )}
      <Box id="iwi-affiliation-list" ref={iwiListRef} overflowY={{ md: 'auto' }} flexGrow={1} tabIndex={-1}>
        {filteredIwiList.length > 0 && (
          <>
            <fieldset>
              <legend {...visuallyHiddenProps}>Select the iwis you affiliate with</legend>
              <IwiItemList data-cy="iwi-list">
                {filteredIwiList.map((iwi: Iwi, index) => {
                  const isIwiSelected = !!(
                    selectedItems && selectedItems.find((selectedItem: Iwi) => doesIwiMatch(selectedItem, iwi))
                  );

                  const key = `${iwi.id}-${iwi.sanitisedLabel}-${iwi.priority}`;

                  // Set height to 100% so focusing the bottom most input by
                  // keyboard brings it fully into view.
                  const customVisuallyHiddenStyle = { ...visuallyHiddenProps.style, height: '100%' };

                  return (
                    <IwiItem key={key}>
                      <IwiItemWrapper>
                        <input
                          type="checkbox"
                          name="input_iwiAffiliation"
                          id={`input_iwiAffiliation_${key}`}
                          onChange={() => onItemSelect(iwi)}
                          value={iwi.id}
                          checked={isIwiSelected}
                          tabIndex={-1}
                          onFocus={onFocus}
                          ref={(ref) => addRefToRefList(ref, index)}
                          {...visuallyHiddenProps}
                          style={customVisuallyHiddenStyle}
                        />
                        <IwiItemLabel htmlFor={`input_iwiAffiliation_${key}`}>
                          <Box px={{ _: 16, md: 24 }}>
                            <Box py={16} pr={{ _: 32, md: 40 }} borderBottom="1px solid rgba(191, 84, 34, 0.5)">
                              <Body2 fontWeight={700} m={0}>
                                {iwi.label}
                              </Body2>
                              <Fineprint m={0} mt="8px">
                                {iwi.region?.label}
                              </Fineprint>
                            </Box>
                          </Box>
                        </IwiItemLabel>
                      </IwiItemWrapper>
                    </IwiItem>
                  );
                })}
              </IwiItemList>
            </fieldset>
          </>
        )}
        {filteredIwiList.length === 0 && isSearchTermValid && (
          <MissingIwiPanel isDesktopOrAbove={isDesktopOrAbove ?? true}>
            <Fineprint>We could not find a match. Would you like to select:</Fineprint>
            <Box mt={16} mb={24}>
              <MissingIwiName>{searchTerm}</MissingIwiName>
              <Fineprint>{region?.label}</Fineprint>
            </Box>
            <Fineprint>
              As one of your iwi? This choice means that you identify with an iwi that is not included in the{' '}
              <StyledAnchorTag
                href="https://aria.stats.govt.nz/aria/#ClassificationView:uri=http://stats.govt.nz/cms/ClassificationVersion/uH9AkXTnTlq40DHG"
                target="_blank"
              >
                Stats NZ classification <StyledImage src={externalLink} alt="External Link" />
              </StyledAnchorTag>
              that Tātai uses.
            </Fineprint>
            <Box mt={24} display="flex" justifyContent="space-between" flexShrink={0} flexGrow={0}>
              <Button
                type="button"
                ref={addCustomIwiButtonRef}
                label="Yes"
                onClick={handleCustomEntry}
                maxWidth="initial"
              />
              <Box width={16} flexShrink={0} />
              <Button type="button" onClick={handleFormReset} label="Reset search" variant="light" maxWidth="initial" />
            </Box>
          </MissingIwiPanel>
        )}
      </Box>
    </>
  );
};

const MissingIwiPanel = styled.div<{ isDesktopOrAbove: boolean }>`
  background-color: ${(props) => props.theme.colors.ma};
  border-radius: 4px;
  padding: 24px 16px;
  margin-top: ${(props) => (props.isDesktopOrAbove ? 32 : 8)}px;
`;

const MissingIwiName = styled(Body2)`
  font-weight: 700;
`;

const IwiItemList = styled.ul`
  border-radius: 4px;

  ${up('md')} {
    overflow: hidden;
    margin-bottom: 0;
  }
`;

const IwiItem = styled.li`
  background-color: ${(props) => props.theme.colors.ma};
  position: relative;
`;

const IwiItemLabel = styled.label`
  word-break: break-word;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    background-image: url('/icons/ic-checkbox.svg');
    background-position: center center;
    background-size: 24px 24px;

    ${up('md')} {
      right: 24px;
    }
  }
`;

const IwiItemWrapper = styled.div`
  input:checked + ${IwiItemLabel}::before {
    background-image: url('/icons/ic-checkbox-checked.svg');
  }

  /* Make the fake checkbox appear to have focus when the input is focused */
  input:focus + ${IwiItemLabel}::before {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`;

export const StyledImage = styled.img`
  vertical-align: sub;
`;

export const StyledAnchorTag = styled.a`
  font-weight: 700 !important;
`;

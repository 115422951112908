import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

import { AnimatedBackground } from '../AnimatedBackground/AnimatedBackground';

const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = () => (
  <LoaderWrapper>
    <AnimatedBackground />
    <ClipLoader color="white" size={100} />
  </LoaderWrapper>
);

/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';
import { useMediaQuery } from 'usehooks-ts';

import { ReactComponent as BackgroundHoverSvg } from '../../assets/images/Subtract_white_md.svg';
import { ReactComponent as BackgroundHoverSmallSvg } from '../../assets/images/Subtract_white_sm.svg';
import { ReactComponent as BackgroundSvg } from '../../assets/images/Subtract_yellow_md.svg';
import { ReactComponent as BackgroundSmallSvg } from '../../assets/images/Subtract_yellow_sm.svg';
import { MaoriLanguage } from '../Accessibility/MaoriLanguage';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionLabel,
  AccordionPanel,
} from '../Accordion/Accordion';
import { recordCustomEvents } from '../Analytics/RecordEvents';
import { BackgroundBlockRoot } from '../AnimatedBackground/AnimatedBackground';
import { Block } from '../Block/Block';
import { Box } from '../Box/Box';
import { LinkButton } from '../Button/Button';
import { ScrollToTopButton } from '../Button/ScrollToTopButton';
import { ButtonLink } from '../ButtonLink/ButtonLink';
import { Container4 } from '../Container/Container2';
import { FooterNavigation } from '../FooterNavigation/FooterNavigation';
import { GlobalHeader } from '../GlobalHeader/GlobalHeader';
import { Head } from '../Head/Head';
import { HorizontalSeparator } from '../HorizontalSeperator/HorizontalSeparator';
import { LandingSections } from '../LandingContextProvider/LandingContextProvider';
import { useLandingContext } from '../LandingContextProvider/useLandingContext';
import { Body2, Body4, Body5, H1, Ul } from '../Typography/Typography';

import { useAuth } from '@/features/auth';
import { Navigation } from '@/features/iwiAffiliationForm/components/Navigation';

const BACKGROUND_IMAGE_MAPPINGS: {
  [key: string]: { small: string; large: string };
} = {
  pango: {
    small: '/backgrounds/background-pango-small.png',
    large: '/backgrounds/background-pango.jpg',
  },
};

export const Questions = () => {
  let previousStep = 'self';
  if (window.history.state) {
    if (window.history.state.usr) {
      previousStep = window.history.state && window.history.state?.usr;
    }
  }

  const [isHover, setIsHover] = useState<boolean>(false);
  const [isHoverDiv2, setIsHoverDiv2] = useState<boolean>(false);

  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuth();
  /* We refocus the main title when using the back to top button */
  const mainTitleRef = useRef<HTMLHeadingElement>(null);
  const {
    sectionConfig: { textColor, blockBackgroundVariant },
    currentSection,
    setCurrentSection,
  } = useLandingContext();

  const handleInViewChange = (inView: boolean, sectionId: LandingSections) => {
    if (inView && sectionId !== currentSection) {
      setCurrentSection(sectionId);
    }
  };
  const isDesktop = useBreakpoint(up('md'));
  const currentSize = isDesktop ? 'large' : 'small';
  const isDesktopLg = useMediaQuery('(min-width: 1457px)');
  // const isDesktopLg = useBreakpoint(up('lg'));
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Head />

      <GlobalHeader />

      {/* <PrimaryNavigation /> */}

      <main>
        {!isAuthenticated && (
          <Box
            position={{ _: 'relative', md: 'fixed' }}
            display="flex"
            justifyContent="space-between"
            top={{ md: 32 }}
            right={{ _: 0, md: 32 }}
            bottom={{ md: 'unset' }}
            left={{ _: 0, md: 'unset' }}
            px={{ _: 24, md: 0 }}
            py={{ _: 12, md: 0 }}
            width={{ _: '100%', md: 'auto' }}
            flexShrink={0}
            flexGrow={0}
            zIndex="11"
            flexDirection="column"
          >
            {isDesktop && (
              <>
                <LinkButton
                  label="Get started"
                  to="/signin"
                  dataCy="get-started-button"
                  variant={blockBackgroundVariant === 'dark' && isDesktop ? 'light' : 'dark'}
                />
              </>
            )}
            <Box textAlign="end" mt="24px">
              <ButtonLink
                color={textColor}
                onClick={() => {
                  recordCustomEvents('login_with_mha', { login_with_mha: true });
                  login();
                }}
              >
                Sign in
              </ButtonLink>
            </Box>
          </Box>
        )}
        {/* <AnimatedBackground /> */}
        <BackgroundBlockRoot
          data-cy="background-block-root"
          key="harakeke"
          backgroundColor="harakeke"
          backgroundImageUrl={BACKGROUND_IMAGE_MAPPINGS['pango'][currentSize]}
          active={true}
        />
        <Block
          inViewThreshold={currentSection === LandingSections.pataiContinued ? 1 : undefined}
          variant="light"
          onInViewChange={(inView) => {
            if (inView) {
              handleInViewChange(inView, LandingSections.pataiContinued);
            }
          }}
        >
          <Container4>
            <Navigation
              title="Back"
              goToPreviousStep={() =>
                previousStep === 'benefits'
                  ? navigate('/benefits')
                  : previousStep === 'about'
                  ? navigate('/about')
                  : navigate('/')
              }
              labelled-by="questions"
              padding="0px"
              marginTop={{ _: '72px' }}
            >
              <Box marginTop="40px" marginBottom={{ _: '32px', xxl: '72px' }} width="100%" maxWidth={{ md: 920 }}>
                <H1 as="h2" color={textColor} textAlign={{ _: 'left', md: 'center' }}>
                  <MaoriLanguage>Pātai</MaoriLanguage>
                </H1>
                <HorizontalSeparator color={textColor} justifyContent={{ _: 'left', md: 'center' }} />

                <Accordion collapsible>
                  <AccordionItem>
                    <AccordionButton>
                      <AccordionLabel>Who is collecting my data?</AccordionLabel>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel>
                      <Body2 mb="1rem">
                        The collection, sharing, access to and use of iwi affiliation data for iwi, will initially be
                        managed by the Ministry of Health.
                      </Body2>
                      <Body2 mb="1rem">
                        There is a multi-party governance group, including iwi representation, empowered to recognise
                        and respect <MaoriLanguage>Māori</MaoriLanguage> perspectives, to ensure the data requirements
                        are consistent with the purposes outlined at the time of collection, and that future
                        developments are designed to meet the key goal of iwi-led data decisions for iwi.
                      </Body2>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <AccordionLabel>Why are you collecting it?</AccordionLabel>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel>
                      <Body2 mb="1rem">
                        This data is being collected to provide iwi and Ministry of Health with information to help
                        improve the health and wellbeing of <MaoriLanguage>Māori</MaoriLanguage>.
                      </Body2>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <AccordionLabel>How will my data be used?</AccordionLabel>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel>
                      <Body2 mb="1rem">
                        We respect an individual’s right to autonomy and control of their information. All data shared
                        will be used to:
                      </Body2>
                      <Body2 as="div" mb="1rem">
                        <Ul>
                          <li>
                            Improve <MaoriLanguage>Māori</MaoriLanguage> health and well-being through more informed
                            decision making.
                          </li>
                          <li>
                            Assist <MaoriLanguage>Māori</MaoriLanguage> to plan programmes and services and
                            interventions, policies to develop their own people.
                          </li>
                          <li>
                            Help the allocation of resources and funds to <MaoriLanguage>iwi</MaoriLanguage>.
                          </li>
                          <li>
                            Help in <MaoriLanguage>iwi</MaoriLanguage> planning and social and economic development.
                          </li>
                          <li>
                            Help central, regional, and local government agencies, as well as a range of NGOs,
                            researchers and <MaoriLanguage>iwi</MaoriLanguage> organisations to evaluate, monitor, plan
                            and provide services to <MaoriLanguage>iwi</MaoriLanguage>.
                          </li>
                        </Ul>
                      </Body2>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <AccordionLabel>
                        Will you share this data with my <MaoriLanguage>Iwi</MaoriLanguage>?
                      </AccordionLabel>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel>
                      <Body2 mb="1rem">
                        Absolutely. This information belongs to you and the <MaoriLanguage>iwi</MaoriLanguage> you{' '}
                        <MaoriLanguage>whakapapa</MaoriLanguage> to.
                      </Body2>
                      <Body2 mb="1rem">
                        All the information that is shared about descendants from different{' '}
                        <MaoriLanguage>iwi</MaoriLanguage>, will be shared with those <MaoriLanguage>iwi</MaoriLanguage>
                        . We see this as an opportunity for them to connect you and other people who share a similar{' '}
                        <MaoriLanguage>whakapapa</MaoriLanguage> to get a better understanding of where their{' '}
                        <MaoriLanguage>whānau</MaoriLanguage> are in <MaoriLanguage>Aotearoa</MaoriLanguage>.
                      </Body2>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <AccordionLabel>Who owns this information, and can I update it?</AccordionLabel>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel>
                      <Body2 mb="1rem">
                        This is your information. You own it and once you are logged in, you are able to update, edit or
                        delete your information at anytime.
                      </Body2>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <AccordionLabel>How will my information be stored?</AccordionLabel>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel>
                      <Body2 mb="1rem">
                        Data is a <MaoriLanguage>taonga</MaoriLanguage> and will be treated with respect. This data is
                        being stored in a secure, cloud-based database in Australia. Access to this database is very
                        limited and is only accessed by critical members of the project to analyse at a collective
                        level. As we design the next phase, and the transfer of this data back to{' '}
                        <MaoriLanguage>iwi</MaoriLanguage>-<MaoriLanguage>Māori</MaoriLanguage>, we will be guided by
                        our <MaoriLanguage>Māori</MaoriLanguage> data experts and governance group on how this data
                        continues to be stored, governed and accessed.
                      </Body2>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <AccordionLabel>
                        What if I don’t know the name of my <MaoriLanguage>iwi</MaoriLanguage>?
                      </AccordionLabel>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel>
                      <Body2 mb="1rem">
                        If you have forgotten the name of your <MaoriLanguage>iwi</MaoriLanguage>, we have provided a
                        map and a list that you can filter through the different regions with all{' '}
                        <MaoriLanguage>iwi</MaoriLanguage> from that <MaoriLanguage>rohe</MaoriLanguage>.
                      </Body2>
                      <Body2 mb="1rem">
                        If you still can’t remember your <MaoriLanguage>iwi</MaoriLanguage> after seeing the list, you
                        are able to talk to your <MaoriLanguage>whānau</MaoriLanguage> and come back to the website to
                        provide the information at another time.
                      </Body2>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
              <StyledOuterDiv>
                <Box
                  style={{
                    position: 'relative',
                  }}
                  onClick={() => navigate('/about', { state: { previousStep: 'questions' } })}
                  onMouseEnter={() => {
                    return setIsHover(true);
                  }}
                  onMouseLeave={() => {
                    return setIsHover(false);
                  }}
                >
                  {isDesktopLg ? (
                    <>
                      {!isHover ? (
                        <div>
                          <StyledBackgroundSvg />
                        </div>
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundHoverSvg />
                        </div>
                      )}
                      <StyledImageDiv>
                        <Box>
                          <StyledImg1 src="/backgrounds/whakapapa_about.png" alt="" />
                        </Box>
                        <StyledContentDiv>
                          <Body5 color="#333333">
                            <MaoriLanguage>Whakapapa</MaoriLanguage>{' '}
                          </Body5>{' '}
                          <Body4 color="#333333" marginLeft="6px">
                            {' '}
                            (About)
                          </Body4>
                        </StyledContentDiv>
                      </StyledImageDiv>
                    </>
                  ) : (
                    <>
                      {' '}
                      {!isHover ? (
                        <div
                        // style={{
                        //   position: 'relative',
                        // }}
                        >
                          <StyledBackgroundSmallSvg />
                        </div>
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundHoverSmallSvg />
                        </div>
                      )}
                      <StyledImageDiv>
                        <Box>
                          <StyledImg1 src="/backgrounds/whakapapa_about.png" alt="" />
                        </Box>
                        <StyledContentDiv>
                          <Body5 color="#333333">
                            <MaoriLanguage>Whakapapa</MaoriLanguage>{' '}
                          </Body5>{' '}
                          <Body4 color="#333333" marginLeft="6px">
                            {' '}
                            (About)
                          </Body4>
                        </StyledContentDiv>
                      </StyledImageDiv>
                    </>
                  )}
                </Box>
                <Box
                  style={{
                    position: 'relative',
                  }}
                  onClick={() => navigate('/benefits', { state: { previousStep: 'questions' } })}
                  onMouseEnter={() => {
                    return setIsHoverDiv2(true);
                  }}
                  onMouseLeave={() => {
                    return setIsHoverDiv2(false);
                  }}
                >
                  {isDesktopLg ? (
                    <>
                      {!isHoverDiv2 ? (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundSvg />
                        </div>
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundHoverSvg />
                        </div>
                      )}

                      <StyledImageDiv>
                        <Box>
                          <StyledImg src="/backgrounds/whainga_benefits.png" alt="" />
                        </Box>
                        <StyledContentDiv>
                          <Body5 color="#333333">
                            <MaoriLanguage>Whāinga</MaoriLanguage>{' '}
                          </Body5>{' '}
                          <Body4 color="#333333" marginLeft="6px">
                            {' '}
                            (Benefits)
                          </Body4>
                        </StyledContentDiv>
                      </StyledImageDiv>
                    </>
                  ) : (
                    <>
                      {!isHoverDiv2 ? (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundSmallSvg />
                        </div>
                      ) : (
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <StyledBackgroundHoverSmallSvg />
                        </div>
                      )}

                      <StyledImageDiv>
                        <Box>
                          <StyledImg src="/backgrounds/whainga_benefits.png" alt="" />
                        </Box>
                        <StyledContentDiv>
                          <Body5 color="#333333">
                            <MaoriLanguage>Whāinga</MaoriLanguage>{' '}
                          </Body5>{' '}
                          <Body4 color="#333333" marginLeft="6px">
                            {' '}
                            (Benefits)
                          </Body4>
                        </StyledContentDiv>
                      </StyledImageDiv>
                    </>
                  )}
                </Box>
              </StyledOuterDiv>
            </Navigation>
          </Container4>
        </Block>
        <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }} zIndex={10} mt="auto">
          <FooterNavigation />

          {!isDesktop && (
            <Box mt={32} display="flex" justifyContent={{ _: 'center' }} flexShrink={0} flexGrow={0}>
              <ScrollToTopButton focusRef={mainTitleRef} />
            </Box>
          )}
        </Box>
      </main>
    </>
  );
};

const StyledBackgroundSvg = styled(BackgroundSvg)`
  height: 377px;

  ${up('md')} {
    height: 377px;
  }

  ${up('lg')} {
    height: 314px;
  }
  ${up('xl')} {
    height: 314px;
  }
  ${up('xxl')} {
    height: 314px;
  }
`;

const StyledBackgroundHoverSvg = styled(BackgroundHoverSvg)`
  height: 377px;

  ${up('md')} {
    height: 377px;
  }

  ${up('lg')} {
    height: 314px;
  }
  ${up('xl')} {
    height: 314px;
  }
  ${up('xxl')} {
    height: 314px;
  }
`;
const StyledBackgroundSmallSvg = styled(BackgroundSmallSvg)`
  height: 377px;

  ${up('md')} {
    height: 314px;
  }

  ${up('lg')} {
    height: 314px;
  }
  ${up('xl')} {
    height: 314px;
  }
  ${up('xxl')} {
    height: 314px;
  }
`;

const StyledBackgroundHoverSmallSvg = styled(BackgroundHoverSmallSvg)`
  height: 377px;

  ${up('md')} {
    height: 377px;
  }

  ${up('lg')} {
    height: 314px;
  }
  ${up('xl')} {
    height: 314px;
  }
  ${up('xxl')} {
    height: 314px;
  }
`;

const StyledImageDiv = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 16px;
`;

const StyledOuterDiv = styled.div`
  // width: 100%;
  margin-bottom: 72px;
  margin-top: 32px;
  display: grid;
  grid-template-columns: 100%;

  gap: 16px;

  @media only screen and (min-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em 16px;
  }

  ${up('xl')} {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em 32px;
  }
  ${up('xxl')} {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em 32px;
  }
`;

const StyledContentDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  color: white;
  align-content: end;
  height: 62px;
  padding: 15px;
  @media only screen and (min-width: 991px) {
    padding: 15px;
  }

  ${up('xl')} {
    padding: 10px;
  }

  @media only screen and (min-width: 1600px) {
    padding: 10px;
  }
`;

const StyledImg1 = styled.img`
  width: 100%;
  height: 100%;
  object-position: 50% 10%;
  object-fit: cover;
  max-height: 295px;
  color: #333333;
  :hover {
    color: #666666;
  }
  ${up('md')} {
    max-height: 232px;
  }
  ${up('lg')} {
    max-height: 232px;
  }
  ${up('xl')} {
    max-height: 232px;
  }
  ${up('xxl')} {
    max-height: 232px;
  }
`;
const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-position: top center;
  object-fit: cover;
  max-height: 295px;
  color: #333333;
  :hover {
    color: #666666;
  }
  ${up('md')} {
    max-height: 232px;
  }
  ${up('lg')} {
    max-height: 232px;
  }
  ${up('xl')} {
    max-height: 232px;
  }
  ${up('xxl')} {
    max-height: 232px;
  }
`;

import { forwardRef, SyntheticEvent } from 'react';
import InputMask from 'react-input-mask';
import styled, { css } from 'styled-components';

import { InputError } from '../InputError/InputError';
import { Body2 } from '../Typography/Typography';

interface IDateOfBirthInputProps {
  id: string;
  value?: string;
  error: any;
  onChange: (event: SyntheticEvent) => void;
}

/**
 * Convert to use MaskedInput
 */
export const DateOfBirthInput = forwardRef<HTMLInputElement, IDateOfBirthInputProps>(
  ({ id, value, error, onChange }, ref) => {
    return (
      <DateOfBirthInputWrapper hasError={!!error}>
        <DateOfBirthLabel htmlFor={id}>
          <Body2>Date of birth</Body2>
        </DateOfBirthLabel>
        <ErrorBarWrapper>
          <InputRoot
            inputRef={ref}
            data-cy="date-of-birth"
            type="tel"
            id={id}
            value={value}
            onChange={(event: SyntheticEvent<HTMLInputElement>) => onChange(event)}
            mask="99/99/9999"
            placeholder="dd/mm/yyyy"
          />
          {!!error && <ErrorBar />}
        </ErrorBarWrapper>
        {!!error && <InputError>Please enter a valid date of birth</InputError>}
      </DateOfBirthInputWrapper>
    );
  },
);

DateOfBirthInput.displayName = 'DateOfBirthInput';

const DateOfBirthInputWrapper = styled.div<{ hasError: boolean }>`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  font-family: ${(props) => props.theme.fonts.body};
  width: 100%;

  ${({ hasError }) =>
    hasError &&
    css`
      input {
        border: 1px solid ${(props) => props.theme.colors.error};
      }
    `}
`;

const DateOfBirthLabel = styled.label`
  color: white;
  margin-bottom: 8px;
`;

const InputRoot = styled(InputMask)`
  background: ${(props) => props.theme.colors.ma};
  border: 1px solid rgba(191, 84, 34, 0.5);
  border-radius: 4px;
  padding: 8px 16px;
  height: 56px;
  font-family: Poppins;
  font-size: 1rem;
  font-size: clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.25)), 1.25rem);
  width: 100%;

  &:focus {
    background-color: ${(props) => props.theme.colors.white};
    border-color: ${(props) => props.theme.colors.pango};
  }

  ${(props) =>
    (typeof props.value == 'undefined' || props.value === 'dd/mm/yyyy') &&
    css`
      color: ${(props) => props.theme.colors.placeholder};
    `}
`;

export const ErrorBarWrapper = styled.div`
  position: relative;
`;

export const ErrorBar = styled.div`
  height: 6px;
  background: ${(props) => props.theme.colors.error};
  opacity: 0.5;
  width: 100%;
  position: absolute;
  bottom: 0px;
`;

// NOTE: This is a generated file, nothing you do here matters
// The source of the all.svg file is located at './components/Icon/all.svg'
// The script that generates this file is located at tools/svg-generator.js
// To rebuild this file run 'yarn run generate-svgs'

export const ICONS = {
  'button-background-active': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 56">
      <use xlinkHref="#button-background-active" />
    </svg>
  ),
  'button-background': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 56">
      <use xlinkHref="#button-background" />
    </svg>
  ),
  'ic-arrow-left': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-arrow-left" />
    </svg>
  ),
  'ic-arrow-right': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-arrow-right" />
    </svg>
  ),
  'ic-arrow-up': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-arrow-up" />
    </svg>
  ),
  'ic-button-center': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 56">
      <use xlinkHref="#ic-button-center" />
    </svg>
  ),
  'ic-button-left': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 56">
      <use xlinkHref="#ic-button-left" />
    </svg>
  ),
  'ic-button-right': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 56">
      <use xlinkHref="#ic-button-right" />
    </svg>
  ),
  'ic-checkbox-checked': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-checkbox-checked" />
    </svg>
  ),
  'ic-checkbox': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-checkbox" />
    </svg>
  ),
  'ic-clear': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-clear" />
    </svg>
  ),
  'ic-close': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#ic-close" />
    </svg>
  ),
  'ic-edit': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <use xlinkHref="#ic-edit" />
    </svg>
  ),
  'ic-error': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <use xlinkHref="#ic-error" />
    </svg>
  ),
  'ic-expand': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#ic-expand" />
    </svg>
  ),
  'ic-hr-small': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 6">
      <use xlinkHref="#ic-hr-small" />
    </svg>
  ),
  'ic-info-blue': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-info-blue" />
    </svg>
  ),
  'ic-info': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <use xlinkHref="#ic-info" />
    </svg>
  ),
  'ic-list-item-white': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 10">
      <use xlinkHref="#ic-list-item-white" />
    </svg>
  ),
  'ic-list-item': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 10">
      <use xlinkHref="#ic-list-item" />
    </svg>
  ),
  'ic-quote': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 80">
      <use xlinkHref="#ic-quote" />
    </svg>
  ),
  'ic-radio-checked': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-radio-checked" />
    </svg>
  ),
  'ic-radio': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-radio" />
    </svg>
  ),
  'ic-scroll-down': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 32">
      <use xlinkHref="#ic-scroll-down" />
    </svg>
  ),
  'ic-select-down': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 9">
      <use xlinkHref="#ic-select-down" />
    </svg>
  ),
  'ic-success': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-success" />
    </svg>
  ),
  'ic-zoom-out': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#ic-zoom-out" />
    </svg>
  ),
  'toggle-background-left': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 57">
      <use xlinkHref="#toggle-background-left" />
    </svg>
  ),
  'toggle-background-right': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 57">
      <use xlinkHref="#toggle-background-right" />
    </svg>
  ),
  'div-left': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 377">
      <use xlinkHref="#div-left" />
    </svg>
  ),
  'div-center': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 377">
      <use xlinkHref="#div-center" />
    </svg>
  ),
  'div-right': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 377">
      <use xlinkHref="#div-right" />
    </svg>
  ),
};

import { PropsWithChildren, HTMLProps } from 'react';
import styled from 'styled-components';

export const InputError = styled.span.attrs({
  'aria-live': 'polite',
})`
  margin-top: 8px;
  font-weight: normal;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 14px;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    background-image: url('/icons/ic-error.svg');
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    flex-shrink: 0;
  }
`;

export const StyledErrorBanner = styled(InputError)`
  background: rgba(255, 253, 251, 0.1);
  align-items: flex-start;
  margin: 0;
  line-height: 1.55;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid white;
`;

/**
 * StyledErrorBanner uses flexbox, and if the children has some text with a
 * <MaoriLanguage /> span or similar, then it would cause it to wrap weirdly.
 * Wrapping the children inside a div keeps them all contained.
 */
export const ErrorBanner = ({ children }: PropsWithChildren<HTMLProps<HTMLDivElement>>) => {
  return (
    <StyledErrorBanner>
      <div>{children}</div>
    </StyledErrorBanner>
  );
};

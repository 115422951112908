import lottie from 'lottie-web';
import React, { useEffect, useRef, useState } from 'react';
import { useVisuallyHidden } from 'react-aria';
import { useInView } from 'react-intersection-observer';
import { Navigate, useNavigate } from 'react-router';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

import externalIcon_12px from '../assets/icons/externalIcon_12px.svg';
import externalLink from '../assets/icons/externalLink.svg';
import { ReactComponent as BackgroundHoverSvg } from '../assets/images/Subtract_grey_hover_lg.svg';
import { ReactComponent as BackgroundSvg } from '../assets/images/Subtract_grey_lg.svg';
import { ReactComponent as TeWhatuOraLogo } from '../assets/logos/TeWhatuOraLogo.svg';
import { ReactComponent as TeWhatuOraLogoBlack } from '../assets/logos/TeWhatuOraLogoBlack.svg';
import { MaoriLanguage } from '../components/Accessibility/MaoriLanguage';
import { AnimatedBackground } from '../components/AnimatedBackground/AnimatedBackground';
import { AnimatedMainTitle } from '../components/AnimatedMainTitle/AnimatedMainTitle';
import { AnimatedMainTitleSmall } from '../components/AnimatedMainTitle/AnimatedMainTitleSmall';
import { Block, BlockBackgroundVariant } from '../components/Block/Block';
import { Box } from '../components/Box/Box';
import { LinkButton } from '../components/Button/Button';
import { ScrollToTopButton } from '../components/Button/ScrollToTopButton';
import { Container2, Container3 } from '../components/Container/Container2';
import { FooterNavigation } from '../components/FooterNavigation/FooterNavigation';
import { GlobalHeader } from '../components/GlobalHeader/GlobalHeader';
import { Head } from '../components/Head/Head';
import { HorizontalSeparator } from '../components/HorizontalSeperator/HorizontalSeparator';
// import { Image } from '../components/Image/Image';
import { LandingSections } from '../components/LandingContextProvider/LandingContextProvider';
import { useLandingContext } from '../components/LandingContextProvider/useLandingContext';
// import { LightA } from '../components/Links/Links';
import { NavigationItems } from '../components/PrimaryNavigation/PrimaryNavigation';
// import { Quote } from '../components/Quote/Quote';
import { Body, Body4, Body5, H1, LandingH2 } from '../components/Typography/Typography';
import { defaultTheme } from '../styles/themes/default';

import { recordCustomEvents } from '@/components/Analytics/RecordEvents';
import { Button2 } from '@/components/Button/Button2';
import { ButtonLink } from '@/components/ButtonLink/ButtonLink';
import { useAuth, useUserInfo } from '@/features/auth';
import { useRealMeError } from '@/hooks/useRealMeError';

export interface ISectionConfig {
  backgroundColor: keyof typeof defaultTheme.colors;
  blockBackgroundVariant: BlockBackgroundVariant;
  navigationItem: NavigationItems;
}

const Landing = () => {
  useRealMeError();
  const { isAuthenticated, login } = useAuth();
  const { data } = useUserInfo();
  const confidence_level = data && data['urn:login:health:nz:claims:confidence_level'];
  const navigate = useNavigate();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isHoverDiv2, setIsHoverDiv2] = useState<boolean>(false);
  const [isHoverDiv3, setIsHoverDiv3] = useState<boolean>(false);

  const {
    sectionConfig: { textColor, blockBackgroundVariant },
    currentSection,
    setCurrentSection,
  } = useLandingContext();
  const { visuallyHiddenProps } = useVisuallyHidden();
  const container = useRef(null);

  const exploreRef = useRef(null);
  const scrollToExplore = () => exploreRef.current.scrollIntoView({ behavior: 'smooth' });
  /* We refocus the main title when using the back to top button */
  const mainTitleRef = useRef<HTMLHeadingElement>(null);

  /* We show or hide the fixed get started button on desktop */
  const isDesktop = useBreakpoint(up('md'));

  const { inView: getStartedButtonInView, ref: getStartedButtonRef } = useInView({
    threshold: 0.2,
    initialInView: true,
  });

  const handleInViewChange = (inView: boolean, sectionId: LandingSections) => {
    if (inView && sectionId !== currentSection) {
      setCurrentSection(sectionId);
    }
  };
  useEffect(() => {
    lottie.loadAnimation({
      animationData: require('../assets/lottie/scroll.json'),
      autoplay: true,
      container: container.current,
      loop: true,
      renderer: 'svg',
    });
  }, []);

  return (
    <>
      {confidence_level === '1' || confidence_level === '1N' ? (
        <>
          <Navigate to="/about" />
        </>
      ) : (
        <>
          <Head />

          <GlobalHeader />

          {/* <PrimaryNavigation /> */}

          <main>
            {!isAuthenticated && !isDesktop && (
              <Box
                position="relative"
                display="flex"
                justifyContent="space-between"
                top={{ md: 32 }}
                right={{ _: 0, md: 32 }}
                bottom={{ md: 'unset' }}
                left={{ _: 0, md: 'unset' }}
                px={{ _: 24, md: 0 }}
                py={{ _: 12, md: 0 }}
                width={{ _: '100%', md: 'auto' }}
                flexShrink={0}
                flexGrow={0}
                zIndex="11"
                flexDirection="column"
              >
                <Box textAlign="end" mt="8px">
                  <ButtonLink
                    color={textColor}
                    onClick={() => {
                      recordCustomEvents('login_with_mha', { login_with_mha: true });
                      login();
                    }}
                  >
                    Sign in
                  </ButtonLink>
                </Box>
              </Box>
            )}
            <AnimatedBackground />

            {/* Intro */}
            <Block
              id={NavigationItems.intro}
              inViewThreshold={currentSection === LandingSections.about ? 1 : 0.8}
              variant="dark"
              onInViewChange={(inView) => {
                if (inView) {
                  handleInViewChange(inView, LandingSections.intro);
                }
              }}
            >
              <Container2>
                <Box
                  maxWidth={{ md: '920px' }}
                  pt={80}
                  pb={40}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  flexShrink={0}
                  flexGrow={0}
                >
                  <h1 color={textColor} tabIndex={-1} ref={mainTitleRef} {...visuallyHiddenProps}>
                    <MaoriLanguage>Nau mai ki Tātai</MaoriLanguage>
                  </h1>
                  {isDesktop ? (
                    <AnimatedMainTitle color={textColor} animationLength={0.8} animationDelay={0.25} />
                  ) : (
                    <AnimatedMainTitleSmall color={textColor} animationLength={0.8} animationDelay={0.25} />
                  )}
                  {isAuthenticated && (
                    <H1 mt={{ _: 16, md: 32 }} color={textColor}>
                      {window.sessionStorage.getItem('preferredName')
                        ? window.sessionStorage.getItem('preferredName')
                        : data?.given_name + (data.middle_name ? ' ' + data.middle_name : '') + ' ' + data?.family_name}
                    </H1>
                  )}
                  <HorizontalSeparator justifyContent="center" color={textColor} />
                  <Box>
                    <Body color={textColor} mb={{ _: '16px', md: '18px' }} textAlign="center" fontWeight="400">
                      <MaoriLanguage>Tātai</MaoriLanguage> was created to gather <MaoriLanguage>Māori</MaoriLanguage>{' '}
                      iwi affiliations to contribute to a robust health dataset that can be used by iwi, for iwi.
                    </Body>

                    <LandingH2 color={textColor} textAlign="center">
                      Record your iwi affiliations:
                    </LandingH2>
                  </Box>
                  {!isAuthenticated && (
                    <Box mt={24}>
                      <LinkButton
                        label="Get started"
                        to="/signin"
                        dataCy="get-started-button"
                        ref={getStartedButtonRef}
                      />
                    </Box>
                  )}
                  {!isAuthenticated && (
                    <Box
                      mt={{ _: 32, md: 48 }}
                      display="flex"
                      flexDirection="column"
                      textAlign="center"
                      alignItems="center"
                    >
                      <LandingH2 mb="16px" color={textColor}>
                        Manage iwi affiliations:
                      </LandingH2>

                      {!isDesktop ? (
                        <Button2
                          variant="light"
                          type="button"
                          onClick={() => {
                            recordCustomEvents('login_with_mha', { login_with_mha: true });
                            login();
                          }}
                          label="Login with"
                          label2=" My Health Account"
                          icon={externalIcon_12px}
                          maxWidth="296px"
                          ref={getStartedButtonRef}
                        />
                      ) : (
                        <Button2
                          variant="light"
                          type="button"
                          onClick={() => {
                            recordCustomEvents('login_with_mha', { login_with_mha: true });
                            login();
                          }}
                          label="Login with"
                          label2=" My Health Account"
                          icon={externalLink}
                          maxWidth="356px"
                          ref={getStartedButtonRef}
                        />
                      )}
                      <Box mt={{ _: 32, md: 48 }}>
                        {blockBackgroundVariant === 'dark' ? (
                          <TeWhatuOraLogo aria-hidden />
                        ) : (
                          <TeWhatuOraLogoBlack aria-hidden />
                        )}
                      </Box>
                    </Box>
                  )}
                  {isAuthenticated && (
                    <Box display="flex" flexDirection="column" alignItems="center" mt={{ _: 16, md: 32 }}>
                      <LinkButton to="/manage" label="View my iwi affiliations" />
                    </Box>
                  )}
                </Box>
                {/* <div ref={container} id="animation-container"></div> */}
                <Box ref={container} id="animation-container" onClick={() => scrollToExplore()}></Box>
              </Container2>
            </Block>

            {/* Explore */}
            <div ref={exploreRef}>
              <Block
                inViewThreshold={currentSection === LandingSections.aboutContinued ? 1 : undefined}
                variant="dark"
                onInViewChange={(inView) => {
                  if (inView) {
                    handleInViewChange(inView, LandingSections.aboutContinued);
                  }
                }}
              >
                <Container3>
                  <Box mt={40} mb={16}>
                    <Box mb={{ _: '32px', xxl: '76px' }}>
                      <H1 color={textColor} as="h2" textAlign={{ md: 'center' }}>
                        Explore
                      </H1>

                      <HorizontalSeparator color={textColor} justifyContent={{ _: 'flex-start', md: 'center' }} />
                    </Box>

                    <StyledOuterDiv>
                      <Box
                        style={{
                          position: 'relative',
                        }}
                        onClick={() => navigate('/about', { state: { previousStep: 'landing' } })}
                        onMouseEnter={() => {
                          return setIsHover(true);
                        }}
                        onMouseLeave={() => {
                          return setIsHover(false);
                        }}
                      >
                        {!isHover ? (
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <StyledBackgroundSvg />
                          </div>
                        ) : (
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <StyledBackgroundHoverSvg />
                          </div>
                        )}

                        <StyledImageDiv>
                          <Box>
                            <StyledImg1 src="/backgrounds/whakapapa_about.png" alt="" />
                          </Box>
                          <StyledContentDiv>
                            <Body5 color="#FFFDFB">
                              <MaoriLanguage>Whakapapa</MaoriLanguage>{' '}
                            </Body5>{' '}
                            <Body4 color="#FFFDFB" marginLeft="6px">
                              {' '}
                              (About)
                            </Body4>
                          </StyledContentDiv>
                        </StyledImageDiv>
                      </Box>
                      <Box
                        style={{
                          position: 'relative',
                        }}
                        onClick={() => navigate('/benefits', { state: { previousStep: 'landing' } })}
                        onMouseEnter={() => {
                          return setIsHoverDiv2(true);
                        }}
                        onMouseLeave={() => {
                          return setIsHoverDiv2(false);
                        }}
                      >
                        {!isHoverDiv2 ? (
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <StyledBackgroundSvg />
                          </div>
                        ) : (
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <StyledBackgroundHoverSvg />
                          </div>
                        )}

                        <StyledImageDiv>
                          <Box>
                            <StyledImg1 src="/backgrounds/whainga_benefits.png" alt="" />
                          </Box>
                          <StyledContentDiv>
                            <Body5 color="#FFFDFB">
                              <MaoriLanguage>Whāinga</MaoriLanguage>{' '}
                            </Body5>{' '}
                            <Body4 color="#FFFDFB" marginLeft="6px">
                              {' '}
                              (Benefits)
                            </Body4>
                          </StyledContentDiv>
                        </StyledImageDiv>
                      </Box>
                      <Box
                        style={{
                          position: 'relative',
                        }}
                        onClick={() => navigate('/questions', { state: { previousStep: 'landing' } })}
                        onMouseEnter={() => {
                          return setIsHoverDiv3(true);
                        }}
                        onMouseLeave={() => {
                          return setIsHoverDiv3(false);
                        }}
                      >
                        {!isHoverDiv3 ? (
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <StyledBackgroundSvg />
                          </div>
                        ) : (
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <StyledBackgroundHoverSvg />
                          </div>
                        )}

                        <StyledImageDiv>
                          <Box>
                            <StyledImg1 src="/backgrounds/patai_questions.png" alt="" />
                          </Box>
                          <StyledContentDiv>
                            <Body5 color="#FFFDFB">
                              <MaoriLanguage>Pātai</MaoriLanguage>{' '}
                            </Body5>{' '}
                            <Body4 color="#FFFDFB" marginLeft="6px">
                              {' '}
                              (Questions)
                            </Body4>
                          </StyledContentDiv>
                        </StyledImageDiv>
                      </Box>
                    </StyledOuterDiv>
                  </Box>
                </Container3>
              </Block>
            </div>

            <Box position={{ _: 'static' }} bottom={0} width={{ md: '100%' }} zIndex={10} mt="auto">
              <FooterNavigation />

              {!isDesktop && (
                <Box mt={32} display="flex" justifyContent={{ _: 'center' }} flexShrink={0} flexGrow={0}>
                  <ScrollToTopButton focusRef={mainTitleRef} />
                </Box>
              )}
            </Box>
            {/* </Footer> */}

            {/* Footer call to action */}
            {!getStartedButtonInView && (
              <Box
                backgroundColor={{ _: 'white', md: 'initial' }}
                position="fixed"
                display="flex"
                justifyContent="space-between"
                top={{ md: 32 }}
                right={{ _: 0, md: 32 }}
                bottom={{ md: 'unset' }}
                left={{ _: 0, md: 'unset' }}
                px={{ _: 24, md: 0 }}
                py={{ _: 12, md: 0 }}
                width={{ _: '100%', md: 'auto' }}
                flexShrink={0}
                flexGrow={0}
                zIndex="11"
                flexDirection="column"
              >
                <LinkButton
                  label="Get started"
                  to="/signin"
                  dataCy="get-started-button"
                  variant={blockBackgroundVariant === 'light' && isDesktop ? 'light' : 'dark'}
                />
                <Box textAlign="end" mt="24px">
                  <ButtonLink
                    color={textColor}
                    onClick={() => {
                      recordCustomEvents('login_with_mha', { login_with_mha: true });
                      login();
                    }}
                  >
                    Sign in
                  </ButtonLink>
                </Box>
              </Box>
            )}
          </main>
        </>
      )}
    </>
  );
};

const StyledBackgroundSvg = styled(BackgroundSvg)`
  height: 377px;

  ${up('md')} {
    height: 377px;
  }

  ${up('lg')} {
    height: 404px;
  }
  ${up('xl')} {
    height: 404px;
  }
  ${up('xxl')} {
    height: 404px;
  }
  @media only screen and (min-width: 1401px) {
    height: 404px;
  }
  @media only screen and (min-width: 1920px) {
    height: 656px;
  }
`;

const StyledBackgroundHoverSvg = styled(BackgroundHoverSvg)`
  height: 377px;

  ${up('md')} {
    height: 377px;
  }

  ${up('lg')} {
    height: 404px;
  }
  ${up('xl')} {
    height: 404px;
  }
  ${up('xxl')} {
    height: 404px;
  }
  @media only screen and (min-width: 1401px) {
    height: 404px;
  }
  @media only screen and (min-width: 1920px) {
    height: 656px;
  }
`;

const StyledImageDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 24px;

  ${up('md')} {
    padding: 24px;
  }

  ${up('lg')} {
    padding: 16px;
  }
  ${up('xl')} {
    padding: 16px;
  }
  ${up('xxl')} {
    padding: 16px;
  }
  @media only screen and (min-width: 1401px) {
    padding: 16px;
  }
  @media only screen and (min-width: 1920px) {
    padding: 24px;
  }
`;

const StyledOuterDiv = styled.div`
  width: 100%;
  margin-bottom: 150px;
  margin-top: 32px;
  display: grid;
  grid-template-columns: 100%;

  gap: 16px;
  @media only screen and (min-width: 915px) {
    grid-template-columns: 100%;
  }
  @media only screen and (max-width: 1056px) {
    grid-template-columns: 100%;
  }
  ${up('lg')} {
    grid-template-columns: 100%;
  }

  ${up('xl')} {
    grid-template-columns: 100%;
    gap: 1em 32px;
  }
  ${up('xxl')} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em 32px;
  }
`;

const StyledContentDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  align-content: end;
  height: 62px;
  padding: 5px;

  ${up('sm')} {
    height: 62px;
    padding: 5px;
    align-items: center;
  }
  ${up('md')} {
    padding: 5px;
    height: auto;
    align-items: baseline;
  }
  @media only screen and (min-width: 991px) {
    padding: 5px;
    align-items: baseline;
  }

  ${up('lg')} {
    padding: 5px;
    height: auto;
    align-items: baseline;
  }
  ${up('xl')} {
    padding: 5px;
    height: auto;
    align-items: baseline;
  }

  // @media only screen and (min-width: 1600px) {
  //   padding: 18px;
  // height: auto;
  // }

  ${up('xxl')} {
    padding: 5px;
    height: auto;
    align-items: baseline;
  }
  @media only screen and (min-width: 1920px) {
    padding: 18px;
    height: 81px;
    align-items: baseline;
  }
`;

const StyledImg1 = styled.img`
  width: 100%;
  height: 100%;
  object-position: top center;
  object-fit: cover;
  max-height: 295px;
  color: #333333;
  :hover {
    color: #666666;
  }
  ${up('md')} {
    max-height: 335px;
  }
  ${up('lg')} {
    max-height: 335px;
  }
  ${up('xl')} {
    max-height: 335px;
  }
  ${up('xxl')} {
    max-height: 335px;
  }
  @media only screen and (min-width: 1920px) {
    max-height: 549px;
  }
`;

export default Landing;

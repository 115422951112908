import { parsePhoneNumber } from 'libphonenumber-js';

export const PHONE_MAX_LENGTH = 16;

export const isPhoneValid = (phone?: string) => {
  if (!phone) {
    return false;
  }

  try {
    const numberParsed = parsePhoneNumber(phone, 'NZ');
    return numberParsed.isValid();
  } catch (e) {
    return false;
  }
};

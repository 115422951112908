import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { GlobalHeader } from '../components/GlobalHeader/GlobalHeader';
import { Head } from '../components/Head/Head';

import { EditIwiMap } from '@/features/editIwiMap';

const FormEditIwi = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}>
    <Head />

    <GlobalHeader variantOverride="dark" />

    <main>
      <EditIwiMap />
    </main>
  </GoogleReCaptchaProvider>
);
export default FormEditIwi;

import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

// import { TIwiSubmission } from '../types';

import { useAccessTokenPromise, useAuth } from '@/features/auth';
// interface ResponseMessage {
//   message: string;
// }

const GET_API_PATH = process.env.REACT_APP_API_URL + '/' + process.env.REACT_APP_GET_AUTHENTICATED_IWI_API_PATH;
export const useIwiSubmissions = () => {
  // const queryClient = useQueryClient();
  const { isAuthenticated } = useAuth();
  const getAccessToken = useAccessTokenPromise();
  const { data, isSuccess, status } = useQuery({
    queryKey: 'getSubmissions',
    staleTime: Infinity,
    cacheTime: 0,
    queryFn: async (): Promise<AxiosResponse<{ responseObj: any }>> =>
      axios.get(GET_API_PATH, {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }),
    enabled: isAuthenticated,
    select: (response) => {
      return response.data;
    },
  });

  // queryClient.invalidateQueries({ queryKey: 'getSubmissions' });

  if (data && data.responseObj) {
    return { response: data.responseObj, isSuccess: isSuccess, status: status };
  }
  return { response: null, isSuccess: false };
};

import React from 'react';
import { useVisuallyHidden } from 'react-aria';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';

import { Box } from '../Box/Box';
import Icon from '../Icon/Icon';
import { Body, QuotePrint } from '../Typography/Typography';

interface IQuoteProps extends ColorProps {
  cite: string;
  hideIcon?: boolean;
}

export const Quote = (props: React.PropsWithChildren<IQuoteProps>) => {
  const { visuallyHiddenProps } = useVisuallyHidden();

  const { children, cite, hideIcon, ...otherProps } = props;

  return (
    <QuoteRoot {...otherProps} color={otherProps.color as string}>
      {!hideIcon && (
        <Box display="flex" justifyContent="center" flexShrink={0} flexGrow={0}>
          <Icon name="ic-quote" width={{ _: 60, md: 120 }} height={{ _: 40, md: 80 }} color={props.color?.toString()} />
        </Box>
      )}
      <span {...visuallyHiddenProps}>Quote by {cite}:</span>
      <QuoteBody>
        <QuotePrint as="p" m={0}>
          {children}
        </QuotePrint>
      </QuoteBody>
      <Body m={0} as="figcaption">
        &mdash; {cite}
      </Body>
    </QuoteRoot>
  );
};

const QuoteRoot = styled.figure<any>`
  ${color};

  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
`;

const QuoteBody = styled.blockquote`
  margin: 24px 0 16px;

  ${up('md')} {
    margin: 40px 0 24px;
  }
`;

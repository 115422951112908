import { useState } from 'react';
import { useMutation } from 'react-query';

import { makePostRequest } from '../../../tools/ajax';
import { FormSteps, IIwiAffiliationFormData } from '../components/IwiAffiliationFormContext';
import { IsProvidingAddressResponses } from '../types';

import { useAccessTokenPromise, useAuth } from '@/features/auth';

const UNAUTHENTICATED_API_PATH = process.env.REACT_APP_API_PATH || 'iwi-affiliation';
const AUTHENTICATED_API_PATH =
  process.env.REACT_APP_BEHALF_OF_AUTHENTICATED_API_PATH || 'v3/behalf-of-iwi-affiliations';

interface IUseSubmitIwiAffiliationProps {
  onSuccessOfSubmission?: () => void;
  onErrorOfSubmission?: () => void;
}

export const useSubmitIwiAffiliation = ({
  onSuccessOfSubmission,
  onErrorOfSubmission,
}: IUseSubmitIwiAffiliationProps) => {
  let API_PATH = UNAUTHENTICATED_API_PATH;
  const getAccessToken = useAccessTokenPromise();
  const { isAuthenticated } = useAuth();
  // const getAccessToken = useAccessTokenPromise();
  if (isAuthenticated) {
    API_PATH = AUTHENTICATED_API_PATH;
  }

  const submitIwiAffiliationApi = async (data: IIwiAffiliationFormData) =>
    makePostRequest(
      API_PATH,
      {
        ...data[FormSteps.MAORI_DESCENT],
        affiliatedIwi: JSON.stringify(data[FormSteps.IWI_LOOKUP].affiliatedIwi),
        ...data[FormSteps.YOUR_DETAILS],

        // If gender has been provided as 'prefer not to say' we need to set it to undefined as they're equivalent
        // https://mohits.atlassian.net/browse/TIA-34
        // gender:
        //   data[FormSteps.YOUR_DETAILS].gender === Gender.PREFER_NOT_TO_SAY
        //     ? undefined
        //     : data[FormSteps.YOUR_DETAILS].gender,

        // This is temporary until we figure out why the API is converting objects to '[object Object]' instead of a normal object.
        address:
          data[FormSteps.YOUR_DETAILS].isProvidingAddress === IsProvidingAddressResponses.YES
            ? JSON.stringify(data[FormSteps.YOUR_DETAILS].address)
            : undefined,

        ...data[FormSteps.CONTACT_DETAILS],
        ...data[FormSteps.SUMMARY],
      },
      getAccessToken,
    );

  const { mutate, isLoading } = useMutation(submitIwiAffiliationApi);
  const [showSubmissionError, setShowSubmissionError] = useState<boolean>();

  const submitIwiAffiliation = (data: IIwiAffiliationFormData) => {
    mutate(data, {
      onSuccess: () => {
        setShowSubmissionError(false);
        if (onSuccessOfSubmission) {
          onSuccessOfSubmission();
        }
      },
      onError: () => {
        setShowSubmissionError(true);
        if (onErrorOfSubmission) {
          onErrorOfSubmission();
        }
      },
    });
  };

  return {
    isLoading,
    submitIwiAffiliation,
    showSubmissionError,
    setShowSubmissionError,
  };
};

import { useEffect, useState } from 'react';

import { Iwi } from '../types';

import { IdToLabel } from '@/tools/constants/iwi';

interface IUseIwiLookupProps {
  lookupData: Iwi[];
  onSubmit: (selectedItems: Iwi[]) => void;
}

export const doesIwiMatch = (selectedIwi: Iwi, iwiFromList: Iwi) =>
  selectedIwi.id === iwiFromList.id && selectedIwi.label === iwiFromList.label;

export const useIwiLookup = ({ lookupData, onSubmit }: IUseIwiLookupProps) => {
  const [selectedItems, setSelectedItems] = useState<Iwi[]>(lookupData);
  const [recentlyAdded, setRecentlyAdded] = useState<Iwi | undefined>();
  const [recentlyRemoved, setRecentlyRemoved] = useState<Iwi | undefined>();
  const [region, setRegion] = useState<IdToLabel | undefined>();
  const [showErrors, setShowErrors] = useState<boolean>(false);

  useEffect(() => {
    if (selectedItems.length) {
      setShowErrors(false);
    }
  }, [selectedItems.length]);

  const handleItemSelection = (iwi: Iwi) => {
    const selectedItemIndex = selectedItems?.findIndex((selectedItem: Iwi) => doesIwiMatch(iwi, selectedItem));

    if (selectedItemIndex !== -1) {
      const selectedItemsCopy = [...selectedItems];
      const removedIwi = selectedItemsCopy?.splice(selectedItemIndex, 1);
      setSelectedItems(selectedItemsCopy);
      setRecentlyRemoved(removedIwi[0]);
    } else {
      selectedItems.push(iwi);
      setSelectedItems(selectedItems);
      setRecentlyAdded(iwi);
    }
  };

  const submitWrapper = (e: any) => {
    e.preventDefault();

    // Removing for now to allow skipping
    // if (selectedItems.length === 0) {
    //   setShowErrors(true);
    //   return;
    // }

    onSubmit(selectedItems);
  };

  return {
    selectedItems,
    handleItemSelection,
    region,
    setRegion,
    submitWrapper,
    showErrors,
    recentlyAdded,
    recentlyRemoved,
    setSelectedItems,
  };
};

type windowWithGTM = typeof window & {
  dataLayer?: any[];
};

export const recordEvents = (data: any) => {
  // eslint-disable-next-line no-undef
  const window = globalThis as unknown as windowWithGTM;
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push({
    data,
  });
};
export const recordCustomEvents = (event: string, data: any) => {
  // eslint-disable-next-line no-undef
  const window = globalThis as unknown as windowWithGTM;
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push({
    event: event,
    data,
  });
};
export const resetEvents = () => {
  // eslint-disable-next-line no-undef
  const window = globalThis as unknown as windowWithGTM;
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push(function () {
    this.reset();
  });
};
